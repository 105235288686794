import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  CrudNetFilterExpression,
  CrudNetRepo,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
  CrudNetViewMode,
} from "crudnet-amgular";
import { OffertaService } from "../../offerta/offerta.service";
import {
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterType,
} from "utils";
import { Observable } from "rxjs";
import {
  faPlus,
  faTrash,
  faEdit,
  faEye,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { SALService } from "../../sal/sal.service";
import { ROLES } from "../../../constants";
import { AuthService } from "../../../auth/auth.service";
import { CrudNetExecRequest } from "projects/crudnet-amgular/src/public-api";
import { I18NextPipe } from "angular-i18next";
import { CommessaRiepilogoService } from "../commessa-riepilogo/commessa-riepilogo.service";
import { CommessaRiepilogoModel } from "../commessa-riepilogo/commessa-riepilogo.model";
import * as moment from "moment";
import { CommessaService } from "../commessa.service";

@Component({
  selector: "app-ordine-custom-crud",
  templateUrl: "./commessa-custom-crud.component.html",
  styleUrls: ["./commessa-custom-crud.component.css"],
})
export class CommessaCustomCrudComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: CrudNetRepo<any>;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  defaultSALFilter: TableServerFilter;
  defaultAttivitaFilter: TableServerFilter;
  defaultValuesFromOffertaPicker: object;
  riepilogoData: CommessaRiepilogoModel;
  // getListOptionsParams: CrudNetSearchRequest<any> = {
  //   pageNum: 0,
  //   pageSize: -1
  // };
  // offerteList: any[];
  viewModes = CrudNetViewMode;
  selectedOfferta;
  currentIcon = faPlus;
  closeIcon = faTimes;
  annoCompetenza = 0;

  ordineGiorniLavorati = 0;
  ordineGiorniEffettivi = 0;

  refreshData = new EventEmitter<any>();
  salTitle = "Aggiungi nuovo SAL";

  allowedRolesSal = [ROLES.ROLE_READ_SAL, ROLES.ROLE_WRITE_SAL];
  allowedRolesAttivita = [ROLES.ROLE_READ_ATTIVITA, ROLES.ROLE_WRITE_ATTIVITA];
  allowedRolesCostiCommessa = [
    ROLES.ROLE_READ_COSTI_COMMESSA,
    ROLES.ROLE_WRITE_COSTI_COMMESSA,
  ];
  allowedRolesRiepilogoCommessa = [
    ROLES.ROLE_READ_RIEPILOGO_COMMESSA,
    ROLES.ROLE_WRITE_RIEPILOGO_COMMESSA,
  ];
  // tslint:disable-next-line:variable-name
  _moment = moment;

  constructor(
    public offertaService: OffertaService,
    public salService: SALService,
    public riepilogoService: CommessaRiepilogoService,
    public auth: AuthService,
    public translatePipe: I18NextPipe,
    private commessaService: CommessaService
  ) {
    // salService.notifyGGEffettive$.subscribe(res => {
    //   this.ordineGiorniEffettivi = res;
    // });
    // salService.notifyGGLavorate$.subscribe(res => {
    //   this.ordineGiorniLavorati = res;
    // });
  }

  ngOnInit() {
    if (this.row && this.row.IDOFFERTA) {
      this.offertaService
        .getOffertaById(this.row.IDOFFERTA)
        .subscribe((res) => {
          this.row.Offerta = res.result[0];
          this.selectedOfferta = res.result[0];
          this.annoCompetenza = parseInt(
            this._moment(this.selectedOfferta.dataCompetenza).format("YYYY"),
            10
          );
          if (res.result.length) {
            this.setDefaultValues(res.result[0]);
          }
        });
    }
    if (
      this.row &&
      (this.viewMode === this.viewModes.EDIT ||
        this.viewMode === this.viewModes.VIEW)
    ) {
      this.salService
        .getGGLavorateEffettive(this.row.IDCOMMESSA)
        .subscribe((res) => {
          this.ordineGiorniLavorati = res.ggLavorate || 0;
          // this.ordineGiorniEffettivi = res.ggEffettive || 0;
          // qui
          const request: CrudNetExecRequest = {
            order: [],
            pageNum: 0,
            pageSize: -1,
            par: {
              IDIMPIEGATO: 0,
              IDCOMMESSA: this.row.IDCOMMESSA,
              ANNO: null,
              MESE: null,
              FLAGCONSOLIDATO: 1,
            },
          };
          this.salService.getMinutiByIdAnnoMese(request).subscribe((ret) => {
            this.ordineGiorniEffettivi =
              ret.output && ret.output.return_value
                ? ret.output.return_value / 60 / 8
                : 0;
          });
        });

      this.riepilogoService
        .getRiepilogo(this.row.IDCOMMESSA)
        .subscribe((res) => {
          this.riepilogoData = res.result[0];
        });
    }
    if (this.viewMode !== this.viewModes.CREATE) {
      this.defaultSALFilter = {
        data: "IDCOMMESSA",
        value: this.row.IDCOMMESSA,
        type: TableServerFilterType.FILTER_NUMBER,
        condition: TableServerFilterCondition.EQ,
      };
      this.defaultAttivitaFilter = {
        data: "fkIdCommessa",
        value: this.row.IDCOMMESSA,
        type: TableServerFilterType.FILTER_NUMBER,
        condition: TableServerFilterCondition.EQ,
      };
    }
    this.currentIcon = this.getIcon();
    this.commessaService.onReload.subscribe(() => {
      if (this.row) {
        this.riepilogoService
          .getRiepilogo(this.row.IDCOMMESSA)
          .subscribe((res) => {
            this.riepilogoData = res.result[0];
          });
      }
    });
  }

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  setDefaultValues(val) {
    this.defaultValuesFromOffertaPicker = {
      tariffa: val.tariffa,
      importo: val.importo,
      giorniContratto: val.giorniOfferti,
      flagAttiva: this.row.FLAGATTIVA,
    };
    this.refreshData.emit();
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  onSubmit(entity, viewMode, idOfferta) {
    const p = new CrudNetUpdateRequest();
    let obj = new Observable<any>();
    entity.IDOFFERTA = idOfferta;
    entity.flagAttiva = true;
    entity.fkIdOfferta = idOfferta;
    if (entity.tariffa) {
      entity.importo =
        (entity.tariffa ? entity.tariffa : 0) *
        (entity.giorniContratto ? entity.giorniContratto : 0);
    }

    p.entity = entity;
    if (viewMode === CrudNetViewMode.CREATE) {
      obj = this.service.add(p);
    } else if (viewMode === CrudNetViewMode.EDIT) {
      obj = this.service.update(p);
    } else if (viewMode === CrudNetViewMode.DELETE) {
      obj = this.service.del(p);
    }
    obj.subscribe((res) => {
      if (res.output && res.output.ERRORMESSAGE) {
        this.error.emit(res.output.ERRORMESSAGE);
      } else {
        this.success.emit("OK");
      }
      this.close.emit();
    });
  }

  onSelect(ev) {
    if (ev.idOfferta) {
      if (this.row) {
        this.row.Offerta = ev;
      }
      this.selectedOfferta = ev;
      this.annoCompetenza = parseInt(
        this._moment(this.selectedOfferta.dataCompetenza).format("YYYY"),
        10
      );
      this.setDefaultValues(ev);
    }
  }
}
