import { Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';

/**
 * Component for DateInput in crudnet form
 */
@Component({
  selector: 'cn-field-date',
  templateUrl: './crud-net-field-date.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./crud-net-field-date.component.css']
})
export class CrudNetFieldDateComponent implements OnInit {

  @Input() label: string;
  @Input() id: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() readonly: boolean;
  @Input() show: boolean;

  @Output() valueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
