import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'cn-field-time',
  templateUrl: './crud-net-field-time.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./crud-net-field-time.component.css']
})
export class CrudNetFieldTimeComponent implements OnInit {

  @Input() label: string;
  @Input() id: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() readonly: boolean;
  @Input() show: boolean;

  @Output() valueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
