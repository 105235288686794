import { Component, OnInit } from '@angular/core';
import {CrudNetViewMode} from 'crudnet-amgular';
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType
} from 'utils';
import {Observable, of} from 'rxjs';
import {TipologiaContrattoService} from './tipologia-contratto.service';

@Component({
  selector: 'app-tipologia-contratto',
  templateUrl: './tipologia-contratto.component.html',
  styleUrls: ['./tipologia-contratto.component.css']
})
export class TipologiaContrattoComponent implements OnInit {

  viewMode: CrudNetViewMode;
  loadForm = false;


  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  constructor(public tipoContrattoService: TipologiaContrattoService) {

    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;

    const column1 = new TableServerColumn();
    column1.label = 'Descrizione';
    column1.data = 'descrizione';
    this.tableConfig.columns = [column1];

    const filter1 = new TableServerFilterItem();
    filter1.label = 'Descrizione';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = 'descrizione';
    this.tableFilters.push(filter1);
  }

  ngOnInit() {
  }

}
