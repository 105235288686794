import { ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import {ROLES} from '../constants';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {

@Input() roles: ROLES[];

parent: any;

  constructor(public authService: AuthService, private el: ElementRef, private renderer: Renderer2) {
    this.parent = this.renderer.parentNode(this.el.nativeElement);
     }

  ngOnInit(): void {
    if (!this.authService.hasRoles(this.roles)) {
      this.renderer.removeChild(this.parent, this.el.nativeElement);
    }
  }
}
