import {Component, OnInit} from '@angular/core';
import {NotificationCenterService} from 'src/app/modules/notification-center/notification-center.service';
import {AdminLayoutRoutes} from './admin-layout.routing';
import * as moment from 'moment';
import {TitleBreadcrumbService} from '../../components/title-breadcrumb/services/title-breadcrumb.service';
import { AdminLayoutService } from './admin-layout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  routes = AdminLayoutRoutes;

  chatEnabled=environment.CHAT_ENABLED;

  constructor(private notificationS: NotificationCenterService, private adminLayoutService:AdminLayoutService) {
    this.adminLayoutService.windowsResizedMobile.subscribe(()=>{
      this.notificationS.initDesktop();
    })
  }

  ngOnInit() {
    if(this.chatEnabled){
      this.adminLayoutService.emitIsMobile();
      this.notificationS.init();
    }

  }

}
