import { Injectable } from "@angular/core";
import { BaseRepo } from "../../repos/BaseRepo";
import { Observable } from "rxjs";
import {
  CrudNetFilterExpression,
  CrudNetResultResponse,
  CrudNetSearchRequest,
} from "crudnet-amgular/crudnet-amgular";
import { SkillsModel } from "./skills.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SkillsService extends BaseRepo<SkillsModel> {
  getTable() {
    return "Skill";
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable);
  }

  search(
    params: CrudNetSearchRequest<SkillsModel>,
    customTable?: any,
    filters?: CrudNetFilterExpression
  ): Observable<CrudNetResultResponse<SkillsModel>> {
    if (!customTable) {
      params.includes = ["AmbitoSkill"];
    }
    return this.search2(params, customTable);
  }

  searchLookup(
    params: CrudNetSearchRequest<any>,
    customTable?: any
  ): Observable<CrudNetResultResponse<any>> {
    return super.search2(params, customTable);
  }

  getValueForSelect(
    id: any,
    customTable?: string
  ): Observable<CrudNetResultResponse<any>> {
    if (customTable) {
      return super.find(id, customTable);
    }
  }

  ambitoSkillDataset: any = (typedValue: string) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "AmbitoSkill").pipe(
      map((res) => {
        debugger;
        return res.result.map((ambito) => {
          return {
            value: ambito.idAmbitoSkill,
            descrizione: ambito.descrizione,
          };
        });
      })
    );
  };
}
