import { Injectable, Injector } from "@angular/core";
import { BaseRepo } from "../../repos/BaseRepo";
import { RichiestaModel } from "./richieste.model";
import {
  CrudNetBaseResponse,
  CrudNetExecRequest,
  CrudNetResultResponse,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
  transformFiltersToServer,
  transformServerFilterToTableFilter,
  TypeaheadSearch,
} from "crudnet-amgular";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  TaalUtilsService,
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterType,
} from "utils";

@Injectable({
  providedIn: "root",
})
export class RichiesteService extends BaseRepo<RichiestaModel> {
  constructor(
    private taalService: TaalUtilsService,
    public injector: Injector
  ) {
    super(injector);
  }
  getTable() {
    return "Richiesta";
  }

  search(
    params: CrudNetSearchRequest<RichiestaModel>,
    customTable?: any
  ): Observable<CrudNetResultResponse<RichiestaModel>> {
    if (!customTable) {
      let paramFilter = transformServerFilterToTableFilter(params.filter);
      let filter = new TableServerFilter();

      filter.data = "fkIdStatoRichiesta";
      filter.value = 4;
      filter.condition = TableServerFilterCondition.NOT_EQ;
      filter.type = TableServerFilterType.FILTER_LIST;

      paramFilter.push(filter);

      params.filter = transformFiltersToServer(paramFilter);

      params.includes = [
        "Cliente",
        "TipologiaLavoro",
        "TipoSeniority",
        "TipoSettoreLavorativo",
        "Sede.Comune",
        "StatoRichiesta",
        "TipoPriorita",
        "ReferenteCliente",
        "TipoSettoreLavorativo",
        "TipoProfiloLavorativo",
        "Impiegato.Anagrafica",
        "Comune.Provincia",
        "RichiestaSkill_List.Skill",
        "RichiestaLingua_List.Lingua",
      ];
      return super.search2(params).pipe(
        map((res) => {
          res.result = res.result.map((richiesta) => {
            richiesta.Impiegato.Anagrafica["nomeCognome"] =
              richiesta.Impiegato.Anagrafica.nome +
              " " +
              richiesta.Impiegato.Anagrafica.cognome;
            return richiesta;
          });
          return res;
        })
      );
    }
    if (customTable === "Skill") {
      return super.search(params, customTable).pipe(
        map((res) => {
          res.result = res.result.map((skill) => {
            return {
              ...skill,
              realValue: skill.idSkill + "-" + skill.fkIdAmbitoSkill,
            };
          });
          return res;
        })
      );
    }
    return super.search(params, customTable);
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map((res) => {
        res.result.columns = res.result.columns
          .filter(
            (c) =>
              c.name !== "idAnagrafica" &&
              c.name !== "fkIdImpiegatoRichiedente" &&
              c.name !== "titolo" &&
              c.name !== "fkIdSettoreLavorativo" &&
              c.name !== "dataCreazione" &&
              c.name !== "numeroRisorse" &&
              c.name !== "fkIdStatoRichiesta"
          )
          .map((c) => {
            if (c.name === "fkIdSedeCompetenza") {
              c.realName = "fkIdSede";
            }
            if (c.name === "fkIdComuneSedeLavorativa") {
              c.realName = "fkIdComune";
            }
            if (c.name === "fkIdImpiegatoRichiedente") {
              c.realName = "fkIdImpiegato";
            }

            if (c.name === "visibilitaMesi") {
              c.hideMe = (parsedForm, orginalForm, externalData) => {
                return parsedForm.flagContinuativa;
              };
            }
            return c;
          })
          .concat([
            {
              maxLen: null,
              name: "fkIdLingua",
              precision: null,
              required: true,
              scale: null,
              type: "ICollection",
            },
            {
              maxLen: null,
              name: "fkIdSkill",
              precision: null,
              required: true,
              scale: null,
              type: "ICollection",
            },
            {
              maxLen: null,
              name: "fkIdTipoSettoreLavorativo",
              precision: null,
              required: true,
              scale: null,
              type: "Int32",
            },
            {
              maxLen: null,
              name: "flagContinuativa",
              precision: null,
              required: false,
              scale: null,
              type: "Boolean",
            },
          ]);
        return res;
      })
    );
  }

  add(
    params: CrudNetUpdateRequest<RichiestaModel>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<RichiestaModel>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertRichiestaCompleta", execParams);
  }

  update(
    params: CrudNetUpdateRequest<RichiestaModel>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<RichiestaModel>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_UpdateRichiestaCompleta", execParams);
  }

  del(
    params: CrudNetUpdateRequest<RichiestaModel>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<RichiestaModel>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_UpdateStatoRichiesta", execParams);
  }

  clienteDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "ragioneSociale.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "Cliente").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.idCliente,
            descrizione: el.ragioneSociale,
          }));
        }
        return res.result;
      })
    );
  };

  referenteClienteDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "nome.Contains(@0) || cognome.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "ReferenteCliente").pipe(
      map((res) => {
        return res.result.map((rc) => {
          rc.nominativo = rc.nome + " " + rc.cognome;
          return rc;
        });
      })
    );
  };

  sedeDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "Sede").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.idSede,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  comuneDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "Comune").pipe(
      map((res) => {
        const sortedArray = this.taalService.sortCounterObj(
          res.result,
          (obj) => {
            const val = obj.descrizione.toString().toLowerCase();
            return val == typedValue
              ? 0
              : val.indexOf(typedValue) == 0
              ? 1
              : val.indexOf(typedValue) > 0
              ? 2
              : 3;
          }
        );
        if (fromFilter) {
          return sortedArray.map((el) => ({
            value: el.idComune,
            descrizione: el.descrizione,
          }));
        }
        return sortedArray;
      })
    );
  };

  tipoPrioritaDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "TipoPriorita").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.idTipoPriorita,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  tipologiaLavoroDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "TipologiaLavoro").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.idTipologiaLavoro,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  tipoSeniorityDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "TipoSeniority").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.idTipoSeniority,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  statoRichiestaDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "StatoRichiesta").pipe(
      map((res) => {
        if (fromFilter) {
          let filteredFilter = res.result.filter((rs) => {
            return rs.idStatoRichiesta != 4;
          });
          filteredFilter = filteredFilter.map((el) => ({
            value: el.idStatoRichiesta,
            descrizione: el.descrizione,
          }));
          return filteredFilter;
        }
        return res.result;
      })
    );
  };

  setSkillDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "Skill").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.Skill,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  getSkillList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "Skill");
  }

  getComuneList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "Comune");
  }

  getDataMock() {
    return of([
      {
        nome: "mario",
        cognome: "rossi",
      },
    ]);
  }

  getCandidatiAssegnatiRichiesta(
    id: any
  ): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDRICHIESTA: id,
      },
    };
    return this.exec("fn_GetListaCandidatiAssegnati", params);
  }

  removeCandidatoFromRichiesta(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_RimozioneCandidatoRichiesta", execParams);
  }
}
