import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { I18NextPipe } from "angular-i18next";

@Component({
  selector: "cn-field-select-multiple",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./crud-net-field-select-multiple.component.html",
  styleUrls: ["./crud-net-field-select-multiple.component.css"],
})
export class CrudNetFieldSelectMultipleComponent implements OnInit {
  @Input() displayValue?: string;
  @Input() options: Array<any>;
  @Input() id: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() readonly: boolean;
  @Input() show: boolean;
  @Input() allowAddNewValues?: boolean;

  @Output() valueChange = new EventEmitter<any>();

  constructor(public translatePipe: I18NextPipe) {}

  ngOnInit() {
    if (!this.displayValue || !this.displayValue.length) {
      this.displayValue = "descrizione";
    }
  }

  addElement(element: string) {
    return element;
  }
}
