import { CrudNetRepo } from 'crudnet-amgular';
import { HttpErrorResponse, HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { TOKEN_LOCAL_STORAGE_KEY } from '../constants';
import { ModalService } from 'utils';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export abstract class BaseRepo<T> extends CrudNetRepo<T> {
  constructor(public injector: Injector) {
    super(injector);
  }

  http = this.injector.get(HttpClient);
  auth = this.injector.get(AuthService);
  modalService = this.injector.get(ModalService);

  getBaseUrl() {
    return environment.BASE_URL_TAAL;
  }

  onError(ex: HttpErrorResponse) {
    if (ex.status === 401) {
      this.auth.onLogout.next();
      // this.login.logoutTaalWithGoogle();
    } else {
      if (ex.error && ex.error.message) {
        this.modalService.showError(ex.error.message);
      } else {
        this.modalService.showError(ex.message);
      }
    }
  }

  getAuthToken() {
    return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
  }

  getHttpClient() {
    return this.http;
  }
}


@Injectable()
export class HttpInterceptorTimeout implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(timeout(1800000));
    // 30000 (30s) would be the global default for example
  }
}
