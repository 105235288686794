import { Injectable } from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {SocietaModel} from './societa.model';

@Injectable({
  providedIn: 'root'
})
export class SocietaService extends BaseRepo<SocietaModel> {

  getTable() {
    return 'Societa';
  }
}
