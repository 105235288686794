import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CrudNetUpdateFieldRequest, CrudNetUpdateRequest, CrudNetViewMode} from 'crudnet-amgular';
import {CommessaExpandedService} from '../commessa-expanded.service';
import {
  ModalService,
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterItem,
  TableServerFilterMode,
  TableServerFilterType
} from 'utils';
import {Observable, of} from 'rxjs';
import {CustomStringRendererComponent} from '../../../components/custom-string-renderer/custom-string-renderer.component';
import * as moment from 'moment';
import {CommessaCustomCrudComponent} from '../commessa-custom-crud/commessa-custom-crud.component';
import {CommessaColumnSwitchComponent} from '../commessa-column-switch/commessa-column-switch.component';

@Component({
  selector: 'app-commessa-expandable-column-component',
  templateUrl: './commessa-expandable-column.component.html',
  styleUrls: ['./commessa-expandable-column.component.css'],
})
export class CommessaExpandableColumnComponent implements OnInit, OnChanges {
  @Input() filtersFromCommessa?: TableServerFilter[];
  @Input() idCliente?: number;

  customCRUD: any;

  viewMode: CrudNetViewMode;
  loadForm = false;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = null;//[TableServerActionIntent.VIEW, TableServerActionIntent.EDIT];
  tableFilters: TableServerFilterItem[] = [];
  defaultFilters: TableServerFilter[] = [];
  filterModes = TableServerFilterMode;


  constructor(public commessaService: CommessaExpandedService, private modalService: ModalService) {

    const onSwitchChange = new EventEmitter();
    onSwitchChange.subscribe((row) => {
      const p = new CrudNetUpdateFieldRequest<any>();
      let obj: Observable<any>;

      p.entity = {flagAttiva: row.FLAGATTIVA === 1};
      p.filter = {expression: 'idCommessa==@0', expressionValues: [{value: row.IDCOMMESSA}]};
      obj = this.commessaService.updateflds(p);
      obj.subscribe((res) => {
        if (!res.error) {
          this.modalService.showSuccess('SUCCESSED_OPERATION');
        }
      });
    });

    //           this.modalService.showSuccess("SUCCESSED_OPERATION")

    this.viewMode = CrudNetViewMode.EDIT;
    this.customCRUD = CommessaCustomCrudComponent;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column2 = new TableServerColumn();
    column2.label = 'Titolo offerta';
    column2.data = 'TITOLOOFFERTA';

    const column3 = new TableServerColumn();
    column3.label = 'Data Inizio';
    column3.data = 'DATAINIZIO';
    column3.type = TableServerColumnType.COLUMN_CUSTOM;
    column3.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender:
          row.DATAINIZIO && row.DATAINIZIO.length
            ? moment(row.DATAINIZIO, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY')
            : '-',
      };
      return render;
    };

    const column4 = new TableServerColumn();
    column4.label = 'Data Fine';
    column4.data = 'DATAFINE';
    column4.type = TableServerColumnType.COLUMN_CUSTOM;
    column4.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender:
          row.DATAFINE && row.DATAFINE.length
            ? moment(row.DATAFINE, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY')
            : '-',
      };
      return render;
    };

    const column5 = new TableServerColumn();
    column5.label = 'Attesa Ordine';
    column5.data = 'ATTESAORDINE';
    column5.type = TableServerColumnType.COLUMN_CUSTOM;
    column5.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.ATTESAORDINE ? 'Attesa Ordine' : 'Ordine Arrivato',
      };
      return render;
    };

    const column6 = new TableServerColumn();
    column6.label = 'Sede';
    column6.data = 'SEDE';

    const column7 = new TableServerColumn();
    column7.label = 'Importo';
    column7.data = 'IMPORTOCOMMESSA';
    column7.type = TableServerColumnType.COLUMN_CUSTOM;
    column7.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.IMPORTOCOMMESSA
          ? '€ ' +
          row.IMPORTOCOMMESSA.toLocaleString('it-IT', {
            maximumFractionDigits: 2,
          })
          : '',
      };
      return render;
    };

    const column8 = new TableServerColumn();
    column8.label = 'Totale SAL';
    column8.data = 'TOTALESAL';
    column8.type = TableServerColumnType.COLUMN_CUSTOM;
    column8.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.TOTALESAL
          ? '€ ' +
          row.TOTALESAL.toLocaleString('it-IT', {maximumFractionDigits: 2})
          : '',
      };
      return render;
    };

    const column9 = new TableServerColumn();
    column9.label = 'Totale SAL fatturato';
    column9.data = 'TOTALESALFATTURATO';
    column9.type = TableServerColumnType.COLUMN_CUSTOM;
    column9.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.TOTALESALFATTURATO
          ? '€ ' +
          row.TOTALESALFATTURATO.toLocaleString('it-IT', {
            maximumFractionDigits: 2,
          })
          : '',
      };
      return render;
    };

    const column10 = new TableServerColumn();
    column10.label = 'Commerciale rif.';
    column10.data = 'COMMERCIALERIFERIMENTO';

    /*const columnSwitch = new TableServerColumn();
    columnSwitch.label = 'ATTIVA';
    columnSwitch.data = 'FLAGATTIVA';
    columnSwitch.type = TableServerColumnType.COLUMN_CUSTOM;
    columnSwitch.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CommessaColumnSwitchComponent;
      render.inputs = {
        row
      };
      render.outputs = {
        onSwitchChange,
      };
      return render;
    };*/

    this.tableConfig.columns = [
      column10,
      column2,
      column6,
      column5,
      column3,
      column4,
      column7,
      column8,
      column9,
      //columnSwitch
    ];
  }

  ngOnInit() {
    this.setDefaultFilters();
  }

  setDefaultFilters() {
    this.defaultFilters = this.filtersFromCommessa;
    //if (!this.filtersFromCommessa.find(e => e.data === 'IDCLIENTE')) {
    this.defaultFilters.push({
      data: 'IDCLIENTE',
      value: this.idCliente,
      condition: TableServerFilterCondition.EQ,
      type: TableServerFilterType.FILTER_NUMBER
    });
    //}
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filtersFromCommessa) {
      this.setDefaultFilters();
    }
  }

}
