import { Injectable } from "@angular/core";
import { BaseRepo } from "../../../repos/BaseRepo";
import { Observable } from "rxjs";
import {
  CrudNetExecRequest,
  CrudNetResultResponse,
  CrudNetSearchRequest,
} from "crudnet-amgular";

@Injectable({
  providedIn: "root",
})
export class CommessaRiepilogoService extends BaseRepo<any> {
  getTable() {
    return "";
  }

  getRiepilogo(idCommessa): Observable<CrudNetResultResponse<any>> {
    const par = { IDCOMMESSA: idCommessa };

    const execParams: CrudNetExecRequest = {
      filter: { expression: null, expressionValues: null },
      order: [],
      pageNum: 1,
      pageSize: 10,
      par,
    };
    return super.exec("fn_GetRiepilogoCommessa", execParams);
  }
}
