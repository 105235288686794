import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-action-search-button',
  templateUrl: './action-search-button.component.html',
  styleUrls: ['./action-search-button.component.css']
})
export class ActionSearchButtonComponent implements OnInit {
  @Input() openModalSearch: () => void;
  constructor() { }

  ngOnInit() {
  }

  openModal() {
    this.openModalSearch();
  }

}
