import { Component, OnInit, Type, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { CrudNetViewMode } from 'crudnet-amgular';
import {
  TableServerActionIntent,
  TableServerColumn, TableServerColumnRender, TableServerColumnSortDirection, TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem, TableServerFilterMode,
  TableServerFilterType
} from 'utils';
import { Observable, of } from 'rxjs';
import { ImpiegatoService } from './impiegato.service';
import { ImpiegatoCustomCRUDComponent } from './impiegato-custom-crud/impiegato-custom-crud.component';
import { CustomDateRendererComponent } from '../../components/custom-date-renderer/custom-date-renderer.component';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { PROFILES } from 'src/app/constants';

@Component({
  selector: 'app-impiegato',
  templateUrl: './impiegato.component.html',
  styleUrls: ['./impiegato.component.css']
})
export class ImpiegatoComponent implements OnInit {
  customCRUD: any;

  viewMode: CrudNetViewMode;
  loadForm = false;
  filterModes = TableServerFilterMode;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];
  filterType = null;
  loaded = false;

  constructor(public impiegatoService: ImpiegatoService, private auth: AuthService) {

    this.viewMode = CrudNetViewMode.EDIT;
    this.customCRUD = ImpiegatoCustomCRUDComponent;
    // this.customCRUD.viewMode = this.viewMode;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const columnNome = new TableServerColumn();
    columnNome.label = 'Nome';
    columnNome.data = 'nome';
    // this.tableConfig.columns.push(columnNome);

    const columnCognome = new TableServerColumn();
    columnCognome.label = 'Cognome';
    columnCognome.sortDirection = TableServerColumnSortDirection.ASC;
    columnCognome.data = 'cognome';
    // this.tableConfig.columns.push(columnCognome);

    const columnEmailAziendale = new TableServerColumn();
    columnEmailAziendale.label = 'Email Aziendale';
    columnEmailAziendale.data = 'emailAziendale';
    // this.tableConfig.columns.push(columnEmailAziendale);

    const columnEmailPrivata = new TableServerColumn();
    columnEmailPrivata.label = 'Email Privata';
    columnEmailPrivata.data = 'emailPrivata';
    // this.tableConfig.columns.push(columnEmailPrivata);

    const columnDataNascita = new TableServerColumn();
    columnDataNascita.label = 'Data Nascita';
    columnDataNascita.data = 'dataNascita';
    columnDataNascita.type = TableServerColumnType.COLUMN_CUSTOM;
    columnDataNascita.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.dataNascita
      };
      return render;
    };
    // this.tableConfig.columns.push(columnDataNascita);



    const columnSocietaAppartenenza = new TableServerColumn();
    columnSocietaAppartenenza.label = 'Società';
    columnSocietaAppartenenza.sortDirection = TableServerColumnSortDirection.ASC;
    columnSocietaAppartenenza.data = 'societaAppartenenza';

    const columnTipoContratto = new TableServerColumn();
    columnTipoContratto.label = 'Tipo contratto';
    columnTipoContratto.data = 'tipoContratto';

    const columnCostoAnnuo = new TableServerColumn();
    columnCostoAnnuo.label = 'Costo annuo';
    columnCostoAnnuo.data = 'costoAnnuo';

    const columnCostoGiornaliero = new TableServerColumn();
    columnCostoGiornaliero.label = 'Costo giornaliero';
    columnCostoGiornaliero.data = 'costoGiornaliero';

    const columnIban = new TableServerColumn();
    columnIban.label = 'IBAN';
    columnIban.data = 'iban';

    const filter1 = new TableServerFilterItem();
    filter1.label = 'Nome';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = 'NOME';
    // this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = 'Cognome';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = 'COGNOME';
    // this.tableFilters.push(filter2);

    const filterSocieta = new TableServerFilterItem();
    filterSocieta.label = 'Società appartenenza';
    filterSocieta.type = TableServerFilterType.FILTER_TEXT;
    filterSocieta.data = 'SOCIETAAPPARTENENZA';

    const filterTipoContratto = new TableServerFilterItem();
    filterTipoContratto.label = 'Tipo contratto';
    filterTipoContratto.type = TableServerFilterType.FILTER_TEXT;
    filterTipoContratto.data = 'TIPOCONTRATTO';

    const filterDataScadenza = new TableServerFilterItem();
    filterDataScadenza.label = 'Data scadenza';
    filterDataScadenza.type = TableServerFilterType.FILTER_DATE;
    filterDataScadenza.data = 'DATASCADENZACONTRATTO';

    if (
      this.auth.hasProfiles([PROFILES.PROFILE_RESP_PERSONALE])
    ) {

      // fkIdImpiegatoReferente.Equals(43)"
      this.tableConfig.columns = [
        columnSocietaAppartenenza, // societa
        columnCognome, // cognome
        columnNome, // nome
        columnTipoContratto,
        columnCostoAnnuo,
        columnCostoGiornaliero,
      ];
      this.tableFilters = [
        filterSocieta,
        filter2,
        filter1,
        filterTipoContratto,
        filterDataScadenza
      ];
      this.filterType = this.filterModes.FILTER_BASIC;
      this.loaded = true;
    } else {
      this.tableConfig.columns = [
        columnSocietaAppartenenza, // societa
        columnCognome, // cognome
        columnNome, // nome
        columnTipoContratto,
        columnIban
      ];
      this.tableFilters = [filterSocieta, filter2, filter1];
      this.loaded = true;
    }

  }

  ngOnInit() {

  }

}

