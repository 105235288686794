import { Component } from '@angular/core';
import { TableServerCheckSelection, TableServerColumn, TableServerColumnType, TableServerConfig, TableServerFilterItem, TableServerFilterType, TableServerSearch } from 'utils';
import { DispositivoService } from './dispositivo.service';
import { GestioneMagazzinoCrudComponent } from './gestione-magazzino-crud/gestione.magazzino-crud.component';

@Component({
    templateUrl: './gestione-magazzino.component.html',
    styleUrls: ['./gestione-magazzino.component.css']
  })

  export class GestioneMagazzinoComponent {

    tableConfig: TableServerConfig;
    tableFilters: TableServerFilterItem[] = [];
    customCRUD = GestioneMagazzinoCrudComponent;
    constructor( public dispositivoService: DispositivoService ) {

      this.tableConfig = new TableServerConfig();
      this.tableConfig.itemsPerPage = 10;
      this.tableConfig.columns = [];

      const column1 = new TableServerColumn();
      column1.label = 'SN';
      column1.data = 'serialNumber';

      const column2 = new TableServerColumn();
      column2.label = 'Tipo Dispositvo';
      column2.data = 'TipoDispositivo.codice';

      const column3 = new TableServerColumn();
      column3.label = 'Modello';
      column3.data = 'modello';

      this.tableConfig.columns = [
        column1,
        column2,
        column3,
      ];


      const filterSN = new TableServerFilterItem();
      filterSN.label = 'SN';
      filterSN.type = TableServerFilterType.FILTER_TEXT;
      filterSN.data = 'serialNumber';

      const filterTD = new TableServerFilterItem();
      filterTD.label = 'Tipo dispositivo';
      filterTD.type = TableServerFilterType.FILTER_TEXT;
      filterTD.data = 'TipoDispositivo.codice';


      const filterModello = new TableServerFilterItem();
      filterModello.label = 'Modello';
      filterModello.type = TableServerFilterType.FILTER_TEXT;
      filterModello.data = 'modello';

      this.tableFilters=[
        filterSN,
        filterTD,
        filterModello
      ]
    }

  }
