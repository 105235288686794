import { DettaglioRichiesta } from './dettaglioRichiesta.model';

export class PermessoModel {

  constructor(
    selectedHourStart?: string,
    selectedMinutesStart?: string,
    selectedHourEnd?: string,
    selectedMinutesEnd?: string,
    codiceTipologiaPermesso?: string,
    note?: string,
    id?: number,
    nomeImpiegatoRichiedente?: string,
    cognomeImpiegatoRichiedente?: string,
    descTipoRichiesta?: string,
    dettaglioRichiesta?: string,
    misura?: string,
    ) {
      this.selectedHourStart = selectedHourStart ? selectedHourStart : null;
      this.selectedMinutesStart = selectedMinutesStart ? selectedMinutesStart : null;
      this.selectedHourEnd = selectedHourEnd ? selectedHourEnd : null;
      this.selectedMinutesEnd = selectedMinutesEnd ? selectedMinutesEnd : null ;
      this.codiceTipologiaPermesso = codiceTipologiaPermesso ? codiceTipologiaPermesso : null;
      this.note = note ? note : null;
      this.id = id ? id : null;
      this.nomeImpiegatoRichiedente = nomeImpiegatoRichiedente ? nomeImpiegatoRichiedente : null;
      this.cognomeImpiegatoRichiedente = cognomeImpiegatoRichiedente ? cognomeImpiegatoRichiedente : null;
      this.descTipoRichiesta = descTipoRichiesta ? descTipoRichiesta : null;
      this.dettaglioRichiesta =
        dettaglioRichiesta ? this.getDettaglioRichiestaFromComplexString(dettaglioRichiesta) : null;
      this.misura = misura ? misura : null;
    }

  // i campi che seguono sono quelli alla creazzione di un'istanza per operazioni di insert
  selectedHourStart: string;
  selectedMinutesStart: string;
  selectedHourEnd: string;
  selectedMinutesEnd: string;
  codiceTipologiaPermesso: string;
  note: string;
  // id valido in lettura ed in update
  id: number;
  // i campi che seguono sono quelli ritornati dal server

  nomeImpiegatoRichiedente: string;
  cognomeImpiegatoRichiedente: string;
  descTipoRichiesta: string;
  dettaglioRichiesta: DettaglioRichiesta[];
  misura: string;

 public getDettaglioRichiestaFromComplexString(complexString: string): DettaglioRichiesta[]  {
   const res: DettaglioRichiesta[] = [];

   const singoloRecordArray: string[] = complexString.split(';');
   singoloRecordArray.forEach((el) => {
     const dettaglio = new DettaglioRichiesta(el);
     res.push(dettaglio);
   });
   return res;
  }

}

