import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type,} from '@angular/core';
import {CrudNetViewMode} from 'crudnet-amgular';
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilter,
  TableServerFilterItem,
  TableServerFilterType,
} from 'utils';
import {Observable, of} from 'rxjs';
import {SALService} from './sal.service';
import {SalCustomCrudComponent} from './sal-custom-crud/sal-custom-crud.component';
import {CustomDateRendererComponent} from '../../components/custom-date-renderer/custom-date-renderer.component';
import {CustomStringRendererComponent} from '../../components/custom-string-renderer/custom-string-renderer.component';
import * as moment from 'moment';
import {ImpiegatoService} from '../impiegato/impiegato.service';
import {map} from 'rxjs/operators';
import {TypeaheadSearchFilterAutocomplete} from '../../../../projects/utils/src/lib/table-server/models/TableServerFilter';

@Component({
  selector: 'app-sal',
  templateUrl: './sal.component.html',
  styleUrls: ['./sal.component.css'],
})
export class SALComponent implements OnInit, OnChanges {
  @Input() offerta?: any;
  @Input() ordine?: any;
  @Input() viewModeFromOrdine?: CrudNetViewMode;
  @Input() defaultFiltersFromOrdine?: TableServerFilter[];
  @Input() titleProp?: string;
  @Output() titlePropChange = new EventEmitter<string>();
  additionalInputs: object;

  customCRUD: Type<SalCustomCrudComponent>;

  viewMode: CrudNetViewMode;
  viewModes = CrudNetViewMode;
  loadForm = false;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW, TableServerActionIntent.EDIT, TableServerActionIntent.DELETE];
  tableFilters: TableServerFilterItem[] = [];

  constructor(public salService: SALService, private impiegatoService: ImpiegatoService) {
    // this.salService.notifyOfferta$.subscribe(offerta => {
    //   this.offerta = offerta;
    // });
    // this.salService.notifyOrdine$.subscribe(ordine => {
    //   this.ordine = ordine;
    // });
    this.viewMode = CrudNetViewMode.EDIT;
    this.customCRUD = SalCustomCrudComponent;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column2 = new TableServerColumn();
    column2.label = 'Mese';
    column2.data = 'MESE';
    column2.type = TableServerColumnType.COLUMN_CUSTOM;
    column2.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.MESE
          ? moment()
            .set('month', row.MESE - 1)
            .format('MMMM')
          : '-',
      };
      return render;
    };
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = 'Anno';
    column3.data = 'ANNO';
    this.tableConfig.columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = 'Offerta';
    column4.data = 'TITOLOOFFERTA';
    this.tableConfig.columns.push(column4);

    const column5 = new TableServerColumn();
    column5.label = 'Cliente';
    column5.data = 'RAGIONESOCIALECLIENTE';
    this.tableConfig.columns.push(column5);

    const column6 = new TableServerColumn();
    column6.label = 'Importo';
    column6.data = 'IMPORTO';
    column6.type = TableServerColumnType.COLUMN_NUMBER;
    this.tableConfig.columns.push(column6);

    const column7 = new TableServerColumn();
    column7.label = 'Data fatt.';
    column7.data = 'DATAFATTURAZIONE';
    column7.type = TableServerColumnType.COLUMN_CUSTOM;
    column7.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.DATAFATTURAZIONE,
      };
      return render;
    };
    this.tableConfig.columns.push(column7);

    const column8 = new TableServerColumn();
    column8.label = 'Stato Fatturazione';
    column8.data = 'STATOFATTURAZIONE';
    // column8.type = TableServerColumnType.COLUMN_CUSTOM;
    // column8.render = (row, column) => {
    //   const render = new TableServerColumnRender();
    //   render.component = CustomStringRendererComponent;
    //   render.inputs = {
    //     stringToRender: row.StatoFatturazione.codice === 'OKF' ?
    //       'SI' : 'NO'
    //   };
    //   return render;
    // };
    this.tableConfig.columns.push(column8);

    const column9 = new TableServerColumn();
    column9.label = 'N° fatt.';
    column9.data = 'NUMEROFATTURA';
    column9.type = TableServerColumnType.COLUMN_NUMBER;
    this.tableConfig.columns.push(column9);

    const filter4 = new TableServerFilterItem();
    filter4.label = 'Mese';
    filter4.type = TableServerFilterType.FILTER_MONTH;
    filter4.data = 'MESE';
    this.tableFilters.push(filter4);

    const filter3 = new TableServerFilterItem();
    filter3.label = 'Anno';
    filter3.type = TableServerFilterType.FILTER_NUMBER;
    filter3.data = 'ANNO';
    this.tableFilters.push(filter3);

    const filter1 = new TableServerFilterItem();
    filter1.label = 'Offerta';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = 'TITOLOOFFERTA';
    this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = 'Cliente';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = 'RAGIONESOCIALECLIENTE';
    this.tableFilters.push(filter2);

    const filterImpiegato = new TableServerFilterItem();
    filterImpiegato.label = 'Impiegato';
    filterImpiegato.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filterImpiegato.data = 'fkIdImpiegato';
    filterImpiegato.options = this.searchImpiegato;
    this.tableFilters.push(filterImpiegato);

    const filterStato = new TableServerFilterItem();
    filterStato.label = 'Stato Fatturazione';
    filterStato.type = TableServerFilterType.FILTER_LIST;
    filterStato.data = 'IDSTATOFATTURAZIONE';
    filterStato.options = [
      {label: 'Sal creato, in attesa di ok a fatturare', value: 1},
      {label: 'OK a fatturare', value: 2},
      {label: 'Fatturato', value: 3}
    ];
    this.tableFilters.push(filterStato);
  }

  ngOnInit() {
    if (this.viewModeFromOrdine && this.viewModeFromOrdine === this.viewModes.VIEW) {
      this.tableActions = [TableServerActionIntent.VIEW];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.offerta) {
      this.offerta = changes.offerta.currentValue;
    }
    if (changes.ordine) {
      this.ordine = changes.ordine.currentValue;
    }

    this.additionalInputs = {
      offertaProp: this.offerta,
      ordineProp: this.ordine,
    };
  }

  searchImpiegato: TypeaheadSearchFilterAutocomplete = (query) => {
    const params = {
      pageNum: 0,
      pageSize: -1, // formFields[i].type === CrudNetFieldType.FIELD_SELECT?-1: 20,
      order: ['NOME'],
      filter: {
        expression: 'NOME.ToUpper().Contains(@0) || COGNOME.ToUpper().Contains(@1)',
        expressionValues: [{value: query.toUpperCase()}, {value: query.toUpperCase()}]
      }
    };
    return this.impiegatoService.search(params).pipe(
      map(res => res.result.map(e => ({descrizione: e.nome + ' ' + e.cognome, value: e.idImpiegato})
      ))
    );
  }
}
