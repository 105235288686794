import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { CrudNetRepo } from "../repo/CrudNetRepo";
import {
  CrudNetField,
  CrudNetFieldRelation,
  CrudNetFieldType,
  CrudNetViewMode,
} from "../models/CrudNetFormField";
import _ from "lodash";
import * as moment_ from "moment";
import {
  CrudNetFilterExpression,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
} from "../models/CrudNetRequest";
import { Observable, of } from "rxjs";
import {
  faEdit,
  faEraser,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { map } from "rxjs/operators";
import { TaalUtilsService } from "utils";

const moment = moment_;

/**
 * Component for automatic generation form for crudnet table or view
 */
@Component({
  selector: "cn-form",
  templateUrl: "./crud-net-form.component.html",
  styleUrls: ["./crud-net-form.component.css"],
})
export class CrudNetFormComponent implements OnInit, OnDestroy {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: CrudNetRepo<any>;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: (...args: any[]) => any;
  /**
   * custom param array for the custom submit function
   */
  @Input() customSubmitParams?: any[];

  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: Object;
  @Input() hideTitle?: boolean;
  @Input() refreshEvent?: EventEmitter<any>;

  /**
   * boolean to shoe reset
   */
  @Input() showReset? = false;

  @Input() relatedFields?: CrudNetFieldRelation[];

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @Output() init: EventEmitter<any> = new EventEmitter<any>();

  @Input() disabledFields?: Array<string> = [];

  tableName: string;
  fields: CrudNetField[];
  types = CrudNetFieldType;
  viewModes = CrudNetViewMode;
  currentIcon = faPlus;
  closeIcon = faTimes;
  resetIcon = faEraser;
  show = false;

  months = moment.months().map((month, index) => {
    return {
      label: month,
      value: index + 1,
    };
  });

  constructor(private taalService: TaalUtilsService) {}

  ngOnDestroy(): void {
    this.refreshEvent.unsubscribe();
  }

  ngOnInit() {
    this.tableName = this.service.getTable();
    this.currentIcon = this.getIcon();

    this.registerEvent();

    this.draw();
  }

  registerEvent() {
    if (!this.refreshEvent) {
      this.refreshEvent = new EventEmitter<any>();
    }

    this.refreshEvent.subscribe((res) => {
      this.draw();
    });
  }

  getDateFields() {
    if (this.fields) {
      return this.fields.filter((item) => item.type === this.types.FIELD_DATE);
    }
    return [];
  }

  draw() {
    this.show = false;
    this.service.hideLoadingSpinner = true;
    this._getFormFields()
      .then((fields) => this._loadOptions(fields))
      .then((fields) => this._selectValueDefault(fields))
      .then((fields) => this._selectValueEdit(fields))
      .then((fields) => {
        this.fields = fields;
        this.show = true;
        this.service.hideLoadingSpinner = false;
        setTimeout(() => {
          this.init.emit(true);
        }, 500);
      });
  }

  /**
   * method to get icon for header
   */
  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  /**
   * method that call crudnetservice for default( insert edit delete) or custom submit method
   */
  onSubmit() {
    if (this.submit) {
      if (this.customSubmitParams) {
        return this.submit(
          this.stateToObject(this.fields),
          this.viewMode,
          ...this.customSubmitParams
        );
      } else {
        return this.submit(this.stateToObject(this.fields), this.viewMode);
      }
    } else {
      const p = new CrudNetUpdateRequest();
      if (this.customSubmitParams) {
        p.entity = {
          ...this.stateToObject(this.fields),
          ...this.customSubmitParams[0],
        };
      } else {
        p.entity = this.stateToObject(this.fields);
      }
      let obj = new Observable<any>();
      if (this.viewMode === CrudNetViewMode.CREATE) {
        obj = this.service.add(p);
      } else if (this.viewMode === CrudNetViewMode.EDIT) {
        obj = this.service.update(p);
      } else {
        obj = this.service.del(p);
      }
      obj.subscribe((res) => {
        debugger;
        if (res.error) {
          this.error.emit(res.message);
        } else if (res.output && res.output.ERRORMESSAGE) {
          this.error.emit(res.output.ERRORMESSAGE);
        } else {
          this.success.emit("OK");
        }
        this.close.emit();
      });
    }
  }

  /**
   * method that tranform name of table from camel case to space/lodash separated
   */
  camelCaseToString(s) {
    return _.capitalize(s.split(/(?=[A-Z])/).join(" "));
  }

  /**
   * method that tranform name of table from camel case to uppercase underscore separated
   */
  camelCaseToLabel(s) {
    return s
      .split(/(?=[A-Z])/)
      .join("_")
      .toUpperCase();
  }

  /**
   * method that get table name from field name
   *
   * @example
   * fkIdAnagrafica -> Anagrafica
   */
  getTableNameFromField(field) {
    let f = field.replace("fk", "");
    if (f.indexOf("id") === 0 || f.indexOf("Id") === 0) {
      f = f.replace("id", "").replace("Id", "");
    }
    return f[0].toUpperCase() + f.slice(1);
  }

  /**
   * method that tranform form object to plain object valid for crudnet
   *
   */
  stateToObject(formData) {
    const ret = {};

    if (this.viewMode === CrudNetViewMode.DELETE) {
      ret["id" + this.tableName] = this.idCurrent;
      return ret;
    }

    if (this.viewMode === CrudNetViewMode.EDIT) {
      ret["id" + this.tableName] = this.idCurrent;
    }

    formData.forEach((fd) => {
      if (fd.type === CrudNetFieldType.FIELD_DATE) {
        if (fd.value && moment(fd.value).isValid()) {
          ret[fd.id] = moment(fd.value).format("YYYY-MM-DDTHH:mm:ss");
        }
      } /*else if (fd.type === CrudNetFieldType.FIELD_MONTH) {
        if (fd.value) {
          ret[fd.id] = fd.value.value;
        }
      } */ else if (
        fd.type === CrudNetFieldType.FIELD_AUTOCOMPLETE ||
        fd.type === CrudNetFieldType.FIELD_AUTOCOMPLETE_SELECTFIRST ||
        fd.type === CrudNetFieldType.FIELD_SELECT
      ) {
        if (fd.value) {
          const fieldName =
            "id" + this.getTableNameFromField(fd.realId || fd.id);
          ret[fd.id] = fd.value[fieldName];
        }
      } else if (fd.type === CrudNetFieldType.FIELD_SELECT_MULTIPLE) {
        const fieldName = "id" + this.getTableNameFromField(fd.realId || fd.id);
        if (fd.value && fd.value.length) {
          let list = "";
          fd.value.forEach((el) => {
            const str =
              el === fd.value[fd.value.length - 1]
                ? el.realValue || el[fieldName]
                : el.realValue + "," || el[fieldName] + ",";
            list = list + str;
          });
          ret[fd.id] = list;
        }
      } else if (fd.type === CrudNetFieldType.FIELD_INTEGER) {
        if (fd.value) {
          ret[fd.id] = parseInt(fd.value);
        }
      } else if (fd.type === CrudNetFieldType.FIELD_DECIMAL) {
        if (fd.value) {
          ret[fd.id] = parseFloat(fd.value);
        }
      } else if (fd.type === CrudNetFieldType.FIELD_FILE) {
        ret[fd.id] = fd.value;
      } else if (fd.type === CrudNetFieldType.FIELD_MAPS) {
        if (fd.value) {
          fd.value.latitudine = parseFloat(fd.value.latitudine);
          fd.value.longitudine = parseFloat(fd.value.longitudine);
          ret[fd.id] = fd.value;
        }
      } else {
        ret[fd.id] = fd.value;
      }
    });
    return ret;
  }

  /**
   * method that call crudnet tabledef
   *
   * after create form fields
   *
   */
  _getFormFields() {
    return this.service
      .tabledef()
      .toPromise()
      .then((res): CrudNetField[] => {
        let formField = Array<CrudNetField>();
        if (!res.error) {
          formField = res.result.columns
            .filter(
              (col) =>
                col.type.indexOf("Boolean") >= 0 ||
                col.type.indexOf("String") >= 0 ||
                col.type.indexOf("Int") >= 0 ||
                col.type.indexOf("Decimal") >= 0 ||
                col.type.indexOf("Date") >= 0 ||
                col.type.indexOf("Byte") >= 0 ||
                col.type.indexOf("Maps") >= 0 ||
                col.type === "Month" ||
                col.name.indexOf("fk") >= 0 ||
                col.type.indexOf("Password") >= 0
            )
            .map((col) => {
              let type = CrudNetFieldType.FIELD_TEXT;
              let multiple = false;
              const ret = new CrudNetField();

              if (col.type === "Month") {
                type = CrudNetFieldType.FIELD_MONTH;
              } else if (col.type === "Autocomplete") {
                type = CrudNetFieldType.FIELD_AUTOCOMPLETE;
              } else if (col.type === "AutocompleteSelectFirst") {
                type = CrudNetFieldType.FIELD_AUTOCOMPLETE_SELECTFIRST;
              } else if (col.type === "ICollection") {
                type = CrudNetFieldType.FIELD_SELECT_MULTIPLE;
                multiple = true;
              } else if (col.name.indexOf("fk") >= 0) {
                type = CrudNetFieldType.FIELD_SELECT;
              } else if (col.type.indexOf("Int") >= 0) {
                type = CrudNetFieldType.FIELD_INTEGER;
              } else if (col.type.indexOf("Boolean") >= 0) {
                type = CrudNetFieldType.FIELD_BOOLEAN;
              } else if (col.type.indexOf("Decimal") >= 0) {
                type = CrudNetFieldType.FIELD_DECIMAL;
              } else if (col.type.indexOf("Date") >= 0) {
                type = CrudNetFieldType.FIELD_DATE;
              } else if (col.type.indexOf("Byte") >= 0) {
                type = CrudNetFieldType.FIELD_FILE;
                ret.acceptFileType = col.acceptFileType;
              } else if (col.type.indexOf("String") >= 0 && col.maxLen > 200) {
                type = CrudNetFieldType.FIELD_LONGTEXT;
              } else if (col.type === "Maps") {
                type = CrudNetFieldType.FIELD_MAPS;
              } else if (
                col.type === "Password" ||
                col.name.toLowerCase() == "password"
              ) {
                type = CrudNetFieldType.FIELD_PASSWORD;
              }

              ret.type = type;
              ret.id = col.name;
              ret.realId = col.realName;

              // ret.label = this.camelCaseToString(col.name);
              ret.label = this.camelCaseToLabel(col.name);
              ret.required = col.required;
              ret.precision = col.precision || col.maxLen || 25;
              ret.readOnly = false;
              ret.multiple = multiple;
              ret.hideMe = col.hideMe
                ? (fd) =>
                    col.hideMe(this.stateToObject(fd), fd, this.externalFields)
                : null;
              return ret;
            })
            .filter((c) => this.tableName !== this.getTableNameFromField(c.id));
        }
        return _.orderBy(formField, "required", "desc");
      });
  }

  /**
   * method that call crudnet for list field composition
   *
   */
  _loadLookup = (tableName) => {
    const params = new CrudNetSearchRequest();
    params.pageNum = 0;
    params.pageSize = -1;

    if (this.lookupFilters && this.lookupFilters[tableName]) {
      params.filter = this.lookupFilters[tableName];
    }

    return this.service
      .search(params, tableName)
      .toPromise()
      .then((res) => res.result);
  };

  /**
   * method that compose list field with lookupsData retrived from _loadLookup method
   *
   */
  _loadOptions = async (formFields: CrudNetField[]) => {
    for (let i = 0; i < formFields.length; i++) {
      if (
        // formFields[i].type === CrudNetFieldType.FIELD_SELECT ||
        formFields[i].type === CrudNetFieldType.FIELD_SELECT_MULTIPLE
      ) {
        formFields[i].options = await this._loadLookup(
          this.getTableNameFromField(formFields[i].realId || formFields[i].id)
        );
        formFields[i].displayValue = null;
        if (this.refFieldMap) {
          formFields[i].displayValue = this.refFieldMap[formFields[i].id];
        }
        /*to implement override on service
        if (_.isFunction(this.service.getRefFieldMap)) {
          formFields[i].displayValue = this.service.getRefFieldMap()[
            formFields[i].id
          ];
        }*/
      } else if (
        formFields[i].type === CrudNetFieldType.FIELD_SELECT ||
        formFields[i].type === CrudNetFieldType.FIELD_AUTOCOMPLETE ||
        formFields[i].type === CrudNetFieldType.FIELD_AUTOCOMPLETE_SELECTFIRST
      ) {
        formFields[i].displayValue = "descrizione";
        if (this.refFieldMap && this.refFieldMap[formFields[i].id]) {
          formFields[i].displayValue = this.refFieldMap[formFields[i].id];
        }
        formFields[i].optionSearch = (query): Observable<Array<any>> => {
          let filter = "";
          let filterVal = [];

          if (query !== "") {
            filter = formFields[i].displayValue + ".Contains(@0)";
            filterVal = [{ value: query }];
          }

          const parentF = this.getParentFilter(
            formFields[i].id,
            filterVal.length
          );
          if (parentF.expression && parentF.expression.length) {
            filter = [filter, parentF.expression].join(" && ");
          }
          filterVal = [...filterVal, ...parentF.expressionValues];
          this.service.hideLoadingSpinner = true;
          return this.service
            .search(
              {
                pageNum: 0,
                pageSize: -1, // formFields[i].type === CrudNetFieldType.FIELD_SELECT?-1: 20,
                order: [formFields[i].displayValue],
                filter: {
                  expression: filter,
                  expressionValues: filterVal,
                },
              },
              this.getTableNameFromField(
                formFields[i].realId || formFields[i].id
              )
            )
            .pipe(
              map((res) => {
                this.service.hideLoadingSpinner = false;
                const typed = query.toLowerCase();
                const sortedArray = this.taalService.sortCounterObj(
                  res.result,
                  (obj) => {
                    const val = obj[formFields[i].displayValue]
                      .toString()
                      .toLowerCase();
                    return val == typed
                      ? 0
                      : val.indexOf(typed) == 0
                      ? 1
                      : val.indexOf(typed) > 0
                      ? 2
                      : 3;
                  }
                );
                return formFields[i].type === CrudNetFieldType.FIELD_SELECT
                  ? sortedArray
                  : sortedArray.slice(0, 20);
              })
            );
        };
      } else if (formFields[i].type === CrudNetFieldType.FIELD_MONTH) {
        formFields[i].displayValue = "label";
        formFields[i].optionSearch = (query): Observable<Array<any>> => {
          return of(
            moment.months().map((month, index) => {
              return {
                label: month,
                value: index + 1,
              };
            })
          ).pipe(
            map((months) => {
              return months.filter((m) =>
                m.label.toLowerCase().includes(query.toLowerCase())
              );
            })
          );
        };
      }
    }
    return formFields;
  };

  getRelationChild(idParent) {
    if (this.relatedFields) {
      return this.relatedFields.filter((rel) => rel.parent === idParent);
    }
    return null;
  }

  getRelationParent(idChild) {
    if (this.relatedFields) {
      return this.relatedFields.filter((rel) => rel.child === idChild);
    }
    return null;
  }

  getParentFilter(idChild, startIndex): CrudNetFilterExpression {
    const ret = new CrudNetFilterExpression();
    const expressions = [];
    ret.expressionValues = [];
    const counter = startIndex;
    if (this.relatedFields) {
      this.relatedFields
        .filter((rel) => rel.child === idChild)
        .forEach((rel) => {
          const parentField = this.fields.find((f) => f.id === rel.parent);
          if (parentField && parentField.value) {
            expressions.push(
              (parentField.realId || parentField.id) + "==@" + counter
            );
            const val = this.stateToObject([parentField]);
            ret.expressionValues.push({ value: val[parentField.id] });
          }
        });
    }
    ret.expression = expressions.join(" && ");
    return ret;
  }

  /**
   * method that set default values to form fields
   *
   */
  // tslint:disable-next-line:variable-name
  _selectValueDefault = (formFields: CrudNetField[]) => {
    if (this.viewMode === CrudNetViewMode.CREATE && this.defaultValues) {
      let formFieldsWithVal = [...formFields];
      Object.keys(this.defaultValues).forEach((key) => {
        formFieldsWithVal = formFieldsWithVal.map((field) => {
          if (field.id === key) {
            if (
              field.type === CrudNetFieldType.FIELD_AUTOCOMPLETE ||
              field.type === CrudNetFieldType.FIELD_AUTOCOMPLETE_SELECTFIRST ||
              field.type === CrudNetFieldType.FIELD_SELECT
            ) {
              field.value = this.defaultValues[key];
              /*              field.value = field.options.find((op) => {
                return (
                  op[
                  'id' + this.getTableNameFromField(field.realId || field.id)
                    ] === this.defaultValues[key]
                );
              });*/
            } else {
              field.value = this.defaultValues[key];
            }
          }
          return field;
        });
      });
      return this.loadAllValuesEditAutocomplete(formFieldsWithVal);
    }
    return Promise.resolve(formFields);
  };

  /**
   * method that call crudnet and retrive record data by id;
   *
   * after set fields value with data retrived.
   *
   */
  // tslint:disable-next-line:variable-name
  _selectValueEdit = (formFields) => {
    let ret = Promise.resolve(formFields);
    if (
      this.viewMode === CrudNetViewMode.EDIT ||
      this.viewMode === CrudNetViewMode.VIEW
    ) {
      ret = this.service
        .find(this.idCurrent)
        .toPromise()
        .then((res) => {
          if (!res.error) {
            const selected = res.result;
            const formFieldsWithVal = formFields.map((field) => {
              /*if (field.type === CrudNetFieldType.FIELD_SELECT) {
                field.value = field.options.find((op) => {
                  return (
                    op[
                      'id' +
                        this.getTableNameFromField(field.realId || field.id)
                    ] === selected[field.id]
                  );
                });
              } else*/
              if (field.type === CrudNetFieldType.FIELD_SELECT_MULTIPLE) {
                // field.value = field.options.filter((op) => {
                //   return (
                //     op[
                //     'id' +
                //     this.getTableNameFromField(field.realId || field.id)
                //       ] === selected[field.id]
                //   );
                // });
              } else if (field.type === CrudNetFieldType.FIELD_DATE) {
                const mD = moment(selected[field.id]);
                if (mD.isValid()) {
                  field.value = mD.toDate();
                }
              } else if (
                field.type === CrudNetFieldType.FIELD_AUTOCOMPLETE ||
                field.type ===
                  CrudNetFieldType.FIELD_AUTOCOMPLETE_SELECTFIRST ||
                field.type === CrudNetFieldType.FIELD_SELECT
              ) {
                field.value = selected[field.id];
              } else {
                /*else if (field.type === CrudNetFieldType.FIELD_MONTH) {

                field.value = this.months.find(
                  (m) => m.value === selected[field.realId || field.id]
                );
              }
              */
                field.value = selected[field.realId || field.id];
              }

              return field;
            });

            return formFieldsWithVal;
          }
        })
        .then(this.loadAllValuesEditAutocomplete.bind(this));
    }
    return ret.then((formFields) =>
      formFields.map((f) => {
        f.readOnly = this.fieldChildMustReadOnly(f, formFields);
        return f;
      })
    );
  };

  loadAllValuesEditAutocomplete(formFields: CrudNetField[]) {
    return new Promise((ok, ko) => {
      const promises = [];
      formFields.forEach((f, i) => {
        if (
          f.type === CrudNetFieldType.FIELD_AUTOCOMPLETE ||
          f.type === CrudNetFieldType.FIELD_AUTOCOMPLETE_SELECTFIRST ||
          f.type === CrudNetFieldType.FIELD_SELECT
        ) {
          promises.push(this.loadValueEditAutocomplete(f, i));
        }
      });
      Promise.all(promises).then((fi) => {
        const newFF = [...formFields];
        fi.forEach((fAu) => {
          newFF[fAu.index] = fAu.field;
        });
        ok(newFF);
      });
    });
  }

  fieldChildMustReadOnly(field: CrudNetField, formFields: CrudNetField[]) {
    const rel = this.getRelationParent(field.id);
    let readOnly = false;
    if (this.viewMode === CrudNetViewMode.VIEW) {
      readOnly = true;
    } else if (rel && rel.length) {
      readOnly =
        rel
          .map((rf) => formFields.find((f) => f.id === rf.parent))
          .filter((f) => !f.value).length > 0;
    }
    return readOnly;
  }

  loadValueEditAutocomplete(field: CrudNetField, index: number) {
    const tableName = this.getTableNameFromField(field.realId || field.id);
    const value = field.value;
    if (value) {
      return this.service
        .find(value, tableName)
        .toPromise()
        .then((res) => {
          field.value = res.result;
          return { field, index };
        });
    }
    return Promise.resolve({ field, index });
  }

  selectChanged(field, item) {
    // parent gesture to clear child
    const children = this.getRelationChild(field.id);
    if (children && children.length) {
      this.show = false;
      this.fields = this.fields.map((field) => {
        const child = children.find((f) => f.child === field.id);
        if (child) {
          field.value = null;
          field.readOnly = !item;
        }
        return field;
      });
      setTimeout(() => {
        this.show = true;
      }, 10);
    }

    // todo child gesture to load parent value
  }

  closeModalL() {
    this.close.emit();
  }

  isDisabled(id: string): boolean {
    return this.disabledFields.indexOf(id) > -1;
  }

  resetForm() {
    this.show = false;
    this.service.hideLoadingSpinner = true;
    this._getFormFields()
      .then((fields) => this._loadOptions(fields))
      .then((fields) => this._selectValueDefault(fields))
      .then((fields: CrudNetField[]) => {
        this.fields = fields;
        this.show = true;
        this.service.hideLoadingSpinner = false;
        setTimeout(() => {
          this.init.emit(true);
        }, 500);
      });
  }
}
