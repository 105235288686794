import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import {BaseRepo} from '../../repos/BaseRepo';
import {CrudNetExecRequest} from 'crudnet-amgular';

@Injectable({
  providedIn: 'root'
})
export class RapportinoService extends BaseRepo<any> {

  getTable() {
    return 'Rapportino';
  }

  getRapportinoByAnnoMese = (anno, mese, orders?, filters?, idImpiegato=0) => {
    return this.exec('sp_GetRapportinoDettaglio', {
        par: {
          IDATTIVITA: 0,
          IDIMPIEGATO: idImpiegato,
          ANNO: anno,
          MESE: mese
        },
        order: orders,
        filter: filters,
        pageNum: 0,
        pageSize: -1
    });
  }

  getGestioneRapportino= (anno,mese,orders?, filters?)=>{
    return this.exec('fn_GetGestioneRapportini', {
      par: {
        ANNO: anno,
        MESE: mese
      },
      order: orders,
      filter: filters,
      pageNum: 0,
      pageSize: -1
    });
  }

  getMeseByUtenteAttivita = (idUtente, idAttivita, anno, mese) => {
      return this.exec('sp_GetRapportinoDettaglio',

      {
        par: {
            IDATTIVITA: idAttivita,
            IDIMPIEGATO: idUtente,
            ANNO: anno,
            MESE: mese
        },
        order: [],
        pageNum: 0,
        pageSize: -1
    });
  }

  getMeseByUtenteSal = (idUtente, idSal, anno, mese) => {
    return this.exec('fn_GetSalImpiegatoRapportini',
    {
      par: {
          IDSAL: idSal,
          IDIMPIEGATO: idUtente,
          ANNO: anno,
          MESE: mese
      },
      order: [],
      pageNum: 0,
      pageSize: -1
  });
  }

  getValueForChart = (anno, mese) => {
    return this.exec('fn_DashboardCompletamentoRapportino',
    {
      par: {
          ANNO: anno,
          MESE: mese
      },
      order: [],
      pageNum: 0,
      pageSize: -1
  });
  }

  getIdCurrentRapportino = (idImpiegato, anno, mese) => {
    const params: CrudNetExecRequest = {
      par: {
        IDIMPIEGATO: idImpiegato,
        ANNO: anno,
        MESE: mese
      },
      order: [],
      pageNum: 0,
      pageSize: -1
    };

    return this.exec('sp_CreateRapportino', params);
  }

  saveMese = (idRapportino, idImpiegato, anno, mese, data) => {
      this.showSpinnerDebounce();
      const params = {
            lstAction: data.map(record => {
                return {action: 'exec', entity: {...record, IDRAPPORTINO: idRapportino}};
            }),
            orderOfInsertion: 1,
            tableName: 'sp_InsertRapportinoDettaglio'
        };
      return this.getHttpClient().post(this.getUrl(`/Generic/updMulti/dummy`), [
            // {
            // lstAction: [
            //     {
            //         action: 'exec',
            //         entity: {
            //         IDRAPPORTINO: idRapportino,
            //         IDIMPIEGATO: idImpiegato,
            //         ANNO: anno,
            //         MESE: mese
            //         },
            //
            //     }
            //
            // ],
            // orderOfInsertion: 1,
            // tableName: 'sp_CreateRapportino'
            // },
            params
        ], {
            headers: this.getHeaders()
        }).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
    }


    consolidaMese = (idRapportino) => this.exec('sp_ConsolidaRapportino', {
                                                                            par: {idRapportino},
                                                                            order: [],
                                                                            pageNum: 0,
                                                                            pageSize: -1
                                                                        })

    deconsolidaMese = (idRapportino) => this.exec('sp_DeconsolidaRapportino', {
                                                                                par: {idRapportino},
                                                                                order: [],
                                                                                pageNum: 0,
                                                                                pageSize: -1
                                                                            })

}
