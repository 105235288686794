import {Component, Inject, OnInit} from '@angular/core';
import {LoginService} from './pages/login/login.service';
import {AuthService} from './auth/auth.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'argon-dashboard-angular';

  constructor(private loginService: LoginService, private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    // this.loginService.getAuthState().subscribe((user) => {
    //   if (user !== null) {
    //     // se sei loggato con google ma non con taal fai la login taal e ottieni token e ruoli
    //     if (!this.auth.isAuthenticated()) {
    //       this.loginService.loginTaalWithGoogle(user.idToken).subscribe((res: TaalLoginRes) => {
    //         localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, res.token);
    //         this.loginService.readRoles(res.idImpiegato).subscribe(() => {
    //           this.router.navigate(['dashboard']);
    //         });
    //       });
    //     } else { // altrimenti ottieni i ruoli
    //       this.loginService.readRoles(this.auth.getUserInfo().iduser).subscribe(() => {
    //         console.log('------------', this.auth.getRoles())
    //       });
    //     }
    //   }
    // });

  }
}
