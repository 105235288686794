import { Injectable } from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {CostoGeneraleModel} from './costo-generale.model';
import {forkJoin, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {CrudNetBaseResponse, CrudNetExecRequest, CrudNetResultResponse, CrudNetSearchRequest, CrudNetUpdateRequest} from 'crudnet-amgular';
import {sumBy, groupBy, assign} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CostoGeneraleService extends BaseRepo<CostoGeneraleModel> {

  getTable(): string {
  return 'CostoGenerale';
  }

  add(params: CrudNetUpdateRequest<CostoGeneraleModel>, customTable?: string): Observable<CrudNetBaseResponse<CostoGeneraleModel>> {
    return super.find(params.entity.fkIdBudget, 'Budget').pipe(
      mergeMap(res => {
        params.entity.anno = res.result.anno;
        return super.add(params, customTable);
      })
    );
  }

  del(params: CrudNetUpdateRequest<CostoGeneraleModel>, customTable?: string): Observable<CrudNetBaseResponse<CostoGeneraleModel>> {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {
        ANNO: params.entity.anno,
        IDTIPOCOSTOGENERALE: params.entity.fkIdTipoCostoGenerale
      }
    };
    return super.exec('sp_DeleteCategoriaCostoGenerale', execParams);
  }

  // tslint:disable-next-line:max-line-length
  search(params: CrudNetSearchRequest<CostoGeneraleModel>, customTable?: any): Observable<CrudNetResultResponse<CostoGeneraleModel>> {
    if (!customTable) {
      params.includes = ['TipoCostoGenerale', 'TipoBudget', 'Budget'];
      params.pageSize = -1;
      return super.search2(params).pipe(
        map(res => {
          const ret = [];
          const grouped = groupBy(res.result, (r) => r.anno + '$' + r.fkIdTipoCostoGenerale);
          Object.keys(grouped).forEach(key => {
            let sum = 0;
            let sumConsutivo = 0;
            let mapped = {};
            grouped[key].forEach(elem => {
              if (elem.TipoBudget.codice === 'CONS') {
                sumConsutivo += elem.importo;
              } else {
                sum += elem.importo;
              }
              mapped = {...elem, importo: sum, consuntivo: sumConsutivo};
            });
            ret.push(mapped);
          });
          res.result = ret;
          res.rowCount = ret.length;
          return res;
        })
      );
    }
    return super.search(params, customTable);
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map(res => {
        res.result.columns = res.result.columns.map(c => {
          if (c.name === 'mese') {
            c.type = 'Month';
          }
          if (
            c.name === 'fkIdTipoBudget' ||
            c.name === 'flgPlaceholder' ||
            c.name === 'anno' ||
            c.name === 'mese' ||
            c.name === 'importo'
          ) {
            c.hideMe = (parsedForm, orginalForm, externalData) => {
              return true;
            };
          }
          return c;
        });
        return res;
      })
    );
  }

  getDataPreventivoConsuntivo(params: CrudNetSearchRequest<any>, idBudget: number): Observable<CrudNetResultResponse<any>> {
    const parPreventivo: CrudNetExecRequest = {
      order: params.order,
      pageSize: -1,
      pageNum: 0,
      par: {
        IDBUDGET: idBudget,
        IDTIPOBUDGET: 1
      }
    };
    const parConsuntivo: CrudNetExecRequest = {
      order: params.order,
      pageSize: -1,
      pageNum: 0,
      par: {
        IDBUDGET: idBudget,
        IDTIPOBUDGET: 2
      }
    };
    const consuntivo = super.exec('fn_BudgetDettaglioCostiGenerali', parConsuntivo);
    const preventivo = super.exec('fn_BudgetDettaglioCostiGenerali', parPreventivo);

    /*      params.includes = ['TipoOfferta', 'Budget.TipoBudget'];
          params.pageSize = -1;*/
    return forkJoin([preventivo, consuntivo]).pipe(
      map(r => {
        // const res: CrudNetResultResponse<any>;
        const resPrev = r[0].result.map(prev => ({
          descrizione: prev.DESCRIZIONE,
          preventivo: prev.IMPORTO ? prev.IMPORTO : 0
        })).filter(e => e.descrizione);
        const resCons = r[1].result.map(cons => ({
          descrizione: cons.DESCRIZIONE,
          consuntivo: cons.IMPORTO ? cons.IMPORTO : 0
        })).filter(e => e.descrizione);

        const mappedResCons = Object.values(groupBy(resCons, 'descrizione')).map(val => (
          {
            descrizione: val[0].descrizione,
            consuntivo: sumBy(val, 'consuntivo')
          }
        ));
        const groupedArrayToUnify = groupBy([...resPrev, ...mappedResCons], 'descrizione');
        const unifiedArray = [];
        Object.keys(groupedArrayToUnify).forEach(key => {
          unifiedArray.push(assign(...groupedArrayToUnify[key]));
        });
        const res = r[0];
        res.result = unifiedArray;
        res.rowCount = unifiedArray.length;
        return res;
      })
    );
  }

}
