import { Injectable } from '@angular/core';
import { BaseRepo } from 'src/app/repos/BaseRepo';
import { TipoMessaggio } from './tipo-messaggio.model';

@Injectable({
  providedIn: 'root'
})
export class TipoMessaggioService extends BaseRepo<TipoMessaggio> {
  
  getTable(): string {
    return "TipoMessaggio";
  }

}
