import { Routes } from "@angular/router";
import { environment } from "../../../environments/environment";
import { DashboardComponent } from "../../pages/dashboard/dashboard.component";
// import { MapsComponent } from '../../pages/maps/maps.component';
import { UserProfileComponent } from "../../pages/user-profile/user-profile.component";
import { AuthGuardService as AuthGuard } from "../../auth/auth-guard.service";
import { RoleGuardService as RoleGuard } from "../../auth/role-guard.service";
import { SocietaComponent } from "../../modules/societa/societa.component";
import { OffertaComponent } from "../../modules/offerta/offerta.component";
import { ImpiegatoComponent } from "../../modules/impiegato/impiegato.component";
import { ClienteComponent } from "../../modules/cliente/cliente.component";
import { CommessaComponent } from "../../modules/commessa/commessa.component";
import { RichiesteComponent } from "../../modules/richieste/richieste.component";
import { RapportinoComponent } from "../../modules/rapportino/rapportino.component";
import { SALComponent } from "../../modules/sal/sal.component";
import { GestioneRapportinoComponent } from "../../modules/gestione-rapportino/gestione-rapportino.component";
import { LookupsComponent } from "../../modules/lookups/lookups.component";
import { RapportinoGuard } from "src/app/auth/rapportino-guard.service";
import { PermessoComponent } from "src/app/modules/permesso/permesso.component";
import { GestioneFeriePermessiComponent } from "../../modules/gestione-ferie-permessi/gestione-ferie-permessi.component";
import { ROLES } from "../../constants";
import { ProfiloComponent } from "src/app/modules/profilo/profilo.component";
import { GestioneMagazzinoComponent } from "src/app/modules/gestione-magazzino/gestione.magazzino.component";
import { ReportComponent } from "src/app/modules/report/report.component";
import { TipologiaContrattoComponent } from "../../modules/tipologia-contratto/tipologia-contratto.component";
import { TipologiaFeriePermessiComponent } from "../../modules/tipologia-ferie-permessi/tipologia-ferie-permessi.component";
import { AssegnazioniMagazzinoComponent } from "../../modules/assegnazioni-magazzino/assegnazioni-magazzino.component";
import { BudgetComponent } from "../../modules/budget/budget.component";
import { ImportCostiPersonaleComponent } from "src/app/modules/import-costi-personale/import-costi-personale.component";
import { CostoGeneraleComponent } from "../../modules/costo-generale/costo-generale.component";
import { ArchivioCandidatiComponent } from "src/app/modules/archivio-candidati/archivio-candidati.component";
import { RichiesteInLavorazioneComponent } from "src/app/modules/richieste-in-lavorazione/richieste-in-lavorazione.component";
import { SkillsComponent } from "src/app/modules/skills/skills.component";

let staticRoutes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "DASHBOARD",
      icon: "fas fa-tachometer-alt text-blue",
      class: "",
    },
  },
  /*{
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: 'DASHBOARD',
      icon: 'fas fa-tachometer-alt text-blue',
      class: '',
    }
  },*/
  {
    path: "profilopersonale",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "PROFILO_PERSONALE",
      class: "",
      icon: "fas fa-id-card text-blue",
    },
    children: [
      {
        path: "user-profile",
        component: UserProfileComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "USER_PROFILE",
          icon: "ni ni-single-02 text-blue",
          class: "",
        },
      },
      {
        path: "rapportino",
        component: RapportinoComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "RAPPORTINO",
          class: "",
          icon: "ni ni-calendar-grid-58 text-blue",
        },
        canDeactivate: [RapportinoGuard],
      },
      {
        path: "permesso",
        component: PermessoComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "PERMESSO",
          class: "",
          icon: "ni ni-album-2 text-blue",
        },
      },
    ],
  },
  {
    path: "vendita",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "VENDITA",
      class: "",
      icon: "fas fa-shopping-cart text-blue",
      expectedRoles: [
        ROLES.ROLE_READ_SAL,
        ROLES.ROLE_WRITE_SAL,
        ROLES.ROLE_READ_COMMESSA,
        ROLES.ROLE_WRITE_COMMESSA,
        ROLES.ROLE_READ_CLIENTE,
        ROLES.ROLE_WRITE_CLIENTE,
      ],
    },
    children: [
      {
        path: "cliente",
        component: ClienteComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "CLIENTE",
          class: "",
          icon: "ni ni-circle-08 text-blue",
          expectedRoles: [ROLES.ROLE_READ_CLIENTE, ROLES.ROLE_WRITE_CLIENTE],
        },
      },
      {
        path: "offerta",
        component: OffertaComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "OFFERTE",
          class: "",
          icon: "ni ni-single-copy-04 text-blue",
          expectedRoles: [ROLES.ROLE_READ_OFFERTA, ROLES.ROLE_WRITE_OFFERTA],
        },
      },
      {
        path: "commessa",
        component: CommessaComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "COMMESSE",
          class: "",
          icon: "ni ni-box-2 text-blue",
          expectedRoles: [ROLES.ROLE_READ_COMMESSA, ROLES.ROLE_WRITE_COMMESSA],
        },
      },
    ],
  },
  {
    path: "HR",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "HR",
      class: "",
      icon: "fas fa-globe text-blue",
      expectedRoles: [
        ROLES.ROLE_READ_IMPIEGATO,
        ROLES.ROLE_WRITE_IMPIEGATO,
        ROLES.ROLE_WRITE_TRATTAMENTO_ECONOMICO,
        ROLES.ROLE_READ_TRATTAMENTO_ECONOMICO,
        ROLES.ROLE_READ_SOCIETA,
        ROLES.ROLE_WRITE_SOCIETA,
        ROLES.ROLE_READ_TIPOLOGIA_CONTRATTI,
        ROLES.ROLE_WRITE_TIPOLOGIA_CONTRATTI,
        ROLES.ROLE_READ_GESTIONE_MAGAZZINO,
        ROLES.ROLE_WRITE_GESTIONE_MAGAZZINO,
        ROLES.ROLE_READ_GESTIONE_FERIE,
        ROLES.ROLE_WRITE_GESTIONE_FERIE,
      ],
    },
    children: [
      {
        path: "personale",
        component: ImpiegatoComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "PERSONALE",
          class: "",
          icon: "ni ni-badge text-blue",
          expectedRoles: [
            ROLES.ROLE_READ_IMPIEGATO,
            ROLES.ROLE_WRITE_IMPIEGATO,
            ROLES.ROLE_WRITE_TRATTAMENTO_ECONOMICO,
            ROLES.ROLE_READ_TRATTAMENTO_ECONOMICO,
          ],
        },
      },
      {
        path: "societa",
        component: SocietaComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "SOCIETA",
          class: "",
          icon: "ni ni-building text-blue",
          expectedRoles: [ROLES.ROLE_READ_SOCIETA, ROLES.ROLE_WRITE_SOCIETA],
        },
      },
      {
        path: "tipologiacontratto",
        component: TipologiaContrattoComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "TIPOLOGIA_CONTRATTO",
          class: "",
          icon: "fas fa-file-contract text-blue",
          expectedRoles: [
            ROLES.ROLE_READ_TIPOLOGIA_CONTRATTI,
            ROLES.ROLE_WRITE_TIPOLOGIA_CONTRATTI,
          ],
        },
      },
      {
        path: "gestionemagazzino",
        component: GestioneMagazzinoComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "GESTIONE_MAGAZZINO",
          class: "",
          icon: "fas fa-boxes text-blue",
          expectedRoles: [
            ROLES.ROLE_READ_GESTIONE_MAGAZZINO,
            ROLES.ROLE_WRITE_GESTIONE_MAGAZZINO,
          ],
        },
      },
      {
        path: "assegnazionimagazzino",
        component: AssegnazioniMagazzinoComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "ASSEGNAZIONE_MAGAZZINO",
          class: "",
          icon: "fas fa-boxes text-blue",
          expectedRoles: [
            ROLES.ROLE_READ_GESTIONE_MAGAZZINO,
            ROLES.ROLE_WRITE_GESTIONE_MAGAZZINO,
          ],
        },
      },
      {
        path: "gestioneferiepermessi",
        component: GestioneFeriePermessiComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "GESTIONE_FERIE_E_PERMESSI",
          class: "",
          icon: "ni ni-chart-bar-32 text-blue",
          expectedRoles: [
            ROLES.ROLE_READ_GESTIONE_FERIE,
            ROLES.ROLE_WRITE_GESTIONE_FERIE,
          ],
        },
      },
      {
        path: "tipologiaferiepermessi",
        component: TipologiaFeriePermessiComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "TIPOLOGIA_FERIE_E_PERMESSI",
          class: "",
          icon: "ni ni-chart-bar-32 text-blue",
          expectedRoles: [
            ROLES.ROLE_READ_TIPOLOGIA_FERIE,
            ROLES.ROLE_WRITE_TIPOLOGIA_FERIE,
          ],
        },
      },
    ],
  },
  {
    path: "amministrazione",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "AMMINISTRAZIONE",
      class: "",
      icon: "ni ni-books text-blue",
      expectedRoles: [
        ROLES.ROLE_READ_SAL,
        ROLES.ROLE_WRITE_SAL,
        ROLES.ROLE_WRITE_GESTIONE_RAPPORTINO,
        ROLES.ROLE_READ_GESTIONE_RAPPORTINO,
      ],
    },
    children: [
      {
        path: "sal",
        component: SALComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "SAL",
          class: "",
          icon: "ni ni-money-coins text-blue",
          expectedRoles: [ROLES.ROLE_READ_SAL, ROLES.ROLE_WRITE_SAL],
        },
      },
      {
        path: "gestionerapportino",
        component: GestioneRapportinoComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "GESTIONE_RAPPORTINO",
          class: "",
          icon: "ni ni-chart-bar-32 text-blue",
          expectedRoles: [
            ROLES.ROLE_WRITE_GESTIONE_RAPPORTINO,
            ROLES.ROLE_READ_GESTIONE_RAPPORTINO,
          ],
        },
      },
    ],
  },
  {
    path: "budget",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "BUDGET",
      class: "",
      icon: "fas fa-chart-pie text-blue",
      expectedRoles: [ROLES.ROLE_READ_BUDGET, ROLES.ROLE_WRITE_BUDGET],
    },
    children: [
      {
        path: "budget",
        component: BudgetComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "BUDGET",
          class: "",
          icon: "fas fa-dollar-sign text-blue",
          expectedRoles: [ROLES.ROLE_READ_BUDGET, ROLES.ROLE_WRITE_BUDGET],
        },
      },
      {
        path: "importcp",
        component: ImportCostiPersonaleComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "IMPORT",
          class: "",
          icon: "fas fa-upload text-blue",
          expectedRoles: [ROLES.ROLE_READ_BUDGET, ROLES.ROLE_WRITE_BUDGET],
        },
      },
      {
        path: "costoGenerale",
        canActivate: [AuthGuard, RoleGuard],
        component: CostoGeneraleComponent,
        data: {
          title: "COSTO_GENERALE",
          class: "",
          icon: "fas fa-money-bill-wave-alt text-blue",
          expectedRoles: [ROLES.ROLE_READ_BUDGET, ROLES.ROLE_WRITE_BUDGET],
        },
      },
    ],
  },
  {
    path: "admin",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "ADMIN",
      class: "",
      icon: "fas fa-crown text-blue",
      expectedRoles: [
        ROLES.ROLE_READ_PROFILO,
        ROLES.ROLE_WRITE_PROFILO,
        ROLES.ROLE_READ_LOOKUPS,
        ROLES.ROLE_WRITE_LOOKUPS,
        ROLES.ROLE_READ_REPORT,
        ROLES.ROLE_WRITE_REPORT,
      ],
    },
    children: [
      {
        path: "profilo",
        component: ProfiloComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "PROFILO",
          class: "",
          icon: "fa fa-users-cog text-blue",
          expectedRoles: [ROLES.ROLE_READ_PROFILO, ROLES.ROLE_WRITE_PROFILO],
        },
      },
      {
        path: "reports",
        component: ReportComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "REPORT",
          class: "",
          icon: "fas fa-receipt text-blue",
          expectedRoles: [ROLES.ROLE_READ_REPORT, ROLES.ROLE_WRITE_REPORT],
        },
      },
    ],
  },
  {
    path: "recruiting",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "RECRUITING",
      class: "",
      icon: "fas fa-user-tie text-blue",
      expectedRoles: [ROLES.ROLE_WRITE_RICHIESTE, ROLES.ROLE_READ_RICHIESTE],
    },
    children: [
      {
        path: "richieste",
        component: RichiesteComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "RICHIESTE",
          class: "",
          icon: "ni ni-books text-blue",
          expectedRoles: [
            ROLES.ROLE_WRITE_RICHIESTE,
            ROLES.ROLE_READ_RICHIESTE,
          ],
        },
      },
      {
        path: "archivioCandidati",
        component: ArchivioCandidatiComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "ARCHIVIO_CANDIDATI",
          class: "",
          icon: "fas fa-users text-blue",
          expectedRoles: [],
        },
      },
      {
        path: "richiesteInLavorazione",
        component: RichiesteInLavorazioneComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "RICHIESTE_IN_LAVORAZIONE",
          class: "",
          icon: "fas fa-spinner text-blue",
          expectedRoles: [],
        },
      },
      {
        path: "skills",
        component: SkillsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          title: "SKILLS",
          class: "",
          icon: "fas fa-graduation-cap text-blue",
          expectedRoles: [],
        },
      },
    ],
  },
  {
    path: "lookups",
    component: LookupsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      title: "LOOKUPS",
      class: "active",
      icon: "ni ni-bullet-list-67 text-blue",
      expectedRoles: [ROLES.ROLE_READ_LOOKUPS, ROLES.ROLE_WRITE_LOOKUPS],
    },
  },
];

export const AdminLayoutRoutes: Routes = staticRoutes;
