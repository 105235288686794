
export class CrudNetUpdateRequest<T> {
    entity?: T;
    entities?: T[];
}

export class CrudNetPagedRequest {
    pageNum: number;
    pageSize: number;
    hash?: string;
    order: string[]
}


export class CrudNetExecRequest extends CrudNetPagedRequest {
  filter?: CrudNetFilterExpression;
  par: any;
}


export class CrudNetUpdateFieldRequest<T> extends CrudNetUpdateRequest<T> {
    filter: CrudNetFilterExpression;
}

export class CrudNetSearchRequest<T> extends CrudNetUpdateRequest<T> {
    pageNum: number;
    pageSize: number;
    filter?: CrudNetFilterExpression;
    includes?: string[];
    order?: string[];
}





class FilterValue {
    propertyType?:string;
    value:any;
}

export class CrudNetFilterExpression {
    expression: string;
    expressionValues: FilterValue[];
}
