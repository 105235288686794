import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { RealtimeRepo } from 'src/app/repos/RealTimeRepo';
import { ModalService, TaalUtilsService } from 'utils';
import { NotificationCenterComponent } from './notification-center.component';
import { NotificationModel } from './notification.model';

@Injectable({
  providedIn:'root'
})
export class NotificationCenterService {

  initialized=false;
  isMobile = navigator.userAgent.match(
    /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
  );

  onNotificationReceived =  new Subject<any>();
  onSentMessageReaded =  new Subject<any>();
  onReceivedMessageReaded = new Subject<any>();
  sendAdminNotification= (messageText:string,selectedImpiegato:number[])=> this.realTimeService.emit('sendNotification',{TESTO:messageText,ARRAYDESTINATARI:selectedImpiegato.join(',')});
  sendNotificationReaded = (id:number) => this.realTimeService.emit("readNotification",{IDLOGINVIOMESSAGGIO :id});
  readSentMessage = (page:number,stato:string) => this.realTimeService.emit("readNotificationSent",{PAGE :page,STATO:stato});
  readReceivedMessage = (page:number,stato:string) => this.realTimeService.emit("readNotificationReceived",{PAGE :page,STATO:stato});

  onConnectionChange= new Subject<boolean>();
  connected=false;

  preMobile:Function=null;
  preDesktop:Function=null;
  constructor(private utilService:TaalUtilsService, private realTimeService: RealtimeRepo, private modalService:ModalService ) { 
    
  }


  initDesktop(){
    if(this.preDesktop)
      this.preDesktop();
    setTimeout(()=>{
      const elementright=document.querySelector('#notification-center .right-sidebar')
      this.preDesktop=this.utilService.appendComponentToElement(NotificationCenterComponent,elementright as HTMLElement,{});
    },500);
    
  }


  disconnect(){
    this.realTimeService.tryDisconnect();
  }

  init(){

      this.realTimeService.onConnectionChange.subscribe((connected)=>{
        this.onConnectionChange.next(connected);
        this.connected=connected;
      })
      
      this.realTimeService.tryConnect();

      this.realTimeService.on('sendNotificationSuccess').subscribe((p)=>{
        this.modalService.showSuccess(p);
      });
      this.realTimeService.on('sendNotificationError').subscribe((p)=>{
        this.modalService.showError(p);
      });
      this.realTimeService.on('messageListSent').subscribe((ps)=>{
        if(ps && ps[0]){
          this.onSentMessageReaded.next(ps[0].map(p=>({
            id:p.IDLOGINVIOMESSAGGIO ,
            text:p.TESTO,
            user:'p',
            time:moment(p.DATASTATOMESSAGGIO).format("DD/MM/YYYY HH:mm")
          })));
        }else{
          this.onSentMessageReaded.next(null);
        }
      });
      this.realTimeService.on('messageListReceived').subscribe((ps)=>{
        if(ps && ps[0]){
          this.onReceivedMessageReaded.next(ps[0].map(p=>({
            id:p.IDLOGINVIOMESSAGGIO ,
            text:p.TESTO,
            user:'',
            time:moment(p.DATASTATOMESSAGGIO).format("DD/MM/YYYY HH:mm")
          })));
        }else{
          this.onReceivedMessageReaded.next(null);
        }
      });

      this.realTimeService.on('notify').subscribe((p)=>{
        const pObj:NotificationModel={
          text:p[0],
          time:moment(p[1],'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm'),
          user:p[2],
          id:p[3]
        };
        this.onNotificationReceived.next(pObj);

        if(Notification.permission=='granted'){
          new Notification(pObj.time + " - Nuovo messaggio da:"+pObj.user,{
            body:pObj.text,
            icon: "/assets/img/taal/logo.png"
          })
        }

      });

  }

  

}
