import {Component, OnInit} from '@angular/core';
import {TitleBreadcrumbService} from './services/title-breadcrumb.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-title-breadcrumb',
  templateUrl: './title-breadcrumb.component.html',
  styleUrls: ['./title-breadcrumb.component.css']
})
export class TitleBreadcrumbComponent implements OnInit {

  title = '';
  visible = true;
  breadcrumb = [];

  constructor(private titleBreadcrumbService: TitleBreadcrumbService, private router: Router) {
    this.titleBreadcrumbService.titleChange.subscribe((value: string) => {
      this.title = value;
    });
    this.titleBreadcrumbService.titleVisibility.subscribe((value: boolean) => {
      this.visible = value;
    });
    this.titleBreadcrumbService.breadcrumbChange.subscribe((value: any) => {
      this.breadcrumb = value.slice(0, value.length - 1);
    });
  }

  ngOnInit() {
  }

}
