import { Component, OnInit } from '@angular/core';
import { NotificationCenterService } from 'src/app/modules/notification-center/notification-center.service';
import { NotificationModel } from 'src/app/modules/notification-center/notification.model';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  notifications:NotificationModel[]=[];
  page=0;
  constructor(private notificationService: NotificationCenterService) { }

  ngOnInit() {
    this.notificationService.onReceivedMessageReaded.subscribe(p=>{
      if(p){
        this.notifications=p.concat(this.notifications);
        if(p.length){
          this.readMessageReceived();
        }
      }
    });
    if(this.notificationService.connected){
      this.readMessageReceived();
    }else{
      this.notificationService.onConnectionChange.subscribe(this.onConnected.bind(this));
    }
    this.notificationService.onNotificationReceived.subscribe(notidication=>{
      this.notifications.push(notidication);
    });
  }

  onConnected(connection){
    if(connection){
      this.readMessageReceived();
    }
  }

  readMessage(id:number){
    this.notificationService.sendNotificationReaded(id);
    this.notifications= this.notifications.filter(n=>n.id!==id);
  }

  readMessageReceived(){
    this.page++;
    this.notificationService.readReceivedMessage(this.page,"N");
  }

}
