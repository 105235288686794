import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { BaseRepo } from "../../repos/BaseRepo";

@Injectable({
  providedIn: "root",
})
export class ReportService extends BaseRepo<any> {
  getTable() {
    return "";
  }

  getFunctions() {
    return of([
      {
        descrizione: "Dettaglio impiegato",
        value: "fn_GetDatiImpiegato",
      },
      {
        descrizione: "Lista impiegati",
        value: "fn_GetListaImpiegati",
      },
      {
        descrizione: "export Sal",
        value: "fn_ExportSAL",
      },
      {
        descrizione: "Export contratti",
        value: "fn_ExportContratti",
      },
      {
        descrizione: "Costi personale",
        value: "fn_ExportCostiPersonale",
      },
      {
        descrizione: "Commesse",
        value: "fn_ExportCommesse",
      },
      {
        descrizione: "Costi struttura",
        value: "fn_ExportCostiStruttura",
      },
      {
        descrizione: "Costi commessa",
        value: "fn_ExportCostiCommesse",
      },
      {
        descrizione: "Personale",
        value: "fn_ExportPersonale",
      },
      {
        descrizione: "Fatturazione Sal",
        value: "fn_ExportFatturazioneSal",
      },
      {
        descrizione: "Rapporti terminati",
        value: "fn_ExportRapportiTerminati",
      },
    ]);
  }
}
