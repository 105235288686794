import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImpiegatoService} from '../impiegato.service';
import {
  TableServerActionIntent,
  TableServerColumn, TableServerColumnRender, TableServerColumnType,
  TableServerConfig, TableServerFilter,
  TableServerFilterItem,
  TableServerFilterType,
  TableServerSearch
} from 'utils';
import {Observable, of} from 'rxjs';
import {transformFiltersToServer, transformOrdersToServer} from 'crudnet-amgular';
import {map, tap} from 'rxjs/operators';
import {CustomDateRendererComponent} from '../../../components/custom-date-renderer/custom-date-renderer.component';
import {PROFILES} from '../../../constants';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-impiegato-picker',
  templateUrl: './impiegato-picker.component.html',
  styleUrls: ['./impiegato-picker.component.css']
})
export class ImpiegatoPickerComponent implements OnInit {

  @Input() required?: boolean;

  @Input() label: string;

  @Input() selectedImpiegato: any;

  @Input() displayValueFunction?: (elm: any) => string;

  @Input() disabled?: boolean;

  @Input() multiple?:boolean;

  @Input() profiloImpiegatiFilter?: PROFILES[];

  @Input() idModal?: string;

  @Input() defaultImpiegatiFilters?: TableServerFilter[];

  @Input() cssClass?:string;

  @Output() selection: EventEmitter<any> = new EventEmitter<any>();

  @Output() close:EventEmitter<any> = new EventEmitter<any>();

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  constructor(public service: ImpiegatoService) {
    if (isNullOrUndefined(this.defaultImpiegatiFilters) || !this.defaultImpiegatiFilters.length) {
      this.defaultImpiegatiFilters = [];
    }

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = 'Nome';
    column1.data = 'nome';
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = 'Cognome';
    column2.data = 'cognome';
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = 'Email Aziendale';
    column3.data = 'emailAziendale';
    this.tableConfig.columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = 'Email Privata';
    column4.data = 'emailPrivata';
    this.tableConfig.columns.push(column4);

    const column5 = new TableServerColumn();
    column5.label = 'Data Nascita';
    column5.data = 'dataNascita';
    column5.type = TableServerColumnType.COLUMN_CUSTOM;
    column5.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.datanascita
      };
      return render;
    };
    this.tableConfig.columns.push(column5);

    const filter1 = new TableServerFilterItem();
    filter1.label = 'Nome';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = 'NOME';
    this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = 'Cognome';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = 'COGNOME';
    this.tableFilters.push(filter2);
  }

  ngOnInit() {}

  onSelect(ev) {
    this.selection.emit(ev);
  }

  getData(params: TableServerSearch) {
    const orders = transformOrdersToServer(params.sort);
    params.filters = [...params.filters, ...this.defaultImpiegatiFilters];
    const filters = transformFiltersToServer(params.filters);
    this.service.getImpiegatiByProfili(
      { pageNum: params.currentPage - 1, pageSize: params.itemsPerPage, order: orders, filter: filters},
      this.profiloImpiegatiFilter
    )
      .pipe(
        tap(res => {
          this.tableConfig.totalItems = res.rowCount;
        }),
        map(res => res.result)
      ).subscribe(res => {
      this.tableRow = of(res);
    });
  }

  impiegatoDisplayValue = (imp) => {
    if (imp) {
      return imp.nome + ' ' + imp.cognome;
    }
  }


  onClose(){
    this.close.emit();
  }

}
