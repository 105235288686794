import { Component, NgZone, OnInit } from '@angular/core';
import { ModalService } from 'utils';
import { NotificationCenterService } from './notification-center.service';
import { NotificationModel } from './notification.model';
import * as moment from 'moment';
import { TipoMessaggioService } from '../tipo-messaggio/tipo-messaggio.service';
import { TipoMessaggio } from '../tipo-messaggio/tipo-messaggio.model';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.css'],
})
export class NotificationCenterComponent implements OnInit {

  logs:NotificationModel[]=new Array<NotificationModel>();
  selectedImpiegato;
  modalId='modal-notification-impiegato-'+new Date().getTime();
  textAreaId='cn-field-long-'+new Date().getTime();
  messageText:string;

  selectedTM:TipoMessaggio;
  tipiMessaggio:TipoMessaggio[]=[];

  loading=false;
  page=0;
  hasResult=true;
  showPickerImpiegato=false;

  constructor(private notificationService: NotificationCenterService, public modalService:ModalService,private tipoMessaggioService:TipoMessaggioService) {
    
  }

  ngOnInit(): void {
    this.notificationService.onSentMessageReaded.subscribe(p=>{
      this.loading=false;
      if(p){
        if(!p.length){
          this.hasResult=false;
        }else{
           this.logs=p.concat(this.logs);
        }
      }
    });
    if(this.notificationService.connected){
      this.readMessageSent();
    }else{
      this.notificationService.onConnectionChange.subscribe(this.onConnected.bind(this));
    }

    this.tipoMessaggioService.search({pageNum:1,pageSize:-1}).subscribe(res=>{
      this.tipiMessaggio=res.result;
    });

  }

  onConnected(connection){
    if(connection){
      this.readMessageSent();
    }
  }

  readMessageSent(){
    this.page++;
    this.loading=true;
    this.notificationService.readSentMessage(this.page,null);
  }

  onImpiegatoChange(selectedImpiegati){
    this.selectedImpiegato = selectedImpiegati;
  }

  openModal(){
    this.selectedImpiegato=null;
    this.showPickerImpiegato=true;
    this.modalService.open(this.modalId);
  }

  
  closeModal(){
    this.modalService.close(this.modalId);
    this.showPickerImpiegato=false;
  }

  impiegatoDisplayValue = (imp) => { //
    if (imp) {
      return imp.nome + ' ' + imp.cognome;
    }
  }

  send(){
    this.modalService.close(this.modalId);
    this.logs.push({
      id:null,
      text:this.messageText,
      time: moment().format("DD/MM/YYYY HH:mm"),
      user:"Io"
    });
    this.notificationService.sendAdminNotification(this.messageText,this.selectedImpiegato.data.map(i=>i.idImpiegato));
  }

  changedSelectedTM(){
    if(this.selectedTM){
      this.messageText=this.selectedTM.testo;
    }
  }

}
