import { Injectable } from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {TipoContrattoLavoro} from './tipologia-contratto.model';

@Injectable({
  providedIn: 'root'
})
export class TipologiaContrattoService extends BaseRepo<TipoContrattoLavoro> {

  getTable() {
    return 'TipoContrattoLavoro';
  }
}
