import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import {LoginService} from '../../pages/login/login.service';
import {SocialUser} from 'angularx-social-login';
import { AdminLayoutService } from 'src/app/layouts/admin-layout/admin-layout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user: SocialUser;
  public focus;
  public listTitles: any[];
  public location: Location;

  chatEnabled=environment.CHAT_ENABLED;
  isMobile=false;

  constructor(location: Location,  private element: ElementRef, private router: Router, private loginService: LoginService,private adminLayoutService:AdminLayoutService) {
    this.location = location;
  }

  ngOnInit() {
    // this.listTitles = this.routes.filter(listTitle => listTitle);
    this.loginService.getAuthState().subscribe((user) => {
      this.user = user;
    });

    this.adminLayoutService.windowsResizedMobile.subscribe(isMobile=>this.isMobile=isMobile);
    
    
  }
  // getTitle() {
  //   let titlee = this.location.prepareExternalUrl(this.location.path());
  //   if (titlee.charAt(0) === '#') {
  //       titlee = titlee.slice( 1 );
  //   }
  //
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let item = 0; item < this.listTitles.length; item++) {
  //       if (this.listTitles[item].path === titlee) {
  //           return this.listTitles[item].title;
  //       }
  //   }
  //   return 'Dashboard';
  // }
  logout() {
    return this.loginService.logoutTaalWithGoogle();
  }
}
