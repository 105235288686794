import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-custom-renderer',
  templateUrl: './custom-date-renderer.component.html',
  styleUrls: ['./custom-date-renderer.component.css']
})
export class CustomDateRendererComponent implements OnInit {
  @Input() date?: any;
  @Input() format?: any;
  dateToRender: string;
  formatToRender: string;
  constructor() { }

  ngOnInit() {
    this.formatToRender = this.format;
    this.dateToRender = this.date ?
      moment(this.date).format(this.formatToRender ? this.formatToRender : 'DD/MM/YYYY')
      :
      '-';
  }

}
