import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {

  @Input() base64?:string;


  urlSafe=null;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if(this.base64 && this.base64.length){
      this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64,"+this.base64);
    }
  }

}
