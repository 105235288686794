import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEdit, faEye, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CrudNetFilterExpression, CrudNetViewMode } from 'projects/crudnet-amgular/src/public-api';
import { DispositivoService } from '../dispositivo.service';
import * as moment from 'moment';
import { ImpiegatoService } from '../../impiegato/impiegato.service';
import { Observable } from 'rxjs';
import { ModalService, PdfService } from 'utils';

@Component({
    selector: 'app-gestione-magazzino-crud',
    templateUrl: './gestione-magazzino-crud.component.html',
    styleUrls: ['./gestione-magazzino-crud.component.css']
  })
  export class GestioneMagazzinoCrudComponent implements OnInit {

    /**
     * Crudnet repo for table or view
     */
    @Input() service: DispositivoService;
    /**
     * Viewmode of form ( INSERT EDIT DELETE )
     */
    @Input() viewMode: CrudNetViewMode;
    /**
     * Object external for configuration in column definition
     */
    @Input() externalFields?: any;
    /**
     * id Value of current record null on viewMode.INSERT
     */
    @Input() idCurrent?: any;
    /**
     * filters to be set by loading list fields in the crud
     */
    @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
    /**
     * chiavi di lettura per campi di tipo lista di default 'descrizione'
     */
    @Input() refFieldMap?: Record<string, string>;
    /**
     * default values ​​for crud
     */
    @Input() defaultValues?: Object;
    /**
     * custom submit method
     */
    @Input() submit?: Function;
    /**
     * the current row of the table useful for custom components
     */
    @Input() row?: any;

    /**
     * event fire on success form submitting
     */
    @Output() success: EventEmitter<string> = new EventEmitter<string>();
    /**
     * event fire on error form submitting
     */
    @Output() error: EventEmitter<string> = new EventEmitter<string>();
    /**
     * event fire on closeModal
     */
    @Output() close: EventEmitter<any> = new EventEmitter<any>();



    viewModes = CrudNetViewMode;

    currentIcon = faPlus;
    closeIcon = faTimes;


    selectedRisorse = null;
    selectedImpiegato;
    defaultCrudValues;
    customSubmitParams: any[] = [];
    selectedDate = new Date();
    reader = new FileReader();
    imageError: string;
    isImageSaved: boolean;
    cardImageBase64: string;
    allegato: string;
    documentoRicevuta: any;
    assignAll = false;

    fatturaPrev = null;
    signedPdf = null;

    assegnazioneAperta = null;
    previewId="modal-prev-doc-ass";
    doc=null;
    noPdf=false;

    constructor(private dispositivoService: DispositivoService, private pdfService: PdfService, private modalService: ModalService) { }

    ngOnInit() {
      this.currentIcon = this.getIcon();
      if (this.row && this.row.AssegnazioneDispositivo_List.length) {
        this.assegnazioneAperta = this.row.AssegnazioneDispositivo_List.find(ass => !ass.dataFineAssegnazione);
        if (this.assegnazioneAperta) {
          this.selectedImpiegato = {
            idImpiegato: this.assegnazioneAperta.Impiegato.idImpiegato,
            nome: this.assegnazioneAperta.Impiegato.Anagrafica.nome,
            cognome: this.assegnazioneAperta.Impiegato.Anagrafica.cognome,
          };
          this.selectedDate = new Date(this.assegnazioneAperta.dataInizioAssegnazione);
        }

      }

    }

    getIcon() {
      switch (this.viewMode) {
        case CrudNetViewMode.DELETE:
          return faTrash;
        case CrudNetViewMode.EDIT:
          return faEdit;
        case CrudNetViewMode.VIEW:
          return faEye;
        default:
          return faPlus;
      }
    }

    closeModal() {
      this.close.emit();
    }

    outputSuccess(ev) {
      this.success.emit(ev);
    }

    outputError(ev) {
      this.error.emit(ev);
    }

    onImpiegatoChange(ev) {
      this.selectedImpiegato = ev;
      this.customSubmitParams = [ev.idImpiegato];
    }

    associaImpiegato() {
      const document = this.getCorrectDocument();
      this.service.assegnaDispositivo(this.row.idDispositivo , this.selectedImpiegato.idImpiegato, moment(this.selectedDate).format('YYYYMMDD'), document)
      .subscribe(res => {
        this.closeModal();
        this.modalService.showSuccess('OK');
      });

    }

    restituisciDispositivo() {
      const document = this.getCorrectDocument();
      this.service.restituisciDispositivo(this.row.idDispositivo , this.selectedImpiegato.idImpiegato, moment().format('YYYYMMDD'), document)
      .subscribe(res => {
        this.closeModal();
        this.modalService.showSuccess('OK');
      });
    }


    getCorrectDocument() {
      if (this.signedPdf) {
        return this.signedPdf;
      } else if (this.documentoRicevuta) {
        return this.documentoRicevuta[0].data.split('base64,')[1];
      }
    }

    customSubmit = (fields, viewMode) => {

      if (fields) {
        const p = {
          fkIdTipoDispositivo: fields.fkIdTipoDispositivo,
          numeroFattura: fields.numeroFattura,
          idDispositivo: fields.idDispositivo,
          modello: fields.modello,
          serialNumber: fields.serialNumber,
          fattura: fields.allegato && fields.allegato.length ? fields.allegato[0].data.split('base64,')[1] : null
        };
        let obj = new Observable<any>();
        if (this.viewMode === CrudNetViewMode.CREATE) {
          obj = this.service.insertDispositivo(p.fkIdTipoDispositivo, p.numeroFattura, p.fattura, p.modello, p.serialNumber);
        } else if (this.viewMode === CrudNetViewMode.EDIT) {
          obj = this.service.updateDispositivo(p.idDispositivo, p.fkIdTipoDispositivo, p.numeroFattura, p.fattura, p.modello, p.serialNumber);
        } else {
          obj = this.service.del({entity: p});
        }
        obj.subscribe(res => {
          if (res.error) {
            this.outputError(res.message);
          } else {
            this.outputSuccess('OK');
          }
          this.closeModal();
        });

      }
    }

    onSignReturn(sign) {
      const pdfDocGenerator = this.pdfService.createPdf(
        // TODO REPLACE WITH CORRECT PDF CONFIG FOR RETURN
        {
        content: [
          {
            text: [
              'Nome: ',
              { text: 'Calogero', decoration: 'underline' },
              ' Cognome : ',
              { text: 'Miraglia', decoration: 'underline' },
              '.'
            ]
          },
          { image: 'data:image/png;base64,' + sign, width: 300, height: 100 },
        ]
      });
      pdfDocGenerator.getBase64((data) => {
        this.signedPdf = data;
      });
    }

    onSign(sign) {
      const pdfDocGenerator = this.pdfService.createPdf(
        // TODO REPLACE WITH CORRECT PDF CONFIG
        {
        content: [
          {
            text: [
              'Nome: ',
              { text: 'Calogero', decoration: 'underline' },
              ' Cognome : ',
              { text: 'Miraglia', decoration: 'underline' },
              '.'
            ]
          },
          { image: 'data:image/png;base64,' + sign, width: 300, height: 100 },
        ]
      });
      pdfDocGenerator.getBase64((data) => {
        this.signedPdf = data;
      });
    }
    impiegatoDisplayValue = (imp) => {
      if (imp) {
        return imp.nome + ' ' + imp.cognome;
      }
    }


    closePreview(){
      this.modalService.close(this.previewId);
      this.doc=null;
      this.noPdf=false;
    }

    openDoc(id,direction){
      this.dispositivoService.getDocumentoAssegnazione(id,direction).subscribe((res)=>{
        if(res.output.return_value && res.output.return_value.length){
          this.doc=res.output.return_value;
        }else{
          this.noPdf=true;
        }
        this.modalService.open(this.previewId);
        
      })
      
    }

  }
