import {Component, OnInit} from '@angular/core';
import {SocialUser} from 'angularx-social-login';
import {LoginService} from '../login/login.service';
import {AuthService} from '../../auth/auth.service';
import {ImpiegatoService} from '../../modules/impiegato/impiegato.service';
import * as moment from 'moment';
import {CrudNetViewMode} from 'crudnet-amgular';
import {ImpiegatoForDati, ImpiegatoModel} from '../../modules/impiegato/impiegato.model';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  user: SocialUser;
  taalUser: ImpiegatoForDati;

  constructor(private loginService: LoginService, private authService: AuthService, public impiegatoService: ImpiegatoService) {
  }

  viewMode = CrudNetViewMode.EDIT;
  userId = null;

  disabledFields = ['nome',
    'cognome',
    'fkIdTipoSesso',
    'fkIdProvinciaNascita',
    'fkIdComuneNascita',
    'emailAziendale',
    'matricola',
    'fkIdProvinciaResidenza',
    'fkIdComuneResidenza',
    'indirizzoResidenza',
    'dataNascita',
    'capResidenza',
    'cellulare',
    'telefonoAbitazione',
    'telefonoLavoro',
    'emailPrivata',
    'iban',
    'codiceFiscale'
  ];

  ngOnInit() {
    this.userId = this.authService.getUserInfo().iduser;
    this.loginService.getAuthState().subscribe((user) => {
      this.user = user;
      this.getLoggedUserData();
    });
  }

  getLoggedUserData() {
    this.impiegatoService.find(this.authService.getUserInfo().iduser).subscribe(res => {
      this.taalUser = res.result;
    });
  }

  success() {
    this.getLoggedUserData();
  }


  loadedCrudnet() {
    document.querySelector('#form-ui #emailAziendale').setAttribute('readonly', 'readonly');
    document.querySelector('#form-ui #nome').setAttribute('readonly', 'readonly');
    document.querySelector('#form-ui #cognome').setAttribute('readonly', 'readonly');
    document.querySelector('#form-ui #fkIdProvinciaNascita').setAttribute('readonly', 'readonly');
    document.querySelector('#form-ui #fkIdComuneNascita').setAttribute('readonly', 'readonly');
    document.querySelector('#form-ui #fkIdTipoSesso').setAttribute('disabled', 'disabled');
    document.querySelector('#form-ui #dataNascita').setAttribute('disabled', 'disabled');
    document.querySelector('#form-ui #codiceFiscale').setAttribute('readonly', 'readonly');
  }

  getAge() {
    return moment().diff(this.taalUser.dataNascita, 'years');
  }

}
