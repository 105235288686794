import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RichiesteInLavorazioneLogViewService {

  constructor() { }

  getDataMock(){
    return of([{
      dataAzione:"10/10/2021",
      tipoAzione:"tipo azione bla",
      esito: "positivo",
      note: "una nota di esempio"
    },
    {
      dataAzione:"11/10/2021",
      tipoAzione:"tipo azione 2 bla",
      esito: "negativo",
      note: "una nota di esempio 2"
    }])
  }
}
