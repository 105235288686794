import { Injectable } from "@angular/core";
import { BaseRepo } from "../../repos/BaseRepo";
import { TrattamentoEconomicoModel } from "./trattamento-economico.model";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import {
  CrudNetBaseResponse,
  CrudNetExecRequest,
  CrudNetResultResponse,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
} from "crudnet-amgular";

@Injectable({
  providedIn: "root",
})
export class TrattamentoEconomicoService extends BaseRepo<any> {
  idImpiegato: number;

  setIdImpiegato(idImpiegato: number) {
    this.idImpiegato = idImpiegato;
  }

  getTable() {
    return "TrattamentoEconomico";
  }

  mapFromServer(entity: any) {
    return {
      fkIdTipoContrattoLavoro: entity.FKIDTIPOCONTRATTOLAVORO,
      fkIdSocieta: entity.FKIDSOCIETA,
      fkIdTipoAmbitoLavorativo: entity.FKIDTIPOAMBITOLAVORATIVO,
      dataInizioTrattamento: entity.DATAINIZIOTRATTAMENTO,
      scadenzaEffettiva: entity.SCADENZAEFFETTIVA,
      dataRecesso: entity.DATARECESSO,
      motivazioniCessazione: entity.MOTIVAZIONICESSAZIONE,
      trasformazioni: entity.TRASFORMAZIONI,
      ccnl: entity.CCNL,
      ral: entity.RAL,
      trasferta: entity.TRASFERTA,
      buoniPasto: entity.BUONIPASTO,
      nettoMese: entity.NETTOMESE,
      costoAnnuo: entity.COSTOANNUO,
      costoGiornaliero: entity.COSTOGIORNALIERO,
      tariffaVendita: entity.TARIFFAVENDITA,
      note: entity.NOTE,
      dataAssunzione: entity.DATAASSUNZIONE,
    };
  }

  find(
    id,
    customTable?: string
  ): Observable<CrudNetResultResponse<TrattamentoEconomicoModel>> {
    if (this.idImpiegato && !customTable) {
      let params = {
        pageNum: 0,
        pageSize: -1,
        order: [],
        par: { IDIMPIEGATO: this.idImpiegato },
      };
      return this.exec("fn_GetTrattamentoImpiegato", params).pipe(
        map((res) => {
          let ret = new CrudNetBaseResponse<any>();

          ret.result = this.mapFromServer(res.result[0]);
          return ret;
        })
      );
    } else {
      return super.find(id, customTable);
    }
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map((res) => {
        res.result.columns = res.result.columns.filter(
          (c) => c.name !== "fkIdImpiegato"
        );
        let indexOfDataRecesso = 0;
        res.result.columns.forEach((col, index) => {
          if (col.name === "dataRecesso") {
            indexOfDataRecesso = index - 1;
          }
        });
        res.result.columns.splice(indexOfDataRecesso, 0, {
          maxLen: null,
          name: "dataAssunzione",
          precision: null,
          required: false,
          scale: null,
          type: "System.DateTime",
        });
        return res;
      })
    );
  }

  getSocietaList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "Societa");
  }

  add(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<TrattamentoEconomicoModel>> {
    return this.sp_SaveTrattamentoEconomico(params.entity);
  }

  update(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<TrattamentoEconomicoModel>> {
    return this.sp_SaveTrattamentoEconomico(params.entity);
  }

  sp_SaveTrattamentoEconomico(entity) {
    const execParams: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: this.mapToServer(entity),
    };
    return super.exec("sp_SaveTrattamentoEconomico", execParams);
  }

  mapToServer(entity: TrattamentoEconomicoModel) {
    return {
      IDTRATTAMENTOECONOMICO: entity.idTrattamentoEconomico,
      IDTIPOCONTRATTOLAVORO: entity.fkIdTipoContrattoLavoro,
      IDSOCIETA: entity.fkIdSocieta,
      IDIMPIEGATO: entity.fkIdImpiegato,
      IDTIPOAMBITOLAVORATIVO: entity.fkIdTipoAmbitoLavorativo,
      DATAINIZIOTRATTAMENTO: entity.dataInizioTrattamento,
      SCADENZAEFFETTIVA: entity.scadenzaEffettiva,
      DATARECESSO: entity.dataRecesso,
      MOTIVAZIONICESSAZIONE: entity.motivazioniCessazione,
      TRASFORMAZIONI: entity.trasformazioni,
      CCNL: entity.ccnl,
      RAL: entity.ral,
      TRASFERTA: entity.trasferta,
      BUONIPASTO: entity.buoniPasto,
      NETTOMESE: entity.nettoMese,
      COSTOANNUO: entity.costoAnnuo,
      COSTOGIORNALIERO: entity.costoGiornaliero,
      TARIFFAVENDITA: entity.tariffaVendita,
      NOTE: entity.note,
    };
  }
}
