import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (route.data) {
      const expectedRoles = route.data.expectedRoles;
      if (isArray(expectedRoles) && expectedRoles.length) {// caso in cui ci sono dei ruoli dichiarati
        const userLoggedRoles = this.auth.getRoles(); // prelevo i ruoli dell'utente loggato
        let finded = 0;
        expectedRoles.forEach(routeRole => {
          if (userLoggedRoles.find(userRole => userRole === routeRole)) {
            // nel caso in cui almeno uno dei ruoli richiesti è contenuto in quelli dell'utente loggato
            finded++;
          }
        });
        // restituisce true nel caso è stato trovato almeno uno dei ruoli richiesti altrimenti torna false con un'istruzione custom
        return finded > 0;
      }
    }

    return true; // caso in cui la route non dichiarato ruoli quindi si procede
  }
}
