import {Injectable} from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseRepo<any> {

  getCounterRapportiniNonConsolidati = (anno, mese) => {
    return this.exec('fn_DashboardRapportiniDaConsolidare', {
      par: {
        ANNO: anno,
        MESE: mese
      },
      order: [],
      filter: null,
      pageNum: 0,
      pageSize: -1
    });
  }

  getSalFatturatoCliente = (anno) => {
    return this.exec('fn_DashboardTotaleSALFatturatoPerCliente', {
      par: {
        ANNO: anno,
      },
      order: [],
      filter: null,
      pageNum: 0,
      pageSize: -1
    });
  }

  getConsulenzeInScadenza = () => {
    return this.exec('fn_DashboardConsulenzeInScadenza', {
      par: {},
      order: [],
      filter: null,
      pageNum: 0,
      pageSize: -1
    });
  }

  getCounterPersonaleSenzaCommesse = () => {
    return this.exec('fn_DashboardImpiegatiSenzaAssegnazioni', {
      par: {},
      order: [],
      filter: null,
      pageNum: 0,
      pageSize: -1
    });
  }

  getCounterTotaleCommesse = (anno, mese) => {
    const par = {
      par: {
        ANNO: anno,
      },
      order: [],
      filter: null,
      pageNum: 0,
      pageSize: -1
    };

    if (mese) {
      par.par['MESE'] = mese;
    }

    return this.exec('fn_DashboardTotaleCommessePerMese', par);
  }

  getCounterTotaleOfferte = (anno, mese) => {
    const par = {
      par: {
        ANNO: anno,
      },
      order: [],
      filter: null,
      pageNum: 0,
      pageSize: -1
    };

    if (mese) {
      par.par['MESE'] = mese;
    }

    return this.exec('fn_DashboardTotaleOffertePerMese', par);
  }

  getTable(): string {
    return '';
  }
}
