import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { GestioneFeriePermessiService } from './gestione-ferie-permessi.service';
import {
  ModalService,
  TableServerCheckSelection,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnSortDirection,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem, TableServerFilterMode,
  TableServerFilterType,
  TableServerSearch,
} from 'utils';
import { Observable, of } from 'rxjs';
import { TableColumnAcceptDenyComponent } from '../../components/table-column-accept-deny/table-column-accept-deny.component';
import * as moment from 'moment';
import { CustomDateRendererComponent } from '../../components/custom-date-renderer/custom-date-renderer.component';
import { PermessoModel } from '../permesso/permesso.model';
import {
  faBan,
  faCheck,
  faFilter,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  CrudNetExecRequest,
  CrudNetSearchRequest,
  transformFiltersToServer,
  transformOrdersToServer,
} from 'crudnet-amgular';
import { map, tap } from 'rxjs/operators';
import { TableServerComponent } from 'utils/lib/table-server/table-server.component';
import { CustomActionButtonComponent } from '../../components/custom-action-button/custom-action-button.component';
import { GestioneFeriePermessiExpandedComponent } from './gestione-ferie-permessi-expanded/gestione-ferie-permessi-expanded.component';
import { CODICI_RICHIESTE, MISURA_FERIE } from '../../constants';
import { DettaglioRichiesta } from '../permesso/dettaglioRichiesta.model';
import { CustomStringRendererComponent } from '../../components/custom-string-renderer/custom-string-renderer.component';

@Component({
  selector: 'app-gestione-ferie-permessi',
  templateUrl: './gestione-ferie-permessi.component.html',
  styleUrls: ['./gestione-ferie-permessi.component.css'],
})
export class GestioneFeriePermessiComponent implements OnInit {
  @ViewChild('tableServer', { static: false }) table: TableServerComponent;

  selectedTab = 0;

  tableConfigAccettazione: TableServerConfig;
  tableRowAccettazione: Observable<Object[]> = of([]);
  tableConfigStorico: TableServerConfig;
  tableRowStorico: Observable<Object[]> = of([]);

  tableFiltersAccettazione: TableServerFilterItem[] = [];
  tableFiltersStorico: TableServerFilterItem[] = [];

  showTableFilterAccettazione = false;
  showTableFilterStorico = false;
  showModal = false;

  filterModes = TableServerFilterMode;

  filterIcon = faFilter;
  disableIcon = faBan;

  currentIcon = faCheck;
  closeIcon = faTimes;

  typeAccept = 'ACCEPT';
  typeDeny = 'DENY';
  typeAnnulla = 'ANNULLA';

  selectedType: string;

  selectedRow: PermessoModel;
  selectedDettaglio: DettaglioRichiesta;

  idGestioneModal = 'gestione-ferie-permessi-modal';
  idGestioneModalMultiple = 'modal-multiple';

  currentParam: TableServerSearch;

  misuraOraria = MISURA_FERIE.ORARIO;

  getListOptionsParams: CrudNetSearchRequest<any> = {
    pageNum: 0,
    pageSize: -1,
  };

  selectedDataCheckbox: any[] = [];

  columnSelection: TableServerColumn = {
    label: '',
    sortable: false,
    sortDirection: TableServerColumnSortDirection.NO_SORT,
    hideSelectAll: false,
    data: 'selection',
    type: TableServerColumnType.COLUMN_CHECKBOX,
    onCheck: (selectedData: TableServerCheckSelection) => {
      this.selectedDataCheckbox = selectedData.data;
    },
  };

  onAnnulla = new EventEmitter();
  annullaIcon = faTimes;

  columnAnnulla: TableServerColumn = {
    label: 'ANNULLA',
    sortable: false,
    sortDirection: TableServerColumnSortDirection.NO_SORT,
    data: 'annulla',
    type: TableServerColumnType.COLUMN_CUSTOM,
    // hideMe: (row: PermessoModel, column) => {
    //   return row.dettaglioRichiesta[0].CODICESTATORICHIESTA === CODICI_RICHIESTE.ANNULLATA;
    // },
    render: (row: PermessoModel, column) => {
      const render = new TableServerColumnRender();
      if (
        row.dettaglioRichiesta[0].CODICESTATORICHIESTA ===
        CODICI_RICHIESTE.ANNULLATA
      ) {
        render.component = CustomStringRendererComponent;
        render.inputs = {
          stringToRender: 'Annullata',
        };
      } else {
        render.component = CustomActionButtonComponent;
        render.inputs = {
          row,
          buttonClass: 'btn-danger',
          actionIcon: this.annullaIcon,
        };
        render.outputs = {
          action: this.onAnnulla,
        };
      }
      return render;
    },
  };

  constructor(
    public service: GestioneFeriePermessiService,
    public modalService: ModalService
  ) {
    this.tableConfigAccettazione = new TableServerConfig();
    this.tableConfigStorico = new TableServerConfig();
    this.tableConfigAccettazione.itemsPerPage = 10;
    this.tableConfigAccettazione.columns = [];
    this.tableConfigStorico.itemsPerPage = 10;
    this.tableConfigStorico.columns = [];
  }

  ngOnInit() {
    this.onAnnulla.subscribe((row) => {
      this.onAnnullaRichiesta(row);
    });

    const onAcceptedValue = new EventEmitter();
    onAcceptedValue.subscribe((row) => {
      this.onAccept(row);
    });

    const onDeniedValue = new EventEmitter();
    onDeniedValue.subscribe((row) => {
      this.onDeny(row);
    });

    const onAcceptedValueDettaglio = new EventEmitter();
    onAcceptedValueDettaglio.subscribe((dettaglio) => {
      this.onAcceptDettaglio(dettaglio);
    });

    const onDeniedValueDettaglio = new EventEmitter();
    onDeniedValueDettaglio.subscribe((dettaglio) => {
      this.onDenyDettaglio(dettaglio);
    });

    const columnExpandable = new TableServerColumn();
    columnExpandable.label = 'Dettagli';
    columnExpandable.data = 'id',
    columnExpandable.type = TableServerColumnType.COLUMN_EXPANDABLE;
    columnExpandable.hideMe = (row: PermessoModel, column) => {
      return row.dettaglioRichiesta.length < 2;
    };
    columnExpandable.render = (row: PermessoModel, column) => {
      const render = new TableServerColumnRender();
      render.component = GestioneFeriePermessiExpandedComponent;
      render.inputs = {
        dettagliRichiesta: row.dettaglioRichiesta,
      };
      render.outputs = {
        onAccept: onAcceptedValueDettaglio,
        onDeny: onDeniedValueDettaglio,
      };
      return render;
    };

    const column1 = new TableServerColumn();
    column1.label = 'Nome';
    column1.data = 'nomeImpiegatoRichiedente';

    const column2 = new TableServerColumn();
    column2.label = 'Cognome';
    column2.data = 'cognomeImpiegatoRichiedente';

    const column3 = new TableServerColumn();
    column3.label = 'Tipo';
    column3.data = 'descTipoRichiesta';

    const column4 = new TableServerColumn();
    column4.label = 'Data inizio';
    column4.data = '';
    column4.sortable = false;
    column4.type = TableServerColumnType.COLUMN_CUSTOM;
    column4.render = (row: PermessoModel, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.dettaglioRichiesta[0].DATAINIZIO,
        format:
          row.misura !== this.misuraOraria ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm',
      };
      return render;
    };

    const column5 = new TableServerColumn();
    column5.label = 'Data fine';
    column5.data = '';
    column5.sortable = false;
    column5.type = TableServerColumnType.COLUMN_CUSTOM;
    column5.render = (row: PermessoModel, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date:
          row.dettaglioRichiesta[row.dettaglioRichiesta.length - 1].DATAFINE,
        format:
          row.misura !== this.misuraOraria ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm',
      };
      return render;
    };

    const acceptDenyColumn = new TableServerColumn();
    acceptDenyColumn.label = '';
    acceptDenyColumn.data = 'accept';
    acceptDenyColumn.sortable = false;
    acceptDenyColumn.type = TableServerColumnType.COLUMN_CUSTOM;
    acceptDenyColumn.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = TableColumnAcceptDenyComponent;
      render.inputs = {
        value: row,
      };
      render.outputs = {
        acceptedValue: onAcceptedValue,
        deniedValue: onDeniedValue,
      };
      return render;
    };
    this.tableConfigAccettazione.columns = [
      columnExpandable,
      column1,
      column2,
      column3,
      column4,
      column5,
      this.columnSelection,
      acceptDenyColumn,
    ];

    this.tableConfigStorico.columns = [
      column1,
      column2,
      column3,
      column4,
      column5,
      this.columnAnnulla,
    ];

    const filterNome = new TableServerFilterItem();
    filterNome.label = 'Nome';
    filterNome.type = TableServerFilterType.FILTER_TEXT;
    filterNome.data = 'NOMEIMPIEGATORICHIEDENTE';

    const filterCognome = new TableServerFilterItem();
    filterCognome.label = 'Cognome';
    filterCognome.type = TableServerFilterType.FILTER_TEXT;
    filterCognome.data = 'COGNOMEIMPIEGATORICHIEDENTE';

    const filterData = new TableServerFilterItem();
    filterData.label = 'Data';
    filterData.type = TableServerFilterType.FILTER_YEAR_MONTH;
    filterData.data = 'ANNOMESE';

    const filterStato = new TableServerFilterItem();
    filterStato.label = 'Stato';
    filterStato.type = TableServerFilterType.FILTER_LIST;
    filterStato.data = 'STATORICHIESTA';
    filterStato.options = [];

    this.tableFiltersAccettazione = [filterNome, filterCognome, filterData];
    this.tableFiltersStorico = [filterNome, filterCognome, filterData, filterStato];

    this.loadLookups();
  }

  loadLookups() {
    this.service
      .search(this.getListOptionsParams, 'StatoRichiestaFeriePermesso')
      .pipe(
        map((res) => {
          return res.result.filter((statoRichiesta) => statoRichiesta.codice !== 'C').map((statoRichiesta) => {
            return {
              label: statoRichiesta.descrizione,
              value: statoRichiesta.codice,
            };
          });
        })
      )
      .subscribe((res) => {
        this.tableFiltersStorico[3].options = res;
      });
  }

  /*
        tap(res => {
          this.tableConfigAccettazione.totalItems = res.rowCount;
        }),
 */

  getData(param: TableServerSearch, storico?: boolean) {
    this.currentParam = param;
    const orders = transformOrdersToServer(param.sort);
    let annomese = '';
    let codiceRichiesta = null;
    const newFilters = [];
    param.filters.forEach((elm) => {
      if (elm.data === 'STATORICHIESTA') {
        codiceRichiesta = elm.value;
      } else if (elm.data !== 'ANNOMESE') {
        newFilters.push(elm);
      } else {
        annomese = moment(elm.value).format('YYYYMM');
      }
    });
    const filters = transformFiltersToServer(newFilters);
    const request: CrudNetSearchRequest<any> = {
      order: orders,
      pageNum: param.currentPage,
      pageSize: param.itemsPerPage,
      filter: filters,
    };
    if (storico) {
      this.tableRowStorico = this.service
        .search(request, null, annomese, storico, codiceRichiesta)
        .pipe(
          tap((res) => {
            this.tableConfigStorico.totalItems = res.rowCount;
          }),
          map((res) => res.result)
        );
    } else {
      this.tableRowAccettazione = this.service
        .search(request, null, annomese, storico, codiceRichiesta)
        .pipe(
          tap((res) => {
            this.tableConfigAccettazione.totalItems = res.rowCount;
          }),
          map((res) => res.result)
        );
    }
  }

  onMultipleAccept() {
    this.selectedType = this.typeAccept;
    this.modalService.open(this.idGestioneModalMultiple);
  }

  onMultipleDeny() {
    this.selectedType = this.typeDeny;
    this.modalService.open(this.idGestioneModalMultiple);
  }

  onAccept(row) {
    this.selectedType = this.typeAccept;
    this.selectedRow = row;
    this.modalService.open(this.idGestioneModal);
  }

  onDeny(row) {
    this.selectedType = this.typeDeny;
    this.selectedRow = row;
    this.modalService.open(this.idGestioneModal);
  }

  onAnnullaRichiesta(row) {
    this.selectedType = this.typeAnnulla;
    this.selectedRow = row;
    this.modalService.open(this.idGestioneModal);
  }

  onAcceptDettaglio(dettaglio) {
    this.selectedType = this.typeAccept;
    this.selectedDettaglio = dettaglio;
    this.modalService.open(this.idGestioneModal);
  }

  onDenyDettaglio(dettaglio) {
    this.selectedType = this.typeDeny;
    this.selectedDettaglio = dettaglio;
    this.modalService.open(this.idGestioneModal);
  }

  closeModal() {
    this.modalService.close(this.idGestioneModal);
    this.selectedType = '';
    this.selectedRow = null;
    this.selectedDettaglio = null;
  }

  closeModalMultiple() {
    this.modalService.close(this.idGestioneModalMultiple);
    this.table.deselectAll(this.columnSelection);
    this.selectedType = '';
  }

  onSubmit() {
    const execParams: CrudNetExecRequest = {
      order: [],
      pageNum: 0,
      pageSize: -1,
      par: {
        ARRAYDETTAGLIORICHIESTA: this.selectedRow
          ? this.selectedRow.dettaglioRichiesta
              .map((det) => det.IDDETTAGLIO)
              .join(';')
          : this.selectedDettaglio.IDDETTAGLIO.toString(),
        CODICESTATORICHIESTA:
          this.selectedType === this.typeAccept
            ? CODICI_RICHIESTE.AUTORIZZATA
            : this.selectedType === this.typeDeny
            ? CODICI_RICHIESTE.RIFIUTATA
            : CODICI_RICHIESTE.ANNULLATA,
      },
    };
    this.service.acceptDenyFerie(execParams).subscribe(() => {
      this.getData(this.currentParam, true);
      this.getData(this.currentParam);
      this.closeModal();
      this.selectedDataCheckbox = [];
    });
  }

  onSubmitMultiple() {
    const execParams: CrudNetExecRequest = {
      order: [],
      pageNum: 0,
      pageSize: -1,
      par: {
        ARRAYDETTAGLIORICHIESTA: this.selectedDataCheckbox
          .map((elm) =>
            elm.dettaglioRichiesta.map((det) => det.IDDETTAGLIO).join(';')
          )
          .join(';'),
        CODICESTATORICHIESTA:
          this.selectedType === this.typeAccept
            ? CODICI_RICHIESTE.AUTORIZZATA
            : this.selectedType === this.typeDeny
            ? CODICI_RICHIESTE.RIFIUTATA
            : CODICI_RICHIESTE.ANNULLATA,
      },
    };
    this.service.acceptDenyFerie(execParams).subscribe(() => {
      this.getData(this.currentParam, true);
      this.getData(this.currentParam);
      this.closeModalMultiple();
      this.selectedDataCheckbox = [];
      this.table.deselectAll(this.columnSelection);
    });
  }

  selectAll() {
    this.tableRowAccettazione.subscribe(rows => {
      this.table.toogleAll(this.columnSelection, rows);
    })
  }

  setTab(value: number) {
    this.selectedTab = value;
  }
}
