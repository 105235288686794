import { Component, OnInit } from "@angular/core";
import { LookupsService } from "./lookups.service";
import {
  CrudNetSearchRequest,
  CrudNetViewMode,
  TypeaheadSearch,
} from "crudnet-amgular";
import {
  TableServerActionIntent,
  TableServerConfig,
  TableServerFilterItem,
} from "utils";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-lookups",
  templateUrl: "./lookups.component.html",
  styleUrls: ["./lookups.component.css"],
})
export class LookupsComponent implements OnInit {
  viewMode: CrudNetViewMode;
  loadForm = false;

  tableConfig: TableServerConfig = new TableServerConfig();
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  selectedLookup;
  show = false;

  lookupDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    /* this.lookupService.searchLookup = true; */
    if (typedValue && typedValue.length) {
      filter = {
        expression: "nomeTabella.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    return this.lookupService
      .getLookupByProfileId(this.authService.getUserInfo().iduser)
      .pipe(
        map((res) => {
          return res.result
            .map((lookup) => {
              return {
                idLookup: lookup.IDLOOKUP,
                nomeTabella: lookup.NOMETABELLA,
              };
            })
            .filter((lookup) => {
              if (typedValue && typedValue.length) {
                return lookup.NOMETABELLA.toLowerCase().startsWith(
                  typedValue.toLowerCase()
                );
              }
              return true;
            });
        })
      );
  };

  constructor(
    public lookupService: LookupsService,
    private authService: AuthService
  ) {
    this.tableConfig.itemsPerPage = 100;
    this.tableConfig.columns = [];
  }

  ngOnInit() {}

  handleSelection(ev) {
    if (ev && ev.nomeTabella) {
      this.show = false;
      this.lookupService.getLookupColumns(ev.nomeTabella).subscribe((res) => {
        // this.selectedLookup = ev;
        this.tableConfig.columns = res;
        // to force reload of cn-table component
        setTimeout(() => (this.show = true), 100);
      });
    }
  }
}
