export class CrudNetBaseResponse<T> {
    error: any;
    errorCode: any;
    message: string;
    result: T | T[] | any;
    hash: string;
    captcha: string;
};

export class CrudNetResultResponse<T> extends CrudNetBaseResponse<T> {
    rowCount?: number
}

export class CrudNetExecResponse<T> extends CrudNetResultResponse<T> {
    output: any
}
