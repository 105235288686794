import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.css']
})
export class RangeSliderComponent implements OnInit {
  @Input() min?: number;
  @Input() max?: number;
  // check if it is necessary
  @Input() current?: number;

  rangeMin: number;
  rangeMax: number;
  currentValue: number;
  constructor() { }

  ngOnInit() {
    this.rangeMin = this.min;
    this.rangeMax = this.max;
    this.currentValue = this.current;
  }

  valueChanged(value) {
    this.currentValue = value;
  }

}



/* import { Component, Input, OnInit, OnChanges, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.css']
})
export class RangeSliderComponent implements OnInit, AfterViewInit {
  public spanStyle = {};

  @Input() min?: number;
  @Input() max?: number;
  @Input() current?: number;

  rangeMin: number;
  rangeMax: number;
  currentValue: number;
  newValue: number;
  newPosition: number;
  color: string;

  constructor() { }

  ngOnInit() {
    this.rangeMin = 0;
    this.rangeMax = 100;
    this.currentValue = this.current;

  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit");
     this.newValue = Number((this.currentValue - this.rangeMin) * 100 / (this.rangeMax - this.rangeMin))
    this.newPosition = 10 - (this.newValue * 0.2);
    this.spanStyle = {
      left: `calc(${this.newValue}% + (${this.newPosition}px))`
    };
  }

valueChanged(value) {
  this.currentValue = value;
}

} */
