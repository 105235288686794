import {Injectable} from '@angular/core';
import {BaseRepo} from '../../../repos/BaseRepo';
import {CostoGeneraleModel} from '../costo-generale.model';
import {Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CrudNetResultResponse, CrudNetSearchRequest} from 'crudnet-amgular';
import {sumBy} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CostoGeneralePreventivoService extends BaseRepo<CostoGeneraleModel> {

  private notifyImportoCostoGenerale = new Subject<number>();

  // Observable object streams
  notifyImportoCostoGenerale$ = this.notifyImportoCostoGenerale.asObservable();

  getTable(): string {
    return 'CostoGenerale';
  }

  sendImportoCostoGenerale(ricavo: number) {
    this.notifyImportoCostoGenerale.next(ricavo);
  }

  // tslint:disable-next-line:max-line-length
  search(params: CrudNetSearchRequest<CostoGeneraleModel>, customTable?: any): Observable<CrudNetResultResponse<CostoGeneraleModel>> {
    if (!customTable) {
      params.includes = ['TipoCostoGenerale'];
      return super.search2(params).pipe(
        tap(res => {
          this.sendImportoCostoGenerale(sumBy(res.result, 'importo').toFixed(2));
        })
      );
    }
    return super.search(params, customTable);
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map(res => {
        res.result.columns = res.result.columns.filter(c =>
          c.name !== 'fkIdBudget' &&
          c.name !== 'anno' &&
          c.name !== 'mese'
        ).map(c => {
          if (c.name === 'fkIdTipoBudget' || c.name === 'flgPlaceholder') {
            c.hideMe = (parsedForm, orginalForm, externalData) => {
              return true;
            };
          }
          return c;
        });
        return res;
      })
    );
  }
}
