import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css']
})
export class SwitchComponent implements OnInit {
  @Input() check: boolean;
  @Output() checkChange = new EventEmitter<boolean>();

  checkIcon = faCheck;
  unCheckIcon = faTimes;

  constructor() { }

  ngOnInit() {
  }

  onChange(ev) {
    this.checkChange.emit(ev.target.checked);
  }

}
