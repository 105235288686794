import {Component, EventEmitter, OnInit} from '@angular/core';
import {
  CrudNetSearchRequest,
  CrudNetViewMode,
  transformFiltersToServer,
  transformOrdersToServer,
} from 'crudnet-amgular';
import * as moment from 'moment';
import {
  BsDatepickerConfig,
  BsDatepickerViewMode,
} from 'ngx-bootstrap/datepicker';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuthService} from 'src/app/auth/auth.service';
import {
  ExcelService,
  ModalService,
  TableServerActionIntent,
  TableServerColumnRender,
  TableServerColumnSortDirection,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterItem,
  TableServerFilterMode,
  TableServerFilterType,
  TableServerSearch,
} from 'utils';
import {ImportCostiPersonaleCrudComponent} from './import-costi-personale-crud/import-costi-personale-crud.component';
import {ImportCostiPersonaleService} from './import-costi-personale.service';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {TitleBreadcrumbService} from '../../components/title-breadcrumb/services/title-breadcrumb.service';

@Component({
  selector: 'app-import-costi-personale',
  templateUrl: './import-costi-personale.component.html',
  styleUrls: ['./import-costi-personale.component.scss'],
})
export class ImportCostiPersonaleComponent implements OnInit {
  customCRUD: any;
  viewMode: CrudNetViewMode;
  tableRowImport: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.DELETE, TableServerActionIntent.EDIT];

  idUser;

  idModal = 'createCostiPersonaleModal';
  idModalLogFile = 'logFileModal';
  showModalLogFile = false;
  modalOption;
  currentIcon = faSearch;
  closeIcon = faTimes;

  datePickerValue: Date = new Date();
  customCrud = ImportCostiPersonaleCrudComponent;

  currentParam: TableServerSearch;

  documentoUPD: any;
  documentoUPD2: any;

  showTableFilter = false;
  filterModes = TableServerFilterMode;
  currentFilters: TableServerSearch;
  tableFilters: TableServerFilterItem[] = [];
  defaultFilters: TableServerFilter[] = [];

  minMode: BsDatepickerViewMode = 'month';
  colorTheme = 'theme-orange';

  SocietaList: any[] = [];
  TipoAmbitoLavorativoList: any[] = [];

  bsConfig: Partial<BsDatepickerConfig> = {
    minMode: this.minMode,
    containerClass: this.colorTheme,
    dateInputFormat: 'MMMM YYYY',
  };

  tableConfig: TableServerConfig = {
    totalItems: 0,
    itemsPerPage: 10,

    columns: [
      {
        data: 'Impiegato.Anagrafica.nome',
        label: 'nome',
        sortDirection: TableServerColumnSortDirection.DESC,
        sortable: true,
        type: TableServerColumnType.COLUMN_TEXT,
      },
      {
        data: 'Impiegato.Anagrafica.cognome',
        label: 'cognome',
        sortDirection: TableServerColumnSortDirection.DESC,
        sortable: true,
        type: TableServerColumnType.COLUMN_TEXT,
      },
      {
        data: 'anno',
        label: 'anno',
        sortDirection: TableServerColumnSortDirection.DESC,
        sortable: true,
        type: TableServerColumnType.COLUMN_TEXT,
      },
      {
        data: 'mese',
        label: 'mese',
        sortDirection: TableServerColumnSortDirection.DESC,
        sortable: true,
        type: TableServerColumnType.COLUMN_TEXT,
      },
      {
        data: 'Impiegato.matricola',
        label: 'matricola',
        sortDirection: TableServerColumnSortDirection.DESC,
        sortable: true,
        type: TableServerColumnType.COLUMN_TEXT,
      },
      {
        data: 'importo',
        label: 'importo',
        sortDirection: TableServerColumnSortDirection.DESC,
        sortable: true,
        type: TableServerColumnType.COLUMN_NUMBER,
      },
      {
        data: 'TipoAmbitoLavorativo.descrizione',
        label: 'Tipo Ambito Lavorativo',
        sortDirection: TableServerColumnSortDirection.DESC,
        sortable: true,
        type: TableServerColumnType.COLUMN_TEXT,
      },
      {
        data: 'Societa.ragioneSociale',
        label: 'Societ\u00E0\'',
        sortDirection: TableServerColumnSortDirection.DESC,
        sortable: true,
        type: TableServerColumnType.COLUMN_TEXT,
      },
      {
        data: '',
        label: '',
        sortDirection: TableServerColumnSortDirection.NO_SORT,
        sortable: false,
        type: TableServerColumnType.COLUMN_ACTION,
        actions: this.tableActions,
        render: (row: any, column, viewMode, closeModal) => {
          const render = new TableServerColumnRender();
          render.component = this.customCrud;
          render.inputs = {
            viewMode: this.getViewModeFromTable(viewMode),
            idCurrent: row.id,
            row,
            service: this.importCostiPersonaleService,
            deleteFn: (rowToDelete) =>
              this.importCostiPersonaleService
                .deleteRec(rowToDelete.idCostoEffettivoDipendente)
                .subscribe((res) => {
                  this.getData(this.currentParam);
                }),
            updateFn: (par) =>
              this.importCostiPersonaleService
                .updateFn(par)
                .subscribe((res) => {
                  this.getData(this.currentParam);
                }),
          };

          const closeEmitter = new EventEmitter<any>();
          closeEmitter.subscribe(closeModal);

          const successEmitter = new EventEmitter<any>();
          successEmitter.subscribe(() =>
            this.modalService.showSuccess('SUCCESSED_OPERATION')
          );
          render.outputs = {
            close: closeEmitter,
            success: successEmitter,
          };
          return render;
        },
      },
    ],
  };

  anno: number = new Date().getFullYear();
  mese: number = new Date().getMonth() + 1;

  constructor(
    public importCostiPersonaleService: ImportCostiPersonaleService,
    public excelService: ExcelService,
    private modalService: ModalService,
    private authService: AuthService,
    private titleBreadcrumbService: TitleBreadcrumbService
  ) {
    const p = this.authService.getUserInfo();
    this.idUser = p.iduser;

    this.customCRUD = ImportCostiPersonaleCrudComponent;
    this.viewMode = CrudNetViewMode.EDIT;

    const filterNome = new TableServerFilterItem();
    filterNome.label = 'Nome';
    filterNome.type = TableServerFilterType.FILTER_TEXT;
    filterNome.data = 'Impiegato.Anagrafica.nome';

    const filterCognome = new TableServerFilterItem();
    filterCognome.label = 'Cognome';
    filterCognome.type = TableServerFilterType.FILTER_TEXT;
    filterCognome.data = 'Impiegato.Anagrafica.cognome';

    const filterAnno = new TableServerFilterItem();
    filterAnno.label = 'Anno';
    filterAnno.type = TableServerFilterType.FILTER_NUMBER;
    filterAnno.data = 'Anno';

    const filterMese = new TableServerFilterItem();
    filterMese.label = 'Mese';
    filterMese.type = TableServerFilterType.FILTER_MONTH;
    filterMese.data = 'Mese';

    const filterMatricola = new TableServerFilterItem();
    filterMatricola.label = 'Numero Matricola';
    filterMatricola.type = TableServerFilterType.FILTER_TEXT;
    filterMatricola.data = 'Impiegato.matricola';

    const filterSocieta = new TableServerFilterItem();
    filterSocieta.options = [];
    filterSocieta.label = 'Societ\u00E0';
    filterSocieta.type = TableServerFilterType.FILTER_LIST;

    filterSocieta.data = 'FkIdSocieta';

    const filterTipoAmbitoLavorativo = new TableServerFilterItem();
    filterTipoAmbitoLavorativo.options = [];
    filterTipoAmbitoLavorativo.label = 'Tipo Personale';
    filterTipoAmbitoLavorativo.type = TableServerFilterType.FILTER_LIST;
    filterTipoAmbitoLavorativo.data = 'FkIdTipoAmbitoLavorativo';

    this.tableFilters = [
      filterNome,
      filterCognome,
      filterMatricola,
      filterAnno,
      filterMese,
      filterSocieta,
      filterTipoAmbitoLavorativo,
    ];
  }

  ngOnInit() {
    this.importCostiPersonaleService.loadSocieta().subscribe((resSocieta) => {
      this.importCostiPersonaleService
        .loadTipoAmbitoLavorativo()
        .subscribe((resTal) => {
          this.SocietaList = resSocieta.result;
          this.TipoAmbitoLavorativoList = resTal.result;

          if (resSocieta.result && resSocieta.result[0]) {
            let filteredList = this.tableFilters.filter(
              (el) => el.data === 'FkIdSocieta'
            );
            filteredList[0].options = resSocieta.result.map((el) => {
              return {
                label: el.ragioneSociale,
                value: el.idSocieta,
              };
            });
          }

          this.tableFilters.filter(
            (el) => el.data === 'FkIdTipoAmbitoLavorativo'
          )[0].options =
            resTal.result && resTal.result[0]
              ? resTal.result.map((el) => {
                return {
                  label: el.descrizione,
                  value: el.idTipoAmbitoLavorativo,
                };
              })
              : [];
        });
    });
  }

  getViewModeFromTable(viewMode: TableServerActionIntent): CrudNetViewMode {
    if (viewMode === TableServerActionIntent.VIEW) {
      return CrudNetViewMode.VIEW;
    }
    if (viewMode === TableServerActionIntent.EDIT) {
      return CrudNetViewMode.EDIT;
    }
    return CrudNetViewMode.DELETE;
  }

  onDateChange(ev: Date) {
    this.anno = ev.getFullYear();
    this.mese = ev.getMonth() + 1;

    const params = new TableServerSearch();
    params.filters.push(
      {
        data: 'Anno',
        condition: TableServerFilterCondition.EQ,
        type: TableServerFilterType.FILTER_NUMBER,
        value: this.anno,
      },
      {
        data: 'Mese',
        condition: TableServerFilterCondition.EQ,
        type: TableServerFilterType.FILTER_NUMBER,
        value: this.mese,
      }
    );
    this.getData(params);
  }

  getData(param?: TableServerSearch) {
    const annoSearch = param && param.filters && param.filters.find(f => f.data === 'Anno') ? param.filters.find(f => f.data === 'Anno').value : this.anno;
    const meseSearch = param && param.filters && param.filters.find(f => f.data === 'Mese') ? param.filters.find(f => f.data === 'Mese').value : this.mese;


    const MeseLitt = moment(meseSearch, 'MM').format('MMMM').toString();
    this.titleBreadcrumbService.setTitle(MeseLitt.substring(0, 1).toUpperCase() + MeseLitt.substring(1) + ' ' + annoSearch);

    if (param && (!param.filters || param.filters.length < 1)) {

      param.filters = [
        ...param.filters,
        {
          data: 'Anno',
          condition: TableServerFilterCondition.EQ,
          type: TableServerFilterType.FILTER_NUMBER,
          value: this.anno,
        },
        {
          data: 'Mese',
          condition: TableServerFilterCondition.EQ,
          type: TableServerFilterType.FILTER_NUMBER,
          value: this.mese,
        },
      ];
      this.currentParam = param;
    }
    const orders =
      param && param.sort ? transformOrdersToServer(param.sort) : null;
    const filters = transformFiltersToServer(param.filters);
    /*
    const newFilters = param && param.filters ? param.filters : [];
    const filters = transformFiltersToServer(newFilters);
    */
    const request: CrudNetSearchRequest<any> = {
      order: orders ? orders : [],
      pageNum: param && param.currentPage ? param.currentPage - 1 : 0,
      pageSize: param && param.itemsPerPage ? param.itemsPerPage : 10,
      filter: filters,
    };
    this.tableRowImport = this.importCostiPersonaleService
      .search(request, null)
      .pipe(
        tap((res) => {
          this.tableConfig.totalItems = res.rowCount;
        }),
        map((res) => res.result)
      );
  }

  manageBtn(docNum) {
    const docToEvaluate = docNum === 1 ? this.documentoUPD : this.documentoUPD2;
    if (
      docToEvaluate &&
      docToEvaluate[0] &&
      docToEvaluate[0].data &&
      docToEvaluate[0].name &&
      docToEvaluate[0].data.length > 0
    ) {
      this.importCostiPersonaleService
        .insertFileImportCostiRec({
          NOMEFILE: docToEvaluate[0].name,
          IDIMPIEGATO: this.idUser,
        })
        .subscribe((res) => {
          const retId = res.result[0].fileImportCostiId;
          const data = docToEvaluate[0].data.split(',')[1];

          const datablob = new Blob([data], {type: 'test.xlsx'});

          const file = new File([datablob], docToEvaluate[0].name, {
            type: 'file',
          });

          this.excelService.readFile(file, 1, true).then((res) => {
            const imports = this.getImportsList(res, retId);

            if (imports.length > 0) {
              this.importCostiPersonaleService
                .insertMultiple(imports)
                .subscribe((res) => {
                  this.anno = imports[0].ANNO;
                  this.mese = imports[0].MESE;

                  const params = new TableServerSearch();
                  params.filters.push(
                    {
                      data: 'Anno',
                      condition: TableServerFilterCondition.EQ,
                      type: TableServerFilterType.FILTER_NUMBER,
                      value: this.anno,
                    },
                    {
                      data: 'Mese',
                      condition: TableServerFilterCondition.EQ,
                      type: TableServerFilterType.FILTER_NUMBER,
                      value: this.mese,
                    }
                  );
                  this.datePickerValue.setFullYear(this.anno, this.mese - 1);
                  this.onDateChange(new Date(this.anno, this.mese - 1));

                  this.importCostiPersonaleService.countLogErrors(retId).subscribe((returnValue) => {
                    if (returnValue.output.return_value > 0) {
                      const message = 'Elementi non importati - ' + returnValue.output.return_value;
                      this.modalService.showWarning(message);
                    } else {
                      this.modalService.showSuccess('Procedura di import completata con successo!');
                    }
                  });
                });
            }
            this.documentoUPD = null;
            this.documentoUPD2 = null;
          });
        });
    } else {
      // TODO Error("WRONG FILE FORMAT");
    }
  }

  getImportsList(res: any[], fileImportCostiId: number): any[] {
    const imports = [];
    // file bocconi
    if (
      res[0] &&
      res[0]['CODICE DIPENDENTE'] &&
      res[0].DENOMINAZIONE &&
      res[0]['TOTALE COSTO DIPENDENTE']
    ) {
      res.forEach((el) => {
        let importoSostVirgolaConPunto = el['TOTALE COSTO DIPENDENTE'];

        imports.push({
          MATRICOLA: el['CODICE DIPENDENTE'],
          RAGIONESOCIALE: el.DENOMINAZIONE,
          FILIALE: 1,
          IDIMPIEGATO: null,
          IDFILEIMPORTCOSTI: fileImportCostiId,
          ANNO: el.ANNO,
          MESE: el.MESE,
          IMPORTO: el['TOTALE COSTO DIPENDENTE'],
        });
      });
      // file nexadata
    } else if (
      res[0] &&
      res[0].Cognome_e_nome &&
      res[0].Ragione_sociale &&
      res[0].Matricola &&
      res[0].Totale
    ) {
      res.forEach((el) => {
        let importoSostVirgolaConPunto = el.Totale;

        imports.push({
          MATRICOLA: el.Matricola,
          RAGIONESOCIALE: el.Ragione_sociale,
          FILIALE: el.Filiale,
          IDIMPIEGATO: null,
          IDFILEIMPORTCOSTI: fileImportCostiId,
          ANNO: el.Anno,
          MESE: el.Mese,
          IMPORTO: el.Totale,
        });
      });
    }

    return imports;
  }

  updateRec() {
  }

  openCreate() {
    const closeModal = new EventEmitter();
    closeModal.subscribe((create) => {
      if (!create) {
        this.modalService.close(this.idModal);
        this.getData(this.currentParam);
      }
    });

    const successModal = new EventEmitter<any>();
    successModal.subscribe((res) => {
      this.modalService.close(this.idModal);
      this.getData(this.currentParam);
      this.modalService.showSuccess('SUCCESSED_OPERATION');
      this.modalOption = {
        ...this.modalOption,
        inputs: {
          viewMode: CrudNetViewMode.CREATE,
          idCurrent: 0,
          service: this.importCostiPersonaleService,
          deleteFn: (row) =>
            this.importCostiPersonaleService
              .deleteRec(row.idCostoEffettivoDipendente)
              .subscribe((res) => {
                this.getData(this.currentParam);
              }),
          updateFn: (par) =>
            this.importCostiPersonaleService.updateFn(par).subscribe((res) => {
              this.getData(this.currentParam);
            }),
          createFn: (par) =>
            this.importCostiPersonaleService
              .insertSingleRecord(par)
              .subscribe((res) => {
                this.getData(this.currentParam);
              }),
        },
      };
      this.modalService.open(this.idModal);
    });
    this.modalOption = {
      component: this.customCrud,
      inputs: {
        service: this.importCostiPersonaleService,
        viewMode: CrudNetViewMode.CREATE,
        createFn: (par) =>
          this.importCostiPersonaleService
            .insertSingleRecord(par)
            .subscribe((res) => {
              this.getData(this.currentParam);
              this.modalService.close(this.idModal);
            }),
      },
      outputs: {
        close: closeModal,
        success: successModal,
      },
    };
    this.modalService.open(this.idModal);
  }

  openModalLogFile() {
    this.showModalLogFile = true;
    this.modalService.open(this.idModalLogFile);
  }

  closeModalLogFile() {
    this.modalService.close(this.idModalLogFile);
    this.showModalLogFile = false;
  }
}
