import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import {BaseRepo} from '../../repos/BaseRepo';
import { transformFiltersToServer, CrudNetFilterExpression, CrudNetResultResponse, CrudNetSearchRequest } from 'crudnet-amgular';
import { DispositivoModel } from './dispositivo.model';
import { from, Observable } from 'rxjs';
import { TableServerFilter, TableServerFilterCondition, TableServerFilterType } from 'utils';

@Injectable({
  providedIn: 'root'
})
export class DispositivoService extends BaseRepo<any> {

  getTable() {
    return 'Dispositivo';
  }

 search(
    params: CrudNetSearchRequest<DispositivoModel>,
    customTable?: any,
    filters?: CrudNetFilterExpression): Observable<CrudNetResultResponse<DispositivoModel>> {
      if ( customTable) {
        return super.search(params, customTable);
      }
      params.includes = ['TipoDispositivo', 'AssegnazioneDispositivo_List.Impiegato.Anagrafica'] ;
      return this.search2(params);
  }

  tabledef(customTable?: string): Observable<any> {
    if (customTable) { return super.tabledef(customTable); }

    return super.tabledef().pipe(
      map(res => {
        res.result.columns.push({
          name: 'numeroFattura',
          precision: null,
          required: false,
          type: 'String'
        });
        res.result.columns.push({
          name: 'allegato',
          precision: null,
          required: false,
          type: 'Byte[]',
          acceptFileType: 'application/pdf'
        });
        res.result.columns = res.result.columns.filter(p => p.name != 'fkIdFattura');
        return res;
      })
    );
  }

  find(id, customTable?: string) {
    if (customTable) { return super.find(id, customTable); }
    const prom = super.find(id).toPromise().then(res => {
      if (res.result.fkIdFattura) {
        return super.exec('fn_GetFattura', {order: null, pageNum: 1, pageSize: -1, par: {IDFATTURA: res.result.fkIdFattura}}).toPromise().then(fatturaRes => {
          res.result = {
            ...res.result,
            allegato: [fatturaRes.result[0].DOCUMENTO],
            numeroFattura: fatturaRes.result[0].NUMEROFATTURA
          };
          return res;
        });
      }
      return res;
    });
    return from(prom);
  }


  assegnaDispositivo(idDispositivo , idUtente, dataInizio, documento) {

    return this.exec('sp_AssignDispositivo',
    {
      par: {
          IDDISPOSITIVO: idDispositivo,
          IDIMPIEGATO: idUtente,
          DATAINIZIOASSEGNAZIONE: dataInizio,
          DOCUMENTORICEVUTA: documento
      },
      order: [],
      pageNum: 0,
      pageSize: -1
  });
  }


  restituisciDispositivo(idDispositivo , idUtente, dataFine, documento) {

    return this.exec('sp_ReturnDispositivo',
    {
      par: {
          IDDISPOSITIVO: idDispositivo,
          IDIMPIEGATO: idUtente,
          DATAFINEASSEGNAZIONE: dataFine,
          DOCUMENTORICEVUTA: documento
      },
      order: [],
      pageNum: 0,
      pageSize: -1
  });
  }

  updateDispositivo(idDispositivo , idTipoDispositivo, numeroFattura, doc, modello, serial) {
    return this.exec('sp_UpdateDispositivo',
    {
      par: {
        IDDISPOSITIVO: idDispositivo,
        IDTIPODISPOSITIVO	: idTipoDispositivo,
        NUMEROFATTURA					: numeroFattura,
        DOCUMENTOFATTURA				: doc,
        MODELLO						: modello,
        SERIALNUMBER		  : serial
      },
      order: [],
      pageNum: 0,
      pageSize: -1
  });
  }

  insertDispositivo(idTipoDispositivo, numeroFattura, doc, modello, serial) {
    return this.exec('sp_InsertDispositivo',
    {
      par: {
        IDTIPODISPOSITIVO	: idTipoDispositivo,
        NUMEROFATTURA					: numeroFattura,
        DOCUMENTOFATTURA				: doc,
        MODELLO						: modello,
        SERIALNUMBER		  : serial
      },
      order: [],
      pageNum: 0,
      pageSize: -1
  });
  }

  getFattura(idFattura) {
    return this.find(idFattura, 'Fattura');
  }

  getDocumentoAssegnazione(id,idMode){
    return this.exec('fn_GetRicevutaDispositivo',{order:[],pageNum:1,pageSize:-1,par:{
      IDASSEGNAZIONEDISPOSITIVO	:id,
      FLAGINOUT									:idMode			
    }});
  }

  updateDocumento(idAssegnazione, documento, flagIsConsegna) {
    return this.exec('sp_UpdateDocAssDispositivo',
    {
      par: {
        IDASSEGNAZIONE: idAssegnazione,
        DOCAGGIORNATO: documento,
        FLAGISCONSEGNA: flagIsConsegna
      },
      order: [],
      pageNum: 0,
      pageSize: -1
    });
  }

  getIdAssegnazioneDispositivo(idDispositivo) {
    let filter = new TableServerFilter();
    filter.data = "FKIDDISPOSITIVO";
    filter.value = idDispositivo;
    filter.condition = TableServerFilterCondition.EQ;
    filter.type = TableServerFilterType.FILTER_NUMBER;

    let filters = [filter];
    let params = {
      pageNum: 0,
      pageSize: -1,
      filter: transformFiltersToServer(filters), 
    }

    params.filter.expression = params.filter.expression + " && !DATAFINEASSEGNAZIONE.HasValue"

    return this.search2(params, "AssegnazioneDispositivo");
  }
}
