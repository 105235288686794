import { element } from "protractor";
import { map, tap } from "rxjs/operators";
import { RichiesteService } from "./../../richieste/richieste.service";
import {
  Component,
  DebugEventListener,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  faEdit,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  CrudNetFilterExpression,
  CrudNetRepo,
  CrudNetUpdateRequest,
  CrudNetViewMode,
} from "crudnet-amgular";
import { I18NextPipe } from "angular-i18next";
import { Observable, of } from "rxjs";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerSearch,
  TableServerColumnRender,
  TableServerColumnSortDirection,
  ModalService,
} from "utils";
import { ArchivioCandidatiCustomCrudComponent } from "../../archivio-candidati/archivio-candidati-custom-crud/archivio-candidati-custom-crud.component";
import { CustomActionButtonComponent } from "src/app/components/custom-action-button/custom-action-button.component";
import { ArchivioCandidatiService } from "../../archivio-candidati/archivio-candidati.service";
import { RichiesteInLavorazioneService } from "../richieste-in-lavorazione.service";
import * as moment from "moment";
import i18next from "i18next";
import { RichiesteInLavorazioneLogViewService } from "./richieste-in-lavorazione-log-view/richieste-in-lavorazione-log-view.service";
import { ArchivioCandidatiLogAzioniViewerComponent } from "../../archivio-candidati/archivio-candidati-log-azioni-viewer/archivio-candidati-log-azioni-viewer.component";

@Component({
  selector: "app-richieste-in-lavorazione-custom-crud",
  templateUrl: "./richieste-in-lavorazione-custom-crud.component.html",
  styleUrls: ["./richieste-in-lavorazione-custom-crud.component.css"],
})
export class RichiesteInLavorazioneCustomCrudComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: RichiesteInLavorazioneService;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;
  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  /**
   * method to fire a new search from server
   */
  @Output() search: EventEmitter<TableServerSearch> =
    new EventEmitter<TableServerSearch>();

  viewModes = CrudNetViewMode;
  defaultCrudValues;
  customSubmitParams: any[] = [];
  currentIcon = faEye;
  closeIcon = faTimes;
  hideTitle = false;

  element = null;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]>;
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  deleteModalId = "deleteModalId";

  logDetailModal = "logDetailModal";
  showModalViewLog = false;
  viewLogIcon = faClipboard;
  trashIcon = faTrash;
  selectedRow: any;

  customCRUD: any;
  customCRUDLogView: any;

  constructor(
    private modalService: ModalService,
    public archivioCandidatiService: ArchivioCandidatiService,
    public richiesteLogViewService: RichiesteInLavorazioneLogViewService,
    public translatePipe: I18NextPipe,
    public richiesteService: RichiesteService
  ) {
    this.viewMode = CrudNetViewMode.VIEW;
    this.customCRUD = ArchivioCandidatiCustomCrudComponent;
    this.customCRUDLogView = ArchivioCandidatiLogAzioniViewerComponent;

    let columns = [];

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    columns = [];

    const column1 = new TableServerColumn();
    column1.label = "NOME";
    column1.data = "nome";
    columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = "COGNOME";
    column2.data = "cognome";
    columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = "Ultima Azione";
    column3.data = "ultimaAzione";
    columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = "Tipo Ultima Azione";
    column4.data = "tipoUltimaAzione";
    columns.push(column4);

    const column5 = new TableServerColumn();
    column5.label = "";
    column5.data = "";
    column5.actions = this.tableActions;
    column5.sortable = false;
    column5.type = TableServerColumnType.COLUMN_ACTION;
    column5.render = (row, column, viewMode, closeModal) => {
      const ret = new TableServerColumnRender();
      ret.component = this.customCRUD;
      ret.inputs = {
        service: this.archivioCandidatiService, //TODO archivio service mappare in input
        viewMode: this.getViewModeFromTable(viewMode),
        idCurrent: row["idCandidato"],
        lookupFilters: this.lookupFilters,
        refFieldMap: this.refFieldMap,
        defaultValues: this.defaultValues,

        /**
         * mandiamo in input sia la row che gli input per i custom component
         */
        row,
      };

      const closeEmitter = new EventEmitter();
      closeEmitter.subscribe(closeModal);
      ret.outputs = {
        close: closeEmitter,
      };
      return ret;
    };
    columns.push(column5);

    const action = new EventEmitter();
    action.subscribe((row) => {
      this.selectedRow = row;
      this.openModalCandidatoLog();
    });

    const column6 = new TableServerColumn();
    column6.type = TableServerColumnType.COLUMN_CUSTOM;
    column6.sortable = false;
    column6.render = (row, column, closeModal) => {
      const render = new TableServerColumnRender();
      render.component = CustomActionButtonComponent;
      render.inputs = {
        row,
        buttonClass: "",
        actionIcon: this.viewLogIcon,
      };
      const closeEmitter = new EventEmitter();
      closeEmitter.subscribe(closeModal);

      render.outputs = {
        action,
        close: closeEmitter,
      };

      return render;
    };

    columns.push(column6);

    const actionDelete = new EventEmitter();
    actionDelete.subscribe((row) => {
      this.selectedRow = row;
      this.openDeleteModal();
    });

    const column7 = new TableServerColumn();
    column7.type = TableServerColumnType.COLUMN_CUSTOM;
    column7.sortable = false;
    column7.render = (row, column, closeModal) => {
      const render = new TableServerColumnRender();
      render.component = CustomActionButtonComponent;
      render.inputs = {
        row,
        buttonClass: "",
        hide: row["idTipoUltimaAzione"] !== 1, //se ha eseguito azioni dopo l'assegnazione, non posso rimuoverlo dalla richiesta
        actionIcon: this.trashIcon,
      };

      const closeEmitter = new EventEmitter();
      closeEmitter.subscribe(closeModal);

      render.outputs = {
        action: actionDelete,
        close: closeEmitter,
      };

      return render;
    };

    columns.push(column7);

    this.tableConfig.columns = columns;
  }

  openModalCandidatoLog() {
    this.modalService.open(this.logDetailModal);
    this.showModalViewLog = true;
  }

  closeModalCandidatoLog() {
    this.modalService.close(this.logDetailModal);
    this.showModalViewLog = false;
    this.getData();
  }

  /**
   * mapping from tableserver viewmode to crudnetviewmode
   */
  getViewModeFromTable(viewMode: TableServerActionIntent): CrudNetViewMode {
    if (viewMode == TableServerActionIntent.VIEW) {
      return CrudNetViewMode.VIEW;
    }
    if (viewMode == TableServerActionIntent.EDIT) {
      return CrudNetViewMode.EDIT;
    }
    return CrudNetViewMode.DELETE;
  }

  ngOnInit() {}

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  getData() {
    this.tableRow = this.richiesteService
      .getCandidatiAssegnatiRichiesta(this.row.idRichiesta)
      .pipe(
        map((res) => {
          this.tableConfig.totalItems = res.result.length;
          return res.result.map((candidato) => ({
            idCandidato: candidato.IDCANDIDATO,
            idRichiesta: this.row.idRichiesta,
            nome: candidato.NOME,
            cognome: candidato.COGNOME,
            ultimaAzione: this.getFormattedDate(candidato.DATACREAZIONE),
            tipoUltimaAzione: candidato.DESCTIPOAZIONE,
            idTipoUltimaAzione: candidato.IDTIPOAZIONERECRUITER,
          }));
        })
      );
  }

  getFormattedDate(date): string {
    let momentVariable = moment(date);
    return momentVariable.format("DD/MM/YYYY");
  }

  getCustomCrudViewTitle() {
    return i18next.t("RICHIESTE_IN_LAVORAZIONE_TITLE", {
      nomeAzienda: this.row.Cliente.codiceCliente,
      data: this.getFormattedDate(this.row.dataCreazione),
      interpolation: { escapeValue: false },
    });
  }

  getTitoloLogDetailModal() {
    return this.selectedRow
      ? this.selectedRow.nome + " " + this.selectedRow.cognome
      : "";
  }

  openDeleteModal() {
    this.modalService.open(this.deleteModalId);
  }

  closeDeleteModal() {
    this.modalService.close(this.deleteModalId);
  }

  mapRemoveCandidatoToServer() {
    return {
      IDCANDIDATO: this.selectedRow.idCandidato,
      IDRICHIESTA: this.selectedRow.idRichiesta,
    };
  }

  deleteItem() {
    const p = new CrudNetUpdateRequest();
    p.entity = this.mapRemoveCandidatoToServer();
    this.richiesteService.removeCandidatoFromRichiesta(p).subscribe((res) => {
      if (res.result && res.result[0] && res.result[0].ESITO === 1) {
        this.modalService.showSuccess(
          this.translatePipe.transform("SUCCESSED_OPERATION")
        );
        this.modalService.close(this.deleteModalId);
        this.closeDeleteModal();
        this.getData();
      } else {
        this.modalService.showError(res.result[0].ERRORMESSAGE);
      }
    });
  }
}
