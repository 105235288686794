import { NgModule } from "@angular/core";
import { CrudNetFormComponent } from "./crud-net-form/crud-net-form.component";
import { CrudNetFieldTextComponent } from "./crud-net-field-text/crud-net-field-text.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CrudNetFieldPasswordComponent } from "./crud-net-field-password/crud-net-field-password.component";
import { CrudNetFieldIntegerComponent } from "./crud-net-field-integer/crud-net-field-integer.component";
import { CrudNetFieldDecimalComponent } from "./crud-net-field-decimal/crud-net-field-decimal.component";
import { CrudNetFieldLongTextComponent } from "./crud-net-field-long-text/crud-net-field-long-text.component";
import { CrudNetFieldDateComponent } from "./crud-net-field-date/crud-net-field-date.component";
import { CrudNetFieldSelectComponent } from "./crud-net-field-select/crud-net-field-select.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { CrudNetFieldBooleanComponent } from "./crud-net-field-boolean/crud-net-field-boolean.component";
import { CrudNetTableComponent } from "./crud-net-table/crud-net-table.component";
import { UtilsModule } from "utils";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SpinnerComponent } from "utils";
import { CrudNetFieldSelectMultipleComponent } from "./crud-net-field-select-multiple/crud-net-field-select-multiple.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TypeaheadConfig, TypeaheadModule } from "ngx-bootstrap/typeahead";
import { CrudNetFieldFileComponent } from "./crud-net-field-file/crud-net-field-file.component";
import { CrudNetFieldAutcompleteComponent } from "./crud-net-field-autcomplete/crud-net-field-autcomplete.component";
import { CrudNetFieldTimeComponent } from "./crud-net-field-time/crud-net-field-time.component";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { CrudNetFieldAutocompleteSelectfirstComponent } from "./crud-net-field-autocomplete-selectfirst/crud-net-field-autocomplete-selectfirst.component";
import { BsDropdownConfig, BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CrudNetFieldMonthsComponent } from "./crud-net-field-months/crud-net-field-months.component";

@NgModule({
  declarations: [
    CrudNetFormComponent,
    CrudNetFieldTextComponent,
    CrudNetFieldPasswordComponent,
    CrudNetFieldIntegerComponent,
    CrudNetFieldDecimalComponent,
    CrudNetFieldLongTextComponent,
    CrudNetFieldDateComponent,
    CrudNetFieldSelectComponent,
    CrudNetFieldBooleanComponent,
    CrudNetTableComponent,
    CrudNetFieldSelectMultipleComponent,
    CrudNetFieldFileComponent,
    CrudNetFieldAutcompleteComponent,
    CrudNetFieldTimeComponent,
    CrudNetFieldAutocompleteSelectfirstComponent,
    CrudNetFieldMonthsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //DpDatePickerModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    TimepickerModule.forRoot(),
    NgSelectModule,
    UtilsModule,
    FontAwesomeModule,
  ],
  entryComponents: [CrudNetFormComponent, SpinnerComponent],
  providers: [
    TypeaheadConfig,
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
  exports: [
    CrudNetFormComponent,
    CrudNetTableComponent,
    CrudNetFieldSelectComponent,
    CrudNetFieldTextComponent,
    CrudNetFieldDateComponent,
    CrudNetFieldDecimalComponent,
    CrudNetFieldLongTextComponent,
    CrudNetFieldSelectMultipleComponent,
    CrudNetFieldPasswordComponent,
    CrudNetFieldBooleanComponent,
    CrudNetFieldIntegerComponent,
    CrudNetFieldFileComponent,
    CrudNetFieldAutcompleteComponent,
    CrudNetFieldTimeComponent,
    CrudNetFieldAutocompleteSelectfirstComponent,
    CrudNetFieldMonthsComponent,
  ],
})
export class CrudnetAmgularModule {}
