import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnChanges, SimpleChanges} from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TypeaheadSearch } from '../models/CrudNetFormField';
import _ from 'lodash';

/**
 * Component for Listinput in crudnet form
 */
@Component({
  selector: 'cn-field-select',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './crud-net-field-select.component.html',
  styleUrls: ['./crud-net-field-select.component.css']
})
export class CrudNetFieldSelectComponent implements OnInit, OnChanges {

  /*
  @Input() displayValue?:string;
  @Input() options:Array<any>;
  @Input() id:string;
  @Input() label:string;
  @Input() required:boolean;
  @Input() value:any;
  @Input() readonly:boolean;
  @Input() show:boolean;

  @Output() valueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if(!this.displayValue || !this.displayValue.length){
      this.displayValue="descrizione";
    }
  }*/

  @Input() displayValue?: string;
  @Input() id: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() readonly: boolean;
  @Input() show: boolean;
  @Input() options: TypeaheadSearch;

  dataSource: Observable<Array<any>>;
  showValue: string;
  typeaheadLoading = false;

  @Output() valueChange = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();

  constructor() {
    this.dataSource = new Observable((observer: Subscriber<string>) => {
      // Runs on every search
      observer.next(this.showValue || '');
    })
    .pipe(
      mergeMap((token: string) => this.options(token))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue === null) {
      this.showValue = '';
    } else if (changes.value && changes.value.currentValue !== null) {
      this.valueChange.emit(changes.value.currentValue);
    }
  }

  ngOnInit() {
    if (!this.displayValue || !this.displayValue.length) {
      this.displayValue = 'descrizione';
    }
    if (this.value) {
      this.showValue = _.get(this.value, this.displayValue);
    }
  }

  typeaheadOnBlur() {
    // if(this.showValue && this.showValue.length){
      if (this.value) {
        this.showValue = _.get(this.value, this.displayValue);
      } else {
        this.showValue = '';
      }
    // }else{
    //  this.valueChange.emit(null);
    //  this.change.emit();
    // }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  onFocus() {
    this.showValue = '';
  }

  setFocus() {
    document.getElementById(this.id).focus();
  }

  onSelect(item) {
    this.valueChange.emit(item);
    this.change.emit(item);
  }

  clear() {
    this.showValue = '';
    this.valueChange.emit(null);
    this.change.emit();
  }

  isReadOnly(): boolean {
    const elm = document.getElementById(this.id);
    return elm ? (elm.hasAttribute('readonly') || elm.hasAttribute('disabled')) : false;
  }

}
