import {Injectable} from '@angular/core';
import {
  CrudNetBaseResponse,
  CrudNetExecRequest,
  CrudNetResultResponse,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
} from 'crudnet-amgular';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {BaseRepo} from 'src/app/repos/BaseRepo';
import {CostoEffettivoDipendenteModel} from './import-costi-personale.model';

@Injectable({
  providedIn: 'root',
})
export class ImportCostiPersonaleService extends BaseRepo<CostoEffettivoDipendenteModel> {
  getTable(): string {
    return 'CostoEffettivoDipendente';
  }

  search(
    params: CrudNetSearchRequest<CostoEffettivoDipendenteModel>,
    customTable?: any
  ): Observable<CrudNetResultResponse<CostoEffettivoDipendenteModel>> {
    if (!customTable) {
      params.includes = [
        'Impiegato.Anagrafica',
        'Societa',
        'TipoAmbitoLavorativo',
      ];
    }
    return super.search2(params, customTable);
  }

  insertMultiple = (data) => {
    this.showSpinnerDebounce();
    const params = {
      lstAction: data.map((record) => {
        return {action: 'exec', entity: {...record}};
      }),
      orderOfInsertion: 1,
      tableName: 'sp_InsertCostoDipendente',
    };
    return this.getHttpClient()
      .post(this.getUrl(`/Generic/updMulti/dummy`), [params], {
        headers: this.getHeaders(),
      })
      .pipe(
        tap(
          this.hideSpinnerDebounce.bind(this),
          this.hideSpinnerDebounce.bind(this)
        ),
        catchError(this.onError.bind(this))
      );
  };

  insertSingleRecord = (par) =>
    this.exec('sp_InsertCostoDipendente', {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: par,
    });

  updateFn = (par) => {
    return this.exec('sp_UpdateCostoDipendente', {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {
        IDIMPIEGATO: par.idImpiegato,
        ANNO: par.anno,
        MESE: par.mese,
        IMPORTO: par.importo,
      },
    });
  };

  deleteRec = (id) =>
    this.exec('sp_DeleteCostoDipendente', {
      par: {
        IDCOSTOEFFETTIVODIPENDENTE: id,
      },
      order: [],
      pageNum: 0,
      pageSize: -1,
    });

  insertFileImportCostiRec = (payload) =>
    this.exec('sp_InsertFileImportCosti', {
      par: payload,
      order: [],
      pageNum: 0,
      pageSize: -1,
    });

  loadSocieta = () => this.search({pageNum: 0, pageSize: -1}, 'Societa');

  loadTipoAmbitoLavorativo = () =>
    this.search({pageNum: 0, pageSize: -1}, 'TipoAmbitoLavorativo');

  countLogErrors = (par) => {
    return this.exec('fn_CountLogErrori', {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {
        IDFILEIMPORTCOSTI: par
      },
    });
  }
}
