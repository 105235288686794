import { Component, OnInit } from "@angular/core";
import { TypeaheadSearch } from "crudnet-amgular";
import { map } from "rxjs/operators";
import { ExcelService } from "utils";
import { ReportService } from "./report.service";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
})
export class ReportComponent {
  selectedReport;
  listaParametri = [];
  show = false;
  listaFunzioniDataSet: TypeaheadSearch = (typedSearch) => {
    return this.service
      .getFunctions()
      .pipe(
        map((f) =>
          f.filter(
            (func) =>
              func.descrizione
                .toLowerCase()
                .indexOf(typedSearch.toLowerCase()) >= 0
          )
        )
      );
  };

  constructor(
    private service: ReportService,
    private excelService: ExcelService
  ) {}

  handleSelection(event) {
    this.show = false;
    this.service.procdef(this.selectedReport.value).subscribe((def) => {
      if (def.result && def.result.Parameter) {
        this.listaParametri = def.result.Parameter;
        this.show = true;
      }
    });
  }

  reportExcel() {
    this.service
      .exec(this.selectedReport.value, {
        order: null,
        pageNum: 1,
        pageSize: -1,
        par: this.mapresulttoObj(),
      })
      .subscribe((res) => {
        if (res.result) {
          this.excelService.exportAsExcelFile(
            res.result,
            this.selectedReport.value
          );
        }
      });
  }

  mapresulttoObj() {
    let ret = {};
    this.listaParametri.forEach((p) => {
      ret[p.Name] = p.value;
    });
    return ret;
  }
}