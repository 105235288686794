import {Injectable, Injector} from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {CrudNetExecRequest, CrudNetResultResponse, CrudNetSearchRequest} from 'crudnet-amgular';
import {Observable} from 'rxjs';
import {RichiestaFeriePermessoModel} from './gestione-ferie-permessi.model';
import {map} from 'rxjs/operators';
import {PermessoModel} from '../permesso/permesso.model';
import {findIndex} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GestioneFeriePermessiService extends BaseRepo<RichiestaFeriePermessoModel> {

  getTable(): string {
    return 'richiestaFeriePermesso';
  }

  acceptDenyFerie(params: CrudNetExecRequest) {
    return this.exec('sp_MassiveEvaluateRichiestaPFM', params);
  }

 search(
   params: CrudNetSearchRequest<RichiestaFeriePermessoModel>,
   customTable?: any,
   annomese?: string,
   flagStorico?: boolean,
   codiceRichiesta?: string,
 ): Observable<CrudNetResultResponse<PermessoModel>> {

   if (customTable) {
     return super.search(params, customTable);
   }
   const execParams = new CrudNetExecRequest();
   if (params.filter) {
     execParams.filter = params.filter;
   }
   execParams.par = {
     FLAGSTORICO: flagStorico ? 1 : 2,
   };
   if (codiceRichiesta) {
     execParams.par = {...execParams.par, CODICERICHIESTA: codiceRichiesta};
   }
   if (annomese) {
     execParams.par = {...execParams.par, ANNOMESE: annomese};
   }
   execParams.pageNum = params.pageNum;
   execParams.pageSize = params.pageSize;
   if (params.order) {
     execParams.order = params.order.map(order => order.toUpperCase());
   }
   return super.exec('fn_GetRichiesteAccettazione', execParams).pipe(
     map(res => {
       res.result = res.result.map( perm => new PermessoModel(
         null,
         null,
         null,
         null,
         perm.CODICETIPORICHIESTA,
         null,
         perm.IDRICHIESTAFERIEPERMESSO,
         perm.NOMEIMPIEGATORICHIEDENTE,
         perm.COGNOMEIMPIEGATORICHIEDENTE,
         perm.DESCTIPORICHIESTA,
         perm.ARRAYDETTAGLIRICHIESTA,
         perm.MISURATIPORICHIESTA,
     ));
       return res;
     })
   );
   // params.includes = [
   //   'DettaglioRichiestaPFM_List',
   //   'Impiegato.Anagrafica'
   // ];
   // return super.search2(params).pipe();
 }

 // tabledef(customTable?: string): Observable<any> {
 //   return super.tabledef(customTable).pipe(
 //     map( res => {
 //       res.result.columns = res.result.columns.map( col => {
 //         if (col.name === 'fkIdImpiegatoRichiedente') {
 //           col.realName = 'fkIdImpiegato';
 //         }
 //         return col;
 //       });
 //       return res;
 //     })
 //   );
 // }
}

