import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number;
  @Input() total: number;
  color: string;

  constructor() { }

  ngOnInit() {
    if (!this.progress) {
      this.progress = 0;
    }

    if (this.total === 0) {
      this.total = this.progress;
    } else if (!this.total) {
      this.total = 100;
    }

    if (this.progress > this.total) {
      this.progress = 100;
      this.total = 100;
    }

    this.progress = (this.progress / this.total) * 100;

    if (this.progress < 30) {
      this.color = 'green';
    } else if (this.progress < 55) {
      this.color = 'blue';
    } else if (this.progress < 80) {
      this.color = 'yellow';
    } else {
      this.color = 'red';
    }
  }

}
