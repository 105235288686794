import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faLockOpen} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-action-deconsolida-button',
  templateUrl: './action-deconsolida-button.component.html',
  styleUrls: ['./action-deconsolida-button.component.css']
})
export class ActionDeconsolidaButtonComponent implements OnInit {
  @Input() consolidato: any;
  @Input() row: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  deconsolidaIcon = faLockOpen;

  constructor() { }

  ngOnInit() {
  }

  onAction() {
    this.action.emit(this.row);
  }

}
