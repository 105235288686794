import { Observable } from 'rxjs';

export class CrudnetHeaderButton{
    text:string;
    action:CrudnetHeaderButtonAction;
    icon?:string;
}

export type CrudnetHeaderButtonAction = () => void;

export type TypeaheadSearch = (typedValue: string) => Observable<Array<any>>;
export class CrudNetField {
   type: CrudNetFieldType;
   id: string;
   realId: string;
   customName: string;
   label: string;
   required: boolean;
   precision: number;
   readOnly: boolean;
   multiple: boolean;
   hideMe?: Function;
   options?: any[];
   optionSearch?: TypeaheadSearch;
   displayValue?: string;
   value: any;
   acceptFileType?: string;
}

export class CrudNetFieldRelation {
    parent: string;
    child: string;
}

export enum CrudNetFieldType {
    FIELD_TEXT = 'FIELD_TEXT',
    FIELD_DATE = 'FIELD_DATE',
    FIELD_INTEGER = 'FIELD_INTEGER',
    FIELD_DECIMAL = 'FIELD_DECIMAL',
    FIELD_LONGTEXT = 'FIELD_LONGTEXT',
    FIELD_SELECT = 'FIELD_SELECT',
    FIELD_BOOLEAN = 'FIELD_BOOLEAN',
    FIELD_SELECT_MULTIPLE = 'FIELD_SELECT_MULTIPLE',
    FIELD_FILE = 'FIELD_FILE',
    FIELD_MAPS = 'FIELD_MAPS',
    FIELD_PASSWORD = 'FIELD_PASSWORD',
    FIELD_AUTOCOMPLETE= 'FIELD_AUTOCOMPLETE',
    FIELD_AUTOCOMPLETE_SELECTFIRST= 'FIELD_AUTOCOMPLETE_SELECTFIRST',
    FIELD_MONTH= 'FIELD_MONTH',
}

export enum CrudNetViewMode {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    CREATE = 'CREATE'
}
