import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounce, debounceTime, throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutService {

  windowsResizedMobile=new Subject<boolean>();
  isMobile=null;
  toogleRightSidebar=new Subject<boolean>();

  constructor() { 
    fromEvent(window,'resize').pipe(debounceTime(500)).subscribe(this.emitIsMobile.bind(this));
  }

  emitIsMobile(){
    const isMobileNew=document.documentElement.clientWidth<768;
    if(this.isMobile!=isMobileNew){
      this.isMobile=isMobileNew;
      this.windowsResizedMobile.next(this.isMobile);
    }
  }

  
  
}
