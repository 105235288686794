import {Component, Input, OnInit} from '@angular/core';
import {
  TableServerActionIntent,
  TableServerColumn, TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem
} from 'utils';
import {Observable, of} from 'rxjs';
import {CustomStringRendererComponent} from "../../../components/custom-string-renderer/custom-string-renderer.component";
import { RapportinoService } from '../../rapportino/rapportino.service';
import { groupBy,sumBy } from 'lodash';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-gestione-rapportino-expandable-column',
  templateUrl: './gestione-rapportino-expandable-column.component.html',
  styleUrls: ['./gestione-rapportino-expandable-column.component.css']
})
export class GestioneRapportinoExpandableColumnComponent implements OnInit {

  @Input() idImpiegato: number;
  @Input() anno: number;
  @Input() mese: number;

  tableConfig: TableServerConfig;
  tableRow: Observable<any[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];
  showTableFilter = false;

  constructor(private service:RapportinoService) {
    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = 'Cliente';
    column1.data = 'CLIENTE';
    column1.sortable = false;
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = 'Attività';
    column2.data = 'TITOLOATTIVITA';
    column2.sortable = false;
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = 'Ore';
    column3.data = 'TOTMINUTILAVORATI';
    column3.sortable = false;
    column3.type = TableServerColumnType.COLUMN_CUSTOM;
    column3.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: (row.TOTMINUTILAVORATI / 60 ).toFixed(2)
      };
      return render;
    };
    this.tableConfig.columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = 'Giorni';
    column4.data = 'TOTMINUTILAVORATI';
    column4.sortable = false;
    column4.type = TableServerColumnType.COLUMN_CUSTOM;
    column4.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: (row.TOTMINUTILAVORATI / 60 / 8).toFixed(2)
      };
      return render;
    };
    this.tableConfig.columns.push(column4);
  }


  getDettagli(totalReport) {
    const filteredById = groupBy(
      totalReport,
      "IDATTIVITA"
    );
    return Object.keys(filteredById).map((elm) => {
      return {
        CLIENTE: filteredById[elm][0].RAGIONESOCIALECLIENTE,
        TITOLOATTIVITA: filteredById[elm][0].DESCATTIVITA,
        TOTMINUTILAVORATI: sumBy(
          filteredById[elm],
          (dettRapp) => dettRapp.MINUTI + dettRapp.ORE * 60
        ),
      };
    });
  }

  ngOnInit() {
    this.tableRow = this.service.getRapportinoByAnnoMese(this.anno,this.mese,null,null,this.idImpiegato).pipe(
      map(res=>this.getDettagli(res.result))
    )
  }

}
