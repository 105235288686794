import { Injectable } from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {BudgetModel} from './budget.model';
import {CrudNetExecRequest, CrudNetResultResponse, CrudNetSearchRequest} from 'crudnet-amgular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BudgetService extends BaseRepo<BudgetModel> {
  getTable(): string {
    return 'Budget';
  }

  search(params: CrudNetSearchRequest<BudgetModel>, customTable?: any): Observable<CrudNetResultResponse<BudgetModel>> {

    if (!customTable) {
      const execParams: CrudNetExecRequest = {
        filter: params.filter,
        order: params.order.map(elm => elm.toUpperCase()),
        pageNum: params.pageNum + 1,
        pageSize: params.pageSize,
        par: {}
      };
      return super.exec('fn_GetListaBudget', execParams).pipe(
        map(res => {
          res.result = res.result.map(r => ({...r, idBudget: r.IDBUDGET}));
          return res;
        })
      );
    }
    return super.search(params, customTable);
  }

  getConsuntivo() {
    return true;
  }

  getCostiExtraCommessa(params: CrudNetExecRequest): Observable<CrudNetResultResponse<any>> {
    return super.exec('fn_BudgetCalcolaCostiCommessa', params);
  }
}
