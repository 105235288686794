import { Component, OnInit } from '@angular/core';
import {CrudNetViewMode} from 'crudnet-amgular';
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType
} from 'utils';
import {Observable, of} from 'rxjs';
import {SocietaService} from './societa.service';

@Component({
  selector: 'app-societa',
  templateUrl: './societa.component.html',
  styleUrls: ['./societa.component.css']
})
export class SocietaComponent implements OnInit {

  viewMode: CrudNetViewMode;
  loadForm = false;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  constructor(public societaService: SocietaService) {

    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;

    const column1 = new TableServerColumn();
    column1.label = 'Ragione sociale';
    column1.data = 'ragioneSociale';
    this.tableConfig.columns = [column1];

    const filter1 = new TableServerFilterItem();
    filter1.label = 'Ragione sociale';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = 'ragioneSociale';
    this.tableFilters.push(filter1);
  }

  ngOnInit() {
  }

}
