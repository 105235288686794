import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DettaglioRichiesta} from '../../permesso/dettaglioRichiesta.model';
import {Observable, of} from 'rxjs';
import {
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerSearch
} from 'utils';
import {CustomDateRendererComponent} from '../../../components/custom-date-renderer/custom-date-renderer.component';
import {TableColumnAcceptDenyComponent} from '../../../components/table-column-accept-deny/table-column-accept-deny.component';

@Component({
  selector: 'app-gestione-ferie-permessi-expanded',
  templateUrl: './gestione-ferie-permessi-expanded.component.html',
  styleUrls: ['./gestione-ferie-permessi-expanded.component.css']
})
export class GestioneFeriePermessiExpandedComponent implements OnInit {

  @Input() set dettagliRichiesta(dettagli: DettaglioRichiesta[]) {
    this.tableRow = of(dettagli);
  }
  @Output() onAccept: EventEmitter<DettaglioRichiesta> = new EventEmitter<DettaglioRichiesta>();
  @Output() onDeny: EventEmitter<DettaglioRichiesta> = new EventEmitter<DettaglioRichiesta>();

  tableConfig: TableServerConfig;
  tableRow: Observable<DettaglioRichiesta[]> = of([]);
  tableActions = [];
  tableFilters: TableServerFilterItem[] = [];

  constructor() {
    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const onAcceptedValue = new EventEmitter();
    onAcceptedValue.subscribe(row => {
      this.onAccept.emit(row);
    });

    const onDeniedValue = new EventEmitter<TableServerSearch>();
    onDeniedValue.subscribe(row => {
      this.onDeny.emit(row);
    });

    const column5 = new TableServerColumn();
    column5.label = 'Data';
    column5.data = '';
    column5.sortable = false;
    column5.type = TableServerColumnType.COLUMN_CUSTOM;
    column5.render = (row: DettaglioRichiesta, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.DATAINIZIO,
      };
      return render;
    };
    this.tableConfig.columns.push(column5);

    const acceptDenyColumn = new TableServerColumn();
    acceptDenyColumn.label = '';
    acceptDenyColumn.data = 'accept';
    acceptDenyColumn.sortable = false;
    acceptDenyColumn.type = TableServerColumnType.COLUMN_CUSTOM;
    acceptDenyColumn.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = TableColumnAcceptDenyComponent;
      render.inputs = {
        value: row
      };
      render.outputs = {
        acceptedValue: onAcceptedValue,
        deniedValue: onDeniedValue,
      };
      return render;
    };
    this.tableConfig.columns.push(acceptDenyColumn);
  }

  ngOnInit() {
  }

  getData(param: TableServerSearch) {
   // this.tableRow = of(this.dettagliRichiesta);
  }

}
