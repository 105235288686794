import {Injectable} from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {SalModel} from './sal.model';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  CrudNetBaseResponse,
  CrudNetExecRequest,
  CrudNetExecResponse, CrudNetFilterExpression,
  CrudNetResultResponse,
  CrudNetSearchRequest,
  CrudNetUpdateRequest
} from 'crudnet-amgular';
import {sumBy} from 'lodash';
import {indexOf} from '@amcharts/amcharts4/.internal/core/utils/Array';
import {TypeaheadSearchFilterAutocomplete} from '../../../../projects/utils/src/lib/table-server/models/TableServerFilter';
import {ImpiegatoModel} from '../impiegato/impiegato.model';

@Injectable({
  providedIn: 'root'
})
export class SALService extends BaseRepo<SalModel> {

  private notifyGGLavorate = new Subject<number>();
  private notifyGGEffettive = new Subject<number>();

  // Observable object streams
  notifyGGEffettive$ = this.notifyGGEffettive.asObservable();
  notifyGGLavorate$ = this.notifyGGLavorate.asObservable();

  // Service message commands
  sendGGEffettive(ggEffettive: number) {
    this.notifyGGEffettive.next(ggEffettive);
  }

  sendGGLavorate(ggLavorate: number) {
    this.notifyGGLavorate.next(ggLavorate);
  }

  getTable() {
    return 'Sal';
  }

  del(params: CrudNetUpdateRequest<SalModel>, customTable?: string): Observable<CrudNetBaseResponse<SalModel>> {
    let idSal = params.entity.idSal;
    return this.exec('sp_DeleteSAL',
      {
        par: {
          IDSAL: idSal,
        },
        order: [],
        pageNum: 0,
        pageSize: -1
      }
    );
  }


  search(params: CrudNetSearchRequest<SalModel>, customTable?: any): Observable<CrudNetResultResponse<SalModel>> {
    let idImpiegatoSal = null;
    if (!customTable) {
      if (
        params.filter &&
        params.filter.expression &&
        params.filter.expression.indexOf('fkIdImpiegato') >= 0
      ) {
        const ind = params.filter.expression
          .split('&&')
          .filter((el) => el.indexOf('fkIdImpiegato') >= 0)[0]
          .split('@')[1];
        params.filter.expression = params.filter.expression.split('&&')
          .filter(e => !e.includes('fkIdImpiegato')).join('&&');
        idImpiegatoSal = params.filter.expressionValues[ind].value;
      }
      // params.includes = ['StatoFatturazione', 'Commessa.Offerta.TipoOfferta.TipoFatturazione', 'Commessa.Offerta.Cliente'];
      {
        const execParams: CrudNetExecRequest = {
          filter: params.filter,
          order: params.order.map(elm => elm.toUpperCase()),
          pageNum: params.pageNum + 1,
          pageSize: params.pageSize,
          par: idImpiegatoSal ? {
            IDIMPIEGATO: idImpiegatoSal
          } : {}
        };
        return super.exec('fn_GetListaSal', execParams).pipe(
          map(res => {
            res.result = res.result.map(r => ({
              ...r,
              idSal: r.IDSAL
            }));
            return res;
          })
        );
      }
    }
    if (customTable === 'Commessa') {
      params.includes = ['Offerta.Cliente', 'Sede'];
    }
    return super.search2(params, customTable).pipe(
      map(res => {
        if (customTable === 'Commessa') {
          res.result = res.result.map(result => {
            return ({...result, descrizione: result.Offerta.titolo + ' di ' + result.Offerta.Cliente.ragioneSociale});
          });
        }
        return res;
      })
    );
  }

  getGGLavorateEffettive(idCommessa) {
    const params: CrudNetSearchRequest<SalModel> = {
      pageNum: 0,
      pageSize: -1,
      filter: {expressionValues: [{value: idCommessa}], expression: 'fkIdCommessa==@0'}
    };
    return super.search2(params).pipe(
      map(res => {
        const ggEffettive = sumBy(res.result, 'giorniEffettivi');
        const ggLavorate = sumBy(res.result, 'giorniLavorati');
        // this.sendGGEffettive(ggEffettive);
        // this.sendGGLavorate(ggLavorate);
        return {ggEffettive, ggLavorate};
      })
    );
  }

  getRisorseBySal(params: CrudNetExecRequest, conf: any): Observable<CrudNetExecResponse<any>> {
    return super.exec('fn_GetSalRisorse', params, conf);
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map(res => {
        const filteredCols = res.result.columns.filter(c => c.name !== 'fkIdCommessa'
          && c.name !== 'giorniEffettivi'
          && c.name !== 'giorniLavorati'
          && c.name !== 'tariffa'
          && c.name !== 'anno'
          && c.name !== 'mese'
        );
        res.result.columns = filteredCols.map(c => {
          if (c.name === 'anticipata') {
            c.hideMe = (parsedForm, orginalForm, externalData) => {
              if (!externalData || !externalData.TipoOfferta || !externalData.TipoOfferta.TipoFatturazione) {
                return true;
              }
              return externalData.TipoOfferta.TipoFatturazione.codice !== 'GG';
            };
          }
          if (c.name === 'importo') {
            c.hideMe = (parsedForm, orginalForm, externalData) => {
              if (!externalData || !externalData.TipoOfferta || !externalData.TipoOfferta.TipoFatturazione) {
                return false;
              }
              return externalData.TipoOfferta.TipoFatturazione.codice !== 'CO';
            };
          }
          return c;
        });
        return res;
      })
    );
  }

  getReportSalMensile(params: CrudNetExecRequest, conf: any): Observable<CrudNetExecResponse<any>> {
    return super.exec('fn_GetReportSalMensili', params, conf);
  }

  getMinutiByIdAnnoMese(param: CrudNetExecRequest) {
    return super.exec('fn_GetMinutiImpiegatoCommessaMese', param);
  }

  getTotaleSALNonFatturatoPerMese(anno) {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {
        ANNO: anno
      }
    };
    return super.exec('fn_DashboardTotaleSALNonFatturatoPerMese', execParams);
  }

  getTotaleSALFatturatoPerMese(anno) {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {
        ANNO: anno
      }
    };
    return super.exec('fn_DashboardTotaleSALFatturatoPerMese', execParams);
  }

  getTotaleSALPerMese(anno) {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {
        ANNO: anno
      }
    };
    return super.exec('fn_DashboardTotaleSALPerMese', execParams);
  }

  getTotaleSALFatturatoPerCliente(anno) {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {
        ANNO: anno
      }
    };
    return super.exec('fn_DashboardTotaleSALFatturatoPerCliente', execParams);
  }

  getSalDaFatturare() {
    const execParams: CrudNetExecRequest = {
        pageNum: 0,
        pageSize: -1,
        order: [],
        par: {}
    };
    return this.exec('fn_DashboardSALMancanti', execParams);
  }

  rememberDontShowDashboardAlert() {
    localStorage.setItem('salAlert', 'true');
  }

  isDontShowDashboardAlertSet(): boolean {
    var value = localStorage.getItem('salAlert');
    if (value == null) {
      return false;
    }
    return true;
  }
}
