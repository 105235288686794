import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { AuthService } from '../../auth/auth.service';
import { ROLES } from '../../constants';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { ModalService, PieChartElement, TableWidgetElement } from 'utils';
import { I18NextPipe } from 'angular-i18next';
import { CounterCardDateType, CounterDateComponent } from './components/counter-date/counter-date.component';
import {PROFILES} from 'src/app/constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  MESE: number = moment().get('month') + 1;
  ANNO: number = moment().get('year');

  roles = ROLES;
  profiles = PROFILES;


  datePickerValueOfferte: Date = new Date();
  datePickerValueCommesse: Date = new Date();
  datePieckerValueSalClientiPie: Date = new Date();
  datePickerNonConsolidati: Date = new Date();

  idDatePickerNonConsolidati = "datePickerNonConsolidati"

  valueTotaleOfferte = 0;
  valueTotaleCommesse = 0;
  valueTotaleImpiegatiSenzaCommesse = 0;
  valueNonConsolidati = 0;

  arrayOfferte = [];
  arrayCommesse = [];
  arrayConsulenzeInScadenza: TableWidgetElement[] = [];

  idPieChartSalFatturatoCliente = 'pieChartSalFatturatoCliente';

  arrayValuePiechart: PieChartElement[] = [];

  colorTheme = 'theme-red';
  minMode: BsDatepickerViewMode = 'year';

  bsConfig: Partial<BsDatepickerConfig> = {
    minMode: this.minMode,
    containerClass: this.colorTheme,
    dateInputFormat: 'YYYY',
    maxDate: new Date()
  };

  yearOfferte;
  yearCommesse;
  yearSalPie;

  counterCardDateTypes = CounterCardDateType;

  constructor(private dashboardService: DashboardService, public authService: AuthService, public modalService: ModalService, private translatePipe: I18NextPipe) {
  }

  ngOnInit() {
    this.yearCommesse = moment().year().toString();
    this.yearOfferte = moment().year().toString();
    this.yearSalPie = moment().year().toString();
    this.getValueForCounters();
    this.getValuesForGraphOfferte();
    this.getValuesForGraphCommesse();
    this.getValuesForConsulenzeInScadenza();
    this.getValuesForPieChartSalCliente();
  }

  getCurrentMonthYear(): string {
    return moment().format('MMMM YYYY');
  }

  getCurrentYear(): string {
    return moment().format('YYYY');
  }


  getValueForCounters(): void {
    const totaleNonConsolidati = this.dashboardService.getCounterRapportiniNonConsolidati(this.ANNO, this.MESE);
    const totaleCommesse = this.dashboardService.getCounterTotaleCommesse(this.ANNO, 0);
    const totaleOfferte = this.dashboardService.getCounterTotaleOfferte(this.ANNO, 0);
    const totaleImpiegatiSenzaCommesse = this.dashboardService.getCounterPersonaleSenzaCommesse();
    totaleOfferte.subscribe(data => {
      this.valueTotaleOfferte = data.result.length ? data.result[0].TOTALEIMPORTO : 0;
    });
    totaleCommesse.subscribe(data => {
      this.valueTotaleCommesse = data.result.length ? data.result[0].TOTALEIMPORTO : 0;
    });
    
    totaleImpiegatiSenzaCommesse.subscribe(res => {
      this.valueTotaleImpiegatiSenzaCommesse = res.rowCount;
    });
    totaleNonConsolidati.subscribe(data => {
      this.valueNonConsolidati = data.output.return_value ? data.output.return_value : 0;
    });
  }

  onDateChangeOfferte(year): void {
    this.yearOfferte = moment(year).year().toString();
    this.getValuesForGraphOfferte();
  }

  onDateChangeCommesse(year): void {
    this.yearCommesse = moment(year).year().toString();
    this.getValuesForGraphCommesse();
  }

  onDateChangeSalFatturatoPie(year): void {
    this.yearSalPie = moment(year).year().toString();
    this.getValuesForPieChartSalCliente();
  }

  onDateChangeNonConsolidati(date): void {
    const ANNO = moment(date).year();
    const MESE = moment(date).month()+1;
    this.dashboardService.getCounterRapportiniNonConsolidati(ANNO, MESE).subscribe(data => {
      this.valueNonConsolidati = data.output.return_value ? data.output.return_value : 0;
    });
  }

  getValuesForPieChartSalCliente() {
    this.dashboardService.getSalFatturatoCliente(this.yearSalPie).subscribe(res => {
      if (res.result && res.result.length) {
        this.arrayValuePiechart = res.result.map(r => ({ category: r.CLIENTE, value: r.TOTALEIMPORTO }));
      } else {
        this.arrayValuePiechart = [];
      }
    });
  }

  getValuesForConsulenzeInScadenza(): void {
    const consulenzeInScadenza = this.dashboardService.getConsulenzeInScadenza();
    consulenzeInScadenza.subscribe(data => {
      this.arrayConsulenzeInScadenza = data.result.length ? data.result.map((x) => {
        const el: TableWidgetElement = {
          titolo: x.NOME + ' ' + x.COGNOME,
          descrizione:
            this.translatePipe.transform('DATE_FINE_ASSEGNAZIONE') + ': '
            + (x.DATAFINEASSEGNAZIONE ? moment(x.DATAFINEASSEGNAZIONE).format('DD/MM/YYYY') : '---') + ', '
            + x.GIORNIRIMANENTI + ' ' + this.translatePipe.transform('GIORNI_RIMANENTI')
        };
        return el;
      }) : [];
    });
  }

  getValuesForGraphOfferte(): void {
    const totaleOfferte = this.dashboardService.getCounterTotaleOfferte(this.yearOfferte, null);
    totaleOfferte.subscribe(data => {
      this.arrayOfferte = data.result.length ? data.result.map((x) => {
        x.MESE = moment(x.MESE, 'M').format('MMMM').toString().toUpperCase();
        return x;
      }) : [];
    });
  }

  getValuesForGraphCommesse(): void {
    const totaleCommesse = this.dashboardService.getCounterTotaleCommesse(this.yearCommesse, null);
    totaleCommesse.subscribe(data => {
      this.arrayCommesse = data.result.length ? data.result.map((x) => {
        x.MESE = moment(x.MESE, 'M').format('MMMM').toString().toUpperCase();
        return x;
      }) : [];
    });
  }
}
