import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  CrudNetFilterExpression,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
  CrudNetViewMode,
  TypeaheadSearch,
} from "crudnet-amgular";
import { ClienteService } from "../cliente.service";
import {
  faEdit,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { I18NextPipe } from "angular-i18next";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ClienteModel } from "../cliente.model";
import { ModalService, TaalUtilsService } from "utils";

@Component({
  selector: "app-cliente-custom-crud",
  templateUrl: "./cliente-custom-crud.component.html",
  styleUrls: ["./cliente-custom-crud.component.css"],
})
export class ClienteCustomCrudComponent implements OnInit {
  @Input() service: ClienteService;

  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;
  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;
  currentIcon = faPlus;
  closeIcon = faTimes;
  nomeCliente = null;

  codiceCliente = null;
  ragioneSociale = null;
  partitaIVA = null;
  sitoWeb = null;
  emailCliente = null;
  telefono = null;
  fax = null;
  indirizzo = null;
  cap = null;
  comune = null;
  percentualeSconto = null;

  loading = true;

  constructor(
    public translatePipe: I18NextPipe,
    public modalService: ModalService,
    public taalService: TaalUtilsService
  ) {}

  ngOnInit() {
    if (this.viewMode === this.viewModes.CREATE) {
      this.loading = false;
    } else if (
      this.viewMode === this.viewModes.EDIT ||
      this.viewMode === this.viewModes.VIEW
    ) {
      this.setValuesFromRow();
    }
  }

  setValuesFromRow() {
    this.service
      .getValueForSelect(this.row.fkIdComune, "Comune")
      .subscribe((res) => {
        this.comune = res.result;
        this.codiceCliente = this.row.codiceCliente;
        this.ragioneSociale = this.row.ragioneSociale;
        this.partitaIVA = this.row.partitaIva;
        this.sitoWeb = this.row.sitoWeb;
        this.emailCliente = this.row.email;
        this.telefono = this.row.telefono;
        this.fax = this.row.fax;
        this.indirizzo = this.row.indirizzo;
        this.cap = this.row.cap;
        this.percentualeSconto = this.row.percentualeSconto;

        this.loading = false;
      });
  }

  closeModal() {
    this.close.emit();
  }

  mapToServer() {
    return {
      idCliente: this.row ? this.row.idCliente : null,
      codiceCliente: this.codiceCliente,
      ragioneSociale: this.ragioneSociale,
      partitaIva: this.partitaIVA,
      sitoWeb: this.sitoWeb,
      email: this.emailCliente,
      telefono: this.telefono,
      fax: this.fax,
      indirizzo: this.indirizzo,
      cap: this.cap,
      fkIdComune: this.comune ? this.comune.idComune : null,
      percentualeSconto: this.percentualeSconto,
      Comune: null,
      Offerta_List: this.row ? this.row.offerta_List : null,
      ReferenteCliente_List: this.row ? this.row.referenteCliente_List : null,
      Richiesta_List: this.row ? this.row.richiesta_List : null,
    };
  }

  onSubmit() {
    let params = this.mapToServer();

    const p = new CrudNetUpdateRequest<ClienteModel>();
    let obj = new Observable<any>();

    p.entity = params;
    if (this.viewMode === CrudNetViewMode.CREATE) {
      obj = this.service.add(p);
    } else if (this.viewMode === CrudNetViewMode.EDIT) {
      obj = this.service.update(p);
    } else if (this.viewMode === CrudNetViewMode.DELETE) {
      obj = this.service.del(p);
    }
    obj.subscribe((res) => {
      if (res.output && res.output.ERRORMESSAGE) {
        this.error.emit(res.output.ERRORMESSAGE);
        this.modalService.showError(res.output.ERRORMESSAGE);
      } else {
        this.success.emit("OK");
        this.close.emit();
      }
    });
  }

  tipoComuneDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "Comune").pipe(
      map((res) => {
        const sortedArray = this.taalService.sortCounterObj(
          res.result,
          (obj) => {
            const val = obj["descrizione"].toString().toLowerCase();
            return val == typedValue
              ? 0
              : val.indexOf(typedValue) == 0
              ? 1
              : val.indexOf(typedValue) > 0
              ? 2
              : 3;
          }
        );
        return sortedArray;
      })
    );
  };
}
