import { Component, OnInit } from "@angular/core";
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType,
} from "utils";
import { CrudNetViewMode } from "crudnet-amgular";
import { CustomDateRendererComponent } from "src/app/components/custom-date-renderer/custom-date-renderer.component";
import { RichiesteInLavorazioneService } from "./richieste-in-lavorazione.service";
import { RichiesteService } from "../richieste/richieste.service";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Observable, of } from "rxjs";
import { RichiesteInLavorazioneCustomCrudComponent } from "./richieste-in-lavorazione-custom-crud/richieste-in-lavorazione-custom-crud.component";
import { ArchivioCandidatiService } from "../archivio-candidati/archivio-candidati.service";

@Component({
  selector: "app-richieste-in-lavorazione",
  templateUrl: "./richieste-in-lavorazione.component.html",
  styleUrls: ["./richieste-in-lavorazione.component.css"],
})
export class RichiesteInLavorazioneComponent implements OnInit {
  customCRUD: any;
  viewMode: CrudNetViewMode;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  constructor(
    public richiesteInLavorazioneService: RichiesteInLavorazioneService,
    public richiesteService: RichiesteService
  ) {
    //this.getSkillList();
    this.getComuneList();

    this.viewMode = CrudNetViewMode.VIEW;
    this.customCRUD = RichiesteInLavorazioneCustomCrudComponent;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    let columns = [];

    const column1 = new TableServerColumn();
    column1.label = "DATA_RICHIESTA";
    column1.data = "dataCreazione";
    column1.type = TableServerColumnType.COLUMN_CUSTOM;
    column1.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.dataCreazione,
        format: "DD/MM/YYYY",
      };
      return render;
    };
    columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = "CLIENTE";
    column2.data = "Cliente.ragioneSociale";
    column2.type = TableServerColumnType.COLUMN_TEXT;
    columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = "SEDE_COMPETENZA";
    column3.data = "Sede.descrizione";
    column3.type = TableServerColumnType.COLUMN_TEXT;
    columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = "HR INCARICATA";
    column4.data = "Impiegato.Anagrafica.nomeCognome";
    column4.type = TableServerColumnType.COLUMN_TEXT;
    columns.push(column4);

    const column5 = new TableServerColumn();
    column5.label = "PROFILO";
    column5.data = "TipoProfiloLavorativo.descrizione";
    column5.type = TableServerColumnType.COLUMN_TEXT;
    columns.push(column5);

    const column6 = new TableServerColumn();
    column6.label = "SKILLS";
    column6.data = "RichiestaSkill_List.descrizione";
    column6.type = TableServerColumnType.COLUMN_TEXT;
    columns.push(column6);

    this.tableConfig.columns = columns;

    let filters = [];

    //FILTER
    const filter1 = new TableServerFilterItem();
    filter1.label = "Data Richiesta";
    filter1.type = TableServerFilterType.FILTER_DATE;
    filter1.data = "dataCreazione";
    filters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = "Cliente";
    filter2.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter2.data = "fkIdCliente";
    filter2.options = this.richiesteService.clienteDataSet;
    filters.push(filter2);

    const filter3 = new TableServerFilterItem();
    filter3.label = "Sede Competenza";
    filter3.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter3.data = "fkIdSedeCompetenza";
    filter3.options = this.richiesteService.sedeDataSet;
    filters.push(filter3);

    const filter4 = new TableServerFilterItem();
    filter4.label = "Priorità";
    filter4.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter4.data = "fkIdTipoPriorita";
    filter4.options = this.richiesteService.tipoPrioritaDataSet;
    filters.push(filter4);

    const filter5 = new TableServerFilterItem();
    filter5.label = "CODICE_RIF_INTERNO";
    filter5.type = TableServerFilterType.FILTER_TEXT;
    filter5.data = "codiceRiferimento";
    filters.push(filter5);

    const filter6 = new TableServerFilterItem();
    filter6.label = "Profilo";

    this.tableFilters = filters;
  }

  ngOnInit() {}

  getSkillList() {
    this.richiesteService.getSkillList().subscribe((res) => {
      let skillList = this.tableFilters.filter((el) => {
        return el.data === "RichiestaSkill_List";
      });
      skillList.map((skill) => {
        console.log(res.result);
        return (skill.options = res.result);
      });
    });
  }

  getComuneList() {
    this.richiesteService.getComuneList().subscribe((res) => {
      let comuniList = this.tableFilters.filter((el) => {
        return el.data === "fkIdProvincia";
      });
      comuniList.map((comune) => {
        console.log(res.result);
        return (comune.options = res.result);
      });
    });
  }
}
