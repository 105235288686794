import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { RealtimeRepo } from 'src/app/repos/RealTimeRepo';
import { ModalService } from 'utils';
import { ChatMessage } from './chat.model';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    initialized = false;


    /*onNotificationReceived =  new Subject<any>();
    onSentMessageReaded =  new Subject<any>();
    onReceivedMessageReaded = new Subject<any>();
    sendAdminNotification= (messageText:string,selectedImpiegato:number[])=> this.realTimeService.emit('sendNotification',{TESTO:messageText,ARRAYDESTINATARI:selectedImpiegato.join(',')});
    sendNotificationReaded = (id:number) => this.realTimeService.emit("readNotification",{IDLOGINVIOMESSAGGIO :id});
    readSentMessage = (page:number,stato:string) => this.realTimeService.emit("readNotificationSent",{PAGE :page,STATO:stato});
    readReceivedMessage = (page:number,stato:string) => this.realTimeService.emit("readNotificationReceived",{PAGE :page,STATO:stato});*/

    onConnectionChange = new Subject<boolean>();
    connected = false;

    sendMessage = (messageText: string, selectedImpiegato: number) => this.realTimeService.emit('sendChatMessage', { TESTO: messageText, DESTINATARIO: selectedImpiegato });
    onMessageReceived = new Subject<any>();


    constructor(private realTimeService: RealtimeRepo, private modalService: ModalService) {
        
        this.realTimeService.onConnectionChange.subscribe((connected) => {
            this.onConnectionChange.next(connected);
            this.connected = connected;
        });
        
        this.realTimeService.tryConnect();

        this.realTimeService.on('sendChatMessageSuccess').subscribe((p) => {
            this.modalService.showSuccess(p);
        });
        this.realTimeService.on('sendChatMessageError').subscribe((p) => {
            this.modalService.showError(p);
        });

        this.realTimeService.on("chatMessageReceived").subscribe(ps => {
            if (ps) {
                const m:ChatMessage={
                    from:ps[1],
                    to:'ME',
                    idFrom:ps[0],
                    time:moment().format('DD/MM/YYYY HH:mm'),
                    messageText:ps[2]
                };
                this.onMessageReceived.next(ps);
                if(Notification.permission=='granted'){
                new Notification(m.time + " - Nuovo messaggio da:"+m.idFrom,{
                    body:m.messageText,
                    icon: "/assets/img/taal/logo.png"
                })
                }
            }
        });


    }



}
