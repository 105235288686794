import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {isNullOrUndefined} from 'util';

/**
 * Component for Textinput in crudnet form
 */
@Component({
  selector: 'cn-field-text',
  templateUrl: './crud-net-field-text.component.html',
  styleUrls: ['./crud-net-field-text.component.css']
})
export class CrudNetFieldTextComponent implements OnInit {


  @Input() label:string;
  @Input() id:string;
  @Input() required:boolean;
  @Input() value:string;
  @Input() precision:number;
  @Input() readonly:boolean;
  @Input() show:boolean;

  @Output() valueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    if (isNullOrUndefined(this.precision)) {
      this.precision = -1;
    }
  }

}
