import { Injectable } from "@angular/core";
import {
  CrudNetResultResponse,
  CrudNetSearchRequest,
  transformFiltersToServer,
  transformServerFilterToTableFilter,
  TypeaheadSearch,
} from "crudnet-amgular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BaseRepo } from "src/app/repos/BaseRepo";
import {
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterType,
} from "utils";
import { RichiestaModel } from "../richieste/richieste.model";

@Injectable({
  providedIn: "root",
})
export class RichiesteInLavorazioneService extends BaseRepo<RichiestaModel> {
  getTable() {
    return "Richiesta";
  }

  search(
    params: CrudNetSearchRequest<RichiestaModel>,
    customTable?: any
  ): Observable<CrudNetResultResponse<RichiestaModel>> {
    if (!customTable) {
      let paramFilter = transformServerFilterToTableFilter(params.filter);
      let filter = new TableServerFilter();

      filter.data = "fkIdStatoRichiesta";
      filter.value = 4;
      filter.condition = TableServerFilterCondition.NOT_EQ;
      filter.type = TableServerFilterType.FILTER_LIST;

      paramFilter.push(filter);

      let filter2 = new TableServerFilter();

      filter2.data = "fkIdStatoRichiesta";
      filter2.value = 3;
      filter2.condition = TableServerFilterCondition.NOT_EQ;
      filter2.type = TableServerFilterType.FILTER_LIST;

      paramFilter.push(filter2);

      params.filter = transformFiltersToServer(paramFilter);

      params.includes = [
        "Cliente",
        "TipologiaLavoro",
        "TipoSeniority",
        "TipoSettoreLavorativo",
        "Sede.Comune",
        "StatoRichiesta",
        "TipoPriorita",
        "ReferenteCliente",
        "TipoSettoreLavorativo",
        "TipoProfiloLavorativo",
        "Impiegato.Anagrafica",
        "Comune.Provincia",
        "RichiestaSkill_List.Skill",
        "RichiestaLingua_List.Lingua",
      ];
      return super.search2(params).pipe(
        map((res) => {
          res.result = res.result.map((richiesta) => {
            richiesta.Impiegato.Anagrafica["nomeCognome"] =
              richiesta.Impiegato.Anagrafica.nome +
              " " +
              richiesta.Impiegato.Anagrafica.cognome;
            return richiesta;
          });
          return res;
        })
      );
    }
    if (customTable === "Skill") {
      return super.search(params, customTable).pipe(
        map((res) => {
          res.result = res.result.map((skill) => {
            return {
              ...skill,
              realValue: skill.idSkill + "-" + skill.fkIdAmbitoSkill,
            };
          });
          return res;
        })
      );
    }
    return super.search(params, customTable);
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map((res) => {
        res.result.columns = res.result.columns
          .filter(
            (c) =>
              c.name !== "idAnagrafica" &&
              c.name !== "fkIdImpiegatoRichiedente" &&
              c.name !== "titolo" &&
              c.name !== "fkIdSettoreLavorativo" &&
              c.name !== "dataCreazione" &&
              c.name !== "numeroRisorse" &&
              c.name !== "fkIdStatoRichiesta"
          )
          .map((c) => {
            if (c.name === "fkIdSedeCompetenza") {
              c.realName = "fkIdSede";
            }
            if (c.name === "fkIdComuneSedeLavorativa") {
              c.realName = "fkIdComune";
            }
            if (c.name === "fkIdImpiegatoRichiedente") {
              c.realName = "fkIdImpiegato";
            }

            if (c.name === "visibilitaMesi") {
              c.hideMe = (parsedForm, orginalForm, externalData) => {
                return parsedForm.flagContinuativa;
              };
            }
            return c;
          })
          .concat([
            {
              maxLen: null,
              name: "fkIdLingua",
              precision: null,
              required: true,
              scale: null,
              type: "ICollection",
            },
            {
              maxLen: null,
              name: "fkIdSkill",
              precision: null,
              required: true,
              scale: null,
              type: "ICollection",
            },
            {
              maxLen: null,
              name: "fkIdTipoSettoreLavorativo",
              precision: null,
              required: true,
              scale: null,
              type: "Int32",
            },
            {
              maxLen: null,
              name: "flagContinuativa",
              precision: null,
              required: false,
              scale: null,
              type: "Boolean",
            },
          ]);
        return res;
      })
    );
  }
}
