import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  faEdit,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CrudNetViewMode } from "crudnet-amgular";
import { ImportCostiPersonaleService } from "../import-costi-personale.service";

@Component({
  selector: "app-import-costi-personale-crud",
  templateUrl: "./import-costi-personale-crud.component.html",
  styleUrls: ["./import-costi-personale-crud.component.css"],
})
export class ImportCostiPersonaleCrudComponent implements OnInit, OnChanges {
  @Input() viewMode: CrudNetViewMode;

  /**
   * custom submit method
   */
  @Input() submit?: Function;

  /**
   * the current row of the table useful for custom components
   */
  @Input() row: any;
  /*  set in(value) {
    this.nome = value.Impiegato.Anagrafica.nome;
    this.cognome = value.Impiegato.Anagrafica.cognome;
    this.anno = value.anno;
    this.mese = value.mese;
    this.matricola = value.Impiegato.matricola;
    this.importo = value.importo;
  }*/

  /**
   * delete function
   */
  @Input() deleteFn?: Function;
  @Input() updateFn?: Function;
  @Input() createFn?: Function;

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;
  currentIcon = faPlus;
  closeIcon = faTimes;

  nome: string;
  cognome: string;
  anno: number;
  mese: number;
  matricola: string;
  importo: number;
  selectedImpiegato: any;

  constructor(
    public importCostiPersonaleService: ImportCostiPersonaleService
  ) {}

  ngOnInit() {
    this.currentIcon = this.getIcon();
    if (this.row) {
      this.nome = this.row.Impiegato.Anagrafica.nome;
      this.cognome = this.row.Impiegato.Anagrafica.cognome;
      this.anno = this.row.anno;
      this.mese = this.row.mese;
      this.matricola = this.row.Impiegato.matricola;
      this.importo = this.row.importo;
      this.selectedImpiegato = {
        idImpiegato: this.row.Impiegato.idImpiegato,
        nome: this.row.Impiegato.Anagrafica.nome,
        cognome: this.row.Impiegato.Anagrafica.cognome,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  onImpiegatoChange(imp) {
    this.selectedImpiegato = {
      nome: imp.nome,
      cognome: imp.cognome,
      idImpiegato: imp.idImpiegato,
    };
  }

  onSubmit(isNew?) {
    if (isNew) {
      const par = {
        MATRICOLA: null,
        RAGIONESOCIALE: null,
        FILIALE: null,
        IDIMPIEGATO: this.selectedImpiegato.idImpiegato,
        IDFILEIMPORTCOSTI: null,
        ANNO: this.anno,
        MESE: this.mese,
        IMPORTO: this.importo,
      };

      this.createFn(par);
      this.closeModal();
      this.success.emit();
    } else {
      const par = {
        idImpiegato: this.row.fkIdImpiegato,
        anno: this.anno,
        mese: this.mese,
        importo: this.importo,
      };
      this.updateFn(par);
      this.closeModal();
      this.success.emit();
    }
  }

  onDelete() {
    this.deleteFn(this.row);
    this.closeModal();
    this.success.emit();
  }

  impiegatoDisplayValue = (imp) => {
    if (imp) {
      return imp.nome + " " + imp.cognome;
    }
  };
}
