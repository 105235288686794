import { Component, OnInit } from '@angular/core';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CrudNetViewMode } from 'crudnet-amgular';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableServerActionIntent, TableServerColumn, TableServerConfig, TableServerFilterItem } from 'utils';
import { ArchivioCandidatiService } from '../archivio-candidati.service';

@Component({
  selector: 'app-archivio-candidati-log-azioni-viewer',
  templateUrl: './archivio-candidati-log-azioni-viewer.component.html',
  styleUrls: ['./archivio-candidati-log-azioni-viewer.component.css']
})
export class ArchivioCandidatiLogAzioniViewerComponent implements OnInit {

  currentIcon = faSearch;
  closeIcon = faTimes;

  viewMode: CrudNetViewMode;
  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]>;
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];

  constructor(public service: ArchivioCandidatiService) {

    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = 'Data Azione';
    column1.data = 'dataAzione';
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = 'Tipo Azione';
    column2.data = 'tipoAzione';
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = 'Esito';
    column3.data = 'esito';
    this.tableConfig.columns.push(column3);

    const column4 = new TableServerColumn();
    column4.label = 'Note';
    column4.data = 'note';
    this.tableConfig.columns.push(column4);

   }

  ngOnInit() {
  }

  onLogAzioniPickerSelected(data) {
    this.tableRow = this.service.getDettaglioLogRichiesta(data.fkIdCandidato, data.fkIdRichiesta).pipe(
      map((res)=>{
        this.tableConfig.totalItems = res.result.length;
        return res.result.map((log)=>({
          dataAzione: this.getFormattedDate(log.DATACREAZIONE),
          tipoAzione: log.DESCTIPOAZIONE,
          note: log.NOTE ? log.NOTE : "-",
          esito: log.ESITO ? log.ESITO : "-"
        }));
      })
    );
  }

  getFormattedDate(date) {
    let momentVariable = moment(date);
    return momentVariable.format("DD/MM/YYYY");
  }

}
