import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TypeaheadSearch } from '../models/CrudNetFormField';
import {get} from 'lodash';
import {I18NextPipe} from 'angular-i18next';


@Component({
  selector: 'cn-field-autocomplete-selectfirst',
  templateUrl: './crud-net-field-autocomplete-selectfirst.component.html',
  styleUrls: ['./crud-net-field-autocomplete-selectfirst.component.css']
})
export class CrudNetFieldAutocompleteSelectfirstComponent implements OnInit {

  @Input() displayValue?: string;
  @Input() id: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() readonly: boolean;
  @Input() show: boolean;
  @Input() options: TypeaheadSearch;
  @Input() idFirstResultSelectable: boolean;

  dataSource: Observable<Array<any>>;
  showValue: string;
  typeaheadLoading = false;

  @Output() valueChange = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();

  constructor(private translatePipe: I18NextPipe) {
    this.dataSource = new Observable((observer: Subscriber<string>) => {
      // Runs on every search
      observer.next(this.showValue);
    })
    .pipe(
      mergeMap((token: string) => this.options(token)),
      map((els) => {
        if (!els || els.length < 1) {
          return [{
            descrizione: this.translatePipe.transform('CREARE_NUOVA_OPZIONE') + ': "' + this.showValue + '" ?',
            idComune: this.showValue,
            }];
        }
        return els;
        })
    );
  }

  ngOnInit() {
    if (!this.displayValue || !this.displayValue.length) {
      this.displayValue = 'descrizione';
    }
    if (this.value) {
      this.showValue = get(this.value, this.displayValue);
    }
  }

  typeaheadOnBlur() {
    if (this.showValue && this.showValue.length) {
      if (this.value) {
        this.showValue = get(this.value, this.displayValue);
      } else {
        this.showValue = '';
      }
    } else {
      this.valueChange.emit(null);
      this.change.emit();
    }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  onFocus() {
    // this.showValue='';
  }

  clear() {
    this.showValue = '';
    this.valueChange.emit(null);
    this.change.emit();
  }

  onSelect(item) {
    this.valueChange.emit(item);
    this.change.emit(item);

  }

  isReadOnly(): boolean {
    const elm = document.getElementById(this.id);
    return elm ? (elm.hasAttribute('readonly') || elm.hasAttribute('disabled')) : false;
  }

}
