import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree
} from '@angular/router';
import { RapportinoComponent } from '../modules/rapportino/rapportino.component';
import { defer, from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RapportinoGuard implements CanDeactivate<RapportinoComponent> {

    canDeactivate(component: RapportinoComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return component.validateSavedData();
    }

}
