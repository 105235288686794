import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-boolean-renderer',
  templateUrl: './custom-boolean-renderer.component.html',
  styleUrls: ['./custom-boolean-renderer.component.css']
})
export class CustomBooleanRendererComponent implements OnInit {
  @Input() boolean?: boolean;
  iconToRender: boolean;

  constructor() { }

  ngOnInit() {
    this.iconToRender = this.boolean ? true : false;
  }

}
