import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  CrudNetSearchRequest,
  CrudNetViewMode,
  transformFiltersToServer,
  transformOrdersToServer,
} from 'crudnet-amgular';
import {
  ModalService,
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilter,
  TableServerFilterItem,
  TableServerFilterMode,
  TableServerFilterType,
  TableServerSearch,
} from 'utils';
import { Observable, of } from 'rxjs';
import { BudgetCustomCrudComponent } from './budget-custom-crud/budget-custom-crud.component';
import { BudgetService } from './budget.service';
import { AuthService } from '../../auth/auth.service';
import { map, tap } from 'rxjs/operators';
import { CustomStringRendererComponent } from '../../components/custom-string-renderer/custom-string-renderer.component';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss'],
})
export class BudgetComponent implements OnInit {
  viewMode: CrudNetViewMode;
  loadForm = false;
  customCrud = BudgetCustomCrudComponent;
  filterModes = TableServerFilterMode;
  currentFilters: TableServerSearch;
  modalOption;
  showTableFilter = false;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW, TableServerActionIntent.EDIT];
  tableFilters: TableServerFilterItem[] = [];
  defaultFilters: TableServerFilter[] = [];
  idModal = 'budgetModal';

  constructor(
    public budgetService: BudgetService,
    public authService: AuthService,
    private modalService: ModalService
  ) {
    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = 'anno';
    column1.data = 'ANNO';

    const column2 = new TableServerColumn();
    column2.label = 'TOTALE_RICAVI';
    column2.data = 'TOTRICAVIPREV';
    column2.type = TableServerColumnType.COLUMN_NUMBER;

    const column3 = new TableServerColumn();
    column3.label = 'TOTALE_COSTI';
    column3.data = '';
    column3.type = TableServerColumnType.COLUMN_CUSTOM;
    column3.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        // TODO add notacredito
        stringToRender: (
          row.TOTCOSTIGENPREV +
          row.TOTCOSTISTRUTPREV +
          row.TOTCOSTIPERSPREV +
          row.NOTACREDITOPREV
        ).toLocaleString(),
      };
      return render;
    };

    const column4 = new TableServerColumn();
    column4.label = 'UTILE';
    column4.data = '';
    column4.type = TableServerColumnType.COLUMN_CUSTOM;
    column4.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        // TODO add notacredito
        stringToRender: (
          row.TOTRICAVIPREV -
          (row.TOTCOSTIGENPREV +
            row.TOTCOSTISTRUTPREV +
            row.TOTCOSTIPERSPREV +
            row.NOTACREDITOPREV)
        ).toLocaleString(),
      };
      return render;
    };

    const column5 = new TableServerColumn();
    column5.label = 'PERCENT_COSTO_STRUTTURA';
    column5.data = 'PERCCOSTISTRUTTURAPREV';
    column5.type = TableServerColumnType.COLUMN_NUMBER;

    const column6 = new TableServerColumn();
    column6.label = 'PERCENT_COSTO_GENERALE';
    column6.data = 'PERCCOSTIGENERALIPREV';
    column6.type = TableServerColumnType.COLUMN_NUMBER;

    const caction = new TableServerColumn();
    caction.type = TableServerColumnType.COLUMN_ACTION;
    caction.sortable = false;
    caction.label = '';
    caction.data = '';
    caction.actions = this.tableActions;
    caction.render = (row, column, viewMode, closeModal) => {
      const render = new TableServerColumnRender();
      render.component = this.customCrud;
      render.inputs = {
        service: this.budgetService,
        viewMode: this.getViewModeFromTable(viewMode),
        idCurrent: row['id' + this.budgetService.getTable()],
        /*        lookupFilters: this.lookupFilters,
        refFieldMap: this.refFieldMap,
        defaultValues: this.defaultValues,
        submit: this.submit,
        customSubmitParams: this.customSubmitParams,
        relatedFields: this.relatedFields,*/
        /**
         * mandiamo in input sia la row che gli input per i custom component
         */
        row,
      };

      const closeEmitter = new EventEmitter<any>();
      closeEmitter.subscribe(closeModal);

      const successEmitter = new EventEmitter<any>();
      successEmitter.subscribe(() =>
        this.modalService.showSuccess('SUCCESSED_OPERATION')
      );
      render.outputs = {
        close: closeEmitter,
        success: successEmitter,
      };
      return render;
    };

    const filterAnno = new TableServerFilterItem();
    filterAnno.label = 'ANNO';
    filterAnno.type = TableServerFilterType.FILTER_NUMBER;
    filterAnno.data = 'ANNO';

    this.tableConfig.columns = [
      column1,
      column2,
      column3,
      column4,
      column5,
      column6,
      caction,
    ];
    this.tableFilters = [filterAnno];
  }

  ngOnInit() {}

  getViewModeFromTable(viewMode: TableServerActionIntent): CrudNetViewMode {
    if (viewMode === TableServerActionIntent.VIEW) {
      return CrudNetViewMode.VIEW;
    }
    if (viewMode === TableServerActionIntent.EDIT) {
      return CrudNetViewMode.EDIT;
    }
    return CrudNetViewMode.DELETE;
  }

  openCreate() {
    const closeModal = new EventEmitter();
    closeModal.subscribe((create) => {
      if (!create) {
        this.modalService.close(this.idModal);
        this.getData(this.currentFilters);
      }
    });
    const successModal = new EventEmitter<any>();
    successModal.subscribe((res) => {
      this.modalService.close(this.idModal);
      this.getData(this.currentFilters);
      this.modalService.showSuccess('SUCCESSED_OPERATION');
      this.modalOption = {
        ...this.modalOption,
        inputs: {
          ...this.modalOption.inputs,
          idCurrent: res['id' + this.budgetService.getTable()],
          row: {
            ...res,
            IDBUDGET: res.idBudget,
            ANNO: res.anno,
            TOTCOSTISTRUTPREV: res.costoPersonaleStruttura,
            NOTACREDITOPREV: res.notaCredito,
          },
          viewMode: CrudNetViewMode.EDIT,
        },
      };
      this.modalService.open(this.idModal);
    });
    this.modalOption = {
      component: this.customCrud,
      inputs: {
        service: this.budgetService,
        viewMode: CrudNetViewMode.CREATE,
      },
      outputs: {
        close: closeModal,
        success: successModal,
      },
    };
    this.modalService.open(this.idModal);
  }

  getData(param: TableServerSearch) {
    this.currentFilters = param;
    const orders = transformOrdersToServer(param.sort);
    const filters = transformFiltersToServer(param.filters);
    const request: CrudNetSearchRequest<any> = {
      order: orders,
      pageNum: param.currentPage - 1,
      pageSize: param.itemsPerPage,
      filter: filters,
    };
    this.tableRow = this.budgetService.search(request, null).pipe(
      tap((res) => {
        this.tableConfig.totalItems = res.rowCount;
      }),
      map((res) => res.result)
    );
  }
}
