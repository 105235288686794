import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { SocketRepo } from 'utils';
import { TOKEN_LOCAL_STORAGE_KEY } from '../constants';
import { debounce } from 'lodash';

@Injectable({
    providedIn:'root'
})
export class RealtimeRepo extends SocketRepo{

    clientId:string;
    onConnectionChange = new Subject<boolean>();

    tryConnect = debounce(this.tryConnectD.bind(this),500);
    tryDisconnect = debounce(this.tryDisconnectD.bind(this),500);

    constructor(){
        super();

    }

    private tryConnectD(){
        if(this.connected) return this.onConnectionChange.next(this.connected);
        this.connect().then(()=>{
            this.on('loginSuccess').subscribe(clientId=>{
                //catch loginSuccess
                this.clientId=clientId[0];
                this.connected=true;
                this.onConnectionChange.next(this.connected);
            },()=>{
                this.connected=false;
                this.onConnectionChange.next(this.connected);
            });
            this.hubConnection.invoke('login',this.getToken());
        });

    }

    private tryDisconnectD(){
        if(!this.connected) return this.onConnectionChange.next(this.connected);
        this.disconnect();
        this.connected=false;

    }

    getServerPath(): string {
      return environment.BASE_URL_SOCKET;
    }

    getToken(): string {
        return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
     }

    public on(eventName:string):Subject<any>{
        const ret= new Subject<any>();
        //if(this.connected){
            this.addEventListerner(eventName,ret);
            return ret;
        //}
       //return ret;

    }

    public emit(event:string,params?:any){
        super.emit(event,params);
    }

}
