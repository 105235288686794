import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * Component for Passwordinput in crudnet form
 */
@Component({
  selector: 'cn-field-password',
  templateUrl: './crud-net-field-password.component.html',
  styleUrls: ['./crud-net-field-password.component.css']
})
export class CrudNetFieldPasswordComponent implements OnInit {

  @Input() label:string;
  @Input() id:string;
  @Input() required:boolean;
  @Input() value:string;
  @Input() precision:number;
  @Input() readonly:boolean;
  @Input() show:boolean;

  @Output() valueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

}
