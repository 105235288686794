import { Injectable, Injector } from "@angular/core";
import { BaseRepo } from "../../repos/BaseRepo";
import {
  CrudNetExecRequest,
  CrudNetResultResponse,
  CrudNetSearchRequest,
  transformFiltersToServer,
} from "crudnet-amgular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PermessoModel } from "../permesso/permesso.model";
import { findIndex } from "lodash";

@Injectable({
  providedIn: "root",
})
export class AssegnazioniMagazzinoService extends BaseRepo<any> {
  getTable(): string {
    return "AssegnazioneDispositivo";
  }

  getAssegnazioni(filters, order) {
    return this.exec("fn_GetListaImpiegatiDispositivi", {
      par: null,
      order: order,
      pageNum: 0,
      pageSize: -1,
      filter: filters ? transformFiltersToServer(filters) : null,
    });
  }

  getDispositiviNonAssegnati(payload, filters, order) {
    return this.exec("fn_GetListaDispositivi", {
      par: payload,
      order: order,
      pageNum: 0,
      pageSize: -1,
      filter: filters ? transformFiltersToServer(filters) : null,
    });
  }

  getDispositiviImpiegato(payload) {
    return this.exec("fn_GetDispositiviImpiegato", {
      par: payload,
      order: [],
      pageNum: 0,
      pageSize: -1,
    });
  }
}
