import { CrudNetFilterExpression } from "crudnet-amgular";
import { ArchivioCandidatiService } from "./archivio-candidati.service";
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType,
  ModalService,
  TableServerFilter,
  TableServerFilterCondition,
  TableServerColumnSortDirection,
  TableServerCheckSelection,
  TableServerSearch,
  TableServerFlterListOption,
  TableServerFilterValue,
  TableServerFilterMode,
} from "utils";

import {
  CrudNetViewMode,
  CrudnetHeaderButton,
  CrudNetSearchRequest,
  TypeaheadSearch,
  transformFiltersToServer,
  CrudNetUpdateRequest,
} from "crudnet-amgular";
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ArchivioCandidatiCustomCrudComponent } from "./archivio-candidati-custom-crud/archivio-candidati-custom-crud.component";
import { forkJoin, Observable, of } from "rxjs";
import { CustomDateRendererComponent } from "src/app/components/custom-date-renderer/custom-date-renderer.component";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { map } from "rxjs/operators";
import * as moment from "moment";
import { CrudNetTableComponent } from "projects/crudnet-amgular/src/lib/crud-net-table/crud-net-table.component";

@Component({
  selector: "app-archivio-candidati",
  templateUrl: "./archivio-candidati.component.html",
  styleUrls: ["./archivio-candidati.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ArchivioCandidatiComponent implements OnInit {
  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("cnTableI", { static: false })
  crudNetTableComponentReference: CrudNetTableComponent;

  idTable = "idTable";

  viewModes = CrudNetViewMode;
  viewMode: CrudNetViewMode;

  customCRUD: any;

  currentIcon = faPlus;
  closeIcon = faTimes;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.DELETE, TableServerActionIntent.EDIT];
  tableFilters: TableServerFilterItem[] = [];
  filtersMode = TableServerFilterMode;

  idAssegnazioneRichiesta = "idModal";
  dataAssegnazione = new Date();
  addedButton: CrudnetHeaderButton[] = [
    {
      text: "Assegna a Richiesta",
      action: this.assegnaRichiesta.bind(this),
    },
  ];

  selectedDataCheckbox: any[] = [];
  columnSelection: TableServerColumn = {
    label: "",
    sortable: false,
    sortDirection: TableServerColumnSortDirection.NO_SORT,
    data: "selection",
    hideSelectAll: true,
    type: TableServerColumnType.COLUMN_CHECKBOX,
    onCheck: (selectedData: TableServerCheckSelection) => {
      this.selectedDataCheckbox = selectedData.data.map((el) => el.IDCANDIDATO);
      this.disableButton(this.idTable);
    },
  };

  assegnazioneRichiesta = null;
  selectRichiesta: any[] = [];

  constructor(
    public archivioCandidatiService: ArchivioCandidatiService,
    private modalService: ModalService
  ) {
    this.getSkillList();
    this.getSediList();
    this.getSeniorityList();
    this.getTipoContrattoList();

    this.viewMode = CrudNetViewMode.VIEW;
    this.customCRUD = ArchivioCandidatiCustomCrudComponent;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    let columns = [];

    columns.push(this.columnSelection);

    const columnNome = new TableServerColumn();
    columnNome.label = "NOME";
    columnNome.data = "NOME";
    columns.push(columnNome);

    const columnCognome = new TableServerColumn();
    columnCognome.label = "COGNOME";
    columnCognome.data = "COGNOME";
    columns.push(columnCognome);

    const columnTipologia = new TableServerColumn();
    columnTipologia.label = "TIPOLOGIA";
    columnTipologia.data = "DESCRIZIONETIPOPROFILOLAVORATIVO";
    columns.push(columnTipologia);

    const columnSkills = new TableServerColumn();
    columnSkills.label = "SKILL";
    columnSkills.data = "DESCSKILLS";
    columns.push(columnSkills);

    const columnUltimaAzione = new TableServerColumn();
    columnUltimaAzione.label = "Ultima Azione";
    columnUltimaAzione.data = "DATALOGAZIONECANDIDATO";
    // columnUltimaAzione.sortDirection = TableServerColumnSortDirection.DESC;
    columnUltimaAzione.type = TableServerColumnType.COLUMN_CUSTOM;
    columnUltimaAzione.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.DATALOGAZIONECANDIDATO,
        format: "DD/MM/YYYY",
      };
      return render;
    };

    /* const columnUltimaAzione = new TableServerColumn();
    columnUltimaAzione.label = "Ultima Azione";
    columnUltimaAzione.data = "LogAzioneCandidato_List.dataCreazione"; */
    columns.push(columnUltimaAzione);

    //Add all columns at the same time to avoid multiple renderings of the table
    this.tableConfig.columns = columns;

    let tableFilters = [];

    const filterNome = new TableServerFilterItem();
    filterNome.label = "Nome";
    filterNome.type = TableServerFilterType.FILTER_TEXT;
    filterNome.data = "NOME";
    tableFilters.push(filterNome);

    const filterCognome = new TableServerFilterItem();
    filterCognome.label = "Cognome";
    filterCognome.type = TableServerFilterType.FILTER_TEXT;
    filterCognome.data = "COGNOME";
    tableFilters.push(filterCognome);

    const filterSede = new TableServerFilterItem();
    filterSede.label = "Sede valutata";
    filterSede.type = TableServerFilterType.FILTER_TEXT;
    filterSede.data = "SEDIVALUTATE";
    tableFilters.push(filterSede);

    /*
    const filterRalDa = new TableServerFilterItem();
    filterRalDa.label = "RAL Desiderata Da";
    filterRalDa.type = TableServerFilterType.FILTER_NUMBER;
    filterRalDa.data = "AspettoEconomicoCandidato_List.RalDesiderataCandidato.ralDa";
    tableFilters.push(filterRalDa);

    const FilterRalA = new TableServerFilterItem();
    FilterRalA.label = "RAL Desiderata A";
    FilterRalA.type = TableServerFilterType.FILTER_NUMBER;
    FilterRalA.data = "AspettoEconomicoCandidato_List.RalDesiderataCandidato.ralA";
    tableFilters.push(FilterRalA);
    */

    const annoNascita = new TableServerFilterItem();
    annoNascita.label = "Anno nascita";
    annoNascita.type = TableServerFilterType.FILTER_YEAR;
    annoNascita.data = "ANNONASCITA";
    tableFilters.push(annoNascita);

    const filterProfilo = new TableServerFilterItem();
    filterProfilo.label = "Profilo";
    filterProfilo.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filterProfilo.options =
      this.archivioCandidatiService.setTpoProfiloLavorativoDataset;
    filterProfilo.data = "CODICETIPOPROFILOLAVORATIVO";
    tableFilters.push(filterProfilo);

    const filterTipologiaProfilo = new TableServerFilterItem();
    filterTipologiaProfilo.label = "Tipologia profilo";
    filterTipologiaProfilo.type = TableServerFilterType.FILTER_TEXT;
    filterTipologiaProfilo.data = "TIPOLOGIAPROFILO";
    tableFilters.push(filterTipologiaProfilo);

    const filterSeniority = new TableServerFilterItem();
    filterSeniority.label = "Seniority";
    filterSeniority.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filterSeniority.options = this.archivioCandidatiService.setSeniorityDataset;
    filterSeniority.data = "IDTIPOSENIORITY";
    tableFilters.push(filterSeniority);

    const filterContratto = new TableServerFilterItem();
    filterContratto.label = "Tipo contratto";
    filterContratto.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filterContratto.options =
      this.archivioCandidatiService.setTpoContrattoLavoroDataset;
    filterContratto.data = "CODICETIPOCONTRATTOLAVORO";
    tableFilters.push(filterContratto);

    const filterTipoUltimaAzione = new TableServerFilterItem();
    filterTipoUltimaAzione.label = "Tipo ultima azione";
    filterTipoUltimaAzione.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filterTipoUltimaAzione.data = "CODICETIPOAZIONERECRUITER";
    filterTipoUltimaAzione.options =
      this.archivioCandidatiService.setTpoAzioneRecruiterDataset;
    tableFilters.push(filterTipoUltimaAzione);

    /*
    const filterBlacklist = new TableServerFilterItem();
    filterBlacklist.label = "Blacklisted";
    filterBlacklist.type = TableServerFilterType.FILTER_LIST;
    filterBlacklist.data = "flagBlacklist";
    let optionTrue = new TableServerFlterListOption();
    optionTrue.label = "True";
    optionTrue.value = true;
    filterBlacklist.options = [optionTrue];
    tableFilters.push(filterBlacklist);
    */

    const filterBlacklist = new TableServerFilterItem();
    filterBlacklist.data = "FLAGBLACKLIST";
    filterBlacklist.type = TableServerFilterType.FILTER_BOOLEAN;
    filterBlacklist.label = "Blacklistato";
    tableFilters.push(filterBlacklist);

    const filterTransfer = new TableServerFilterItem();
    filterTransfer.data = "FLAGDISPONIBILETRANSFER";
    filterTransfer.type = TableServerFilterType.FILTER_BOOLEAN;
    filterTransfer.label = "Disposto a trasferirsi";
    tableFilters.push(filterTransfer);

    /*
    const filterTransfer = new TableServerFilterItem();
    filterTransfer.label = "Disposto a trasferirsi";
    filterTransfer.type = TableServerFilterType.FILTER_LIST;
    filterTransfer.data = "flagDisponibileTransfer";
    filterTransfer.options = [optionTrue];
    tableFilters.push(filterTransfer);
    */

    const filterSkill = new TableServerFilterItem();
    filterSkill.label = "Skill";
    filterSkill.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filterSkill.data = "SKILLS";
    filterSkill.options = this.archivioCandidatiService.setSkillDataSet;
    tableFilters.push(filterSkill);

    //Add all columns at the same time to avoid multiple renderings of the table
    this.tableFilters = tableFilters;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.disableButton(this.idTable);
  }

  getSkillList() {
    /* this.archivioCandidatiService.getSkillList().subscribe(
      res => {
        let skillList = this.tableFilters.filter(el => {
          return el.data === "CandidatoSkill_List";
        });
        skillList.map(skill => {
          return skill.options = res.result;
        });
      }); */
  }

  getSediList() {
    this.archivioCandidatiService.getSediList().subscribe((res) => {
      let sediList = this.tableFilters.filter((el) => {
        return el.data === "CandidatoSede_List";
      });
      sediList.map((sedi) => {
        return (sedi.options = res.result);
      });
    });
  }

  getSeniorityList() {
    this.archivioCandidatiService.getSeniorityList().subscribe((res) => {
      let seniorityList = this.tableFilters.filter((el) => {
        return el.data === "TipoSeniority.descrizione";
      });
      let seniorityTypes = res.result.map((type) => {
        let formattedType = {
          value: type.idTipoSeniority,
          label: type.descrizione,
        };
        return formattedType;
      });
      seniorityList.map((seniority) => {
        return (seniority.options = seniorityTypes);
      });
    });
  }

  getTipoContrattoList() {
    this.archivioCandidatiService.getTipoContrattoList().subscribe((res) => {
      let tipoContrattoList = this.tableFilters.filter((el) => {
        return el.data === "TipoContratto.descrizione";
      });
      let seniorityTypes = res.result.map((type) => {
        let formattedType = {
          value: type.idTipoSeniority,
          label: type.descrizione,
        };
        return formattedType;
      });
      tipoContrattoList.map((seniority) => {
        return (seniority.options = seniorityTypes);
      });
    });
  }

  assegnaRichiesta() {
    this.modalService.open(this.idAssegnazioneRichiesta);
  }

  extractTecnologiesFromRequest(request) {
    let ret = "";
    request.RichiestaSkill_List.forEach((skill) => {
      if (skill.fkIdTipoCompetenzaRichiesta === 1) {
        ret += skill.Skill.descrizione + ", ";
      }
    });
    return ret.substring(0, ret.length - 2);
  }

  getRichieste: TypeaheadSearch = (typedValue) => {
    let filter: CrudNetFilterExpression = null;

    let onlyActiveRequestsFilter = new TableServerFilter();
    onlyActiveRequestsFilter.type = TableServerFilterType.FILTER_NUMBER;
    onlyActiveRequestsFilter.data = "fkIdStatoRichiesta";
    onlyActiveRequestsFilter.condition = TableServerFilterCondition.EQ;
    onlyActiveRequestsFilter.value = 1;
    filter = transformFiltersToServer([onlyActiveRequestsFilter]);

    if (typedValue && typedValue.length) {
      let tableServerFilter = new TableServerFilter();
      tableServerFilter.type = TableServerFilterType.FILTER_TEXT;
      tableServerFilter.data = "Cliente.codiceCliente";
      tableServerFilter.condition = TableServerFilterCondition.LIKE;
      tableServerFilter.value = typedValue;
      let tableServerFilterTP = new TableServerFilter();
      tableServerFilterTP.type = TableServerFilterType.FILTER_TEXT;
      tableServerFilterTP.data = "tipologiaProfilo";
      tableServerFilterTP.condition = TableServerFilterCondition.LIKE;
      tableServerFilterTP.value = typedValue;
      let onlyActiveRequestsFilter = new TableServerFilter();
      onlyActiveRequestsFilter.type = TableServerFilterType.FILTER_NUMBER;
      onlyActiveRequestsFilter.data = "fkIdStatoRichiesta";
      onlyActiveRequestsFilter.condition = TableServerFilterCondition.EQ;
      onlyActiveRequestsFilter.value = 1;
      filter = transformFiltersToServer([
        tableServerFilter,
        tableServerFilterTP,
        onlyActiveRequestsFilter,
      ]);
      let expression = filter.expression.split("&&");
      filter.expression =
        "(" + expression[0] + "||" + expression[1] + ") &&" + expression[2];
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: [
        "Cliente",
        "RichiestaSkill_List.Skill",
        "TipoProfiloLavorativo",
      ],
      pageSize: -1,
      filter,
    };
    return this.archivioCandidatiService
      .searchLookup(searchRequest, "Richiesta")
      .pipe(
        map((res) => {
          return res.result.map((server) => {
            this.selectRichiesta = server.idRichiesta;
            if (server.codiceRiferimento.indexOf("RICGEN") >= 0) {
              return { ...server, label: server.titolo };
            } else {
              return {
                ...server,
                label:
                  server.Cliente.codiceCliente +
                  " - Richiesta " +
                  (server.TipoProfiloLavorativo
                    ? server.TipoProfiloLavorativo.descrizione
                    : "") +
                  " del " +
                  moment(server.dataCreazione).format("DD/MM/YYYY") +
                  " - Tecnologie: " +
                  this.extractTecnologiesFromRequest(server),
              };
            }
          });
        })
      );
  };

  onSubmit() {
    /*
    let data: any[] = this.selectedDataCheckbox.map(elem => {
      let obj = {
        idCandidato: elem,
        idRichiesta: this.assegnazioneRichiesta.idRichiesta,
        note: "",
      }
      return obj;
    });
    */
    let requests = [];

    this.selectedDataCheckbox.forEach((element) => {
      let date = new Date(this.dataAssegnazione.toISOString());
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset() * -1);

      let entity = {
        idCandidato: element,
        idRichiesta: this.assegnazioneRichiesta.idRichiesta,
        note: "",
        datacreazione: date,
      };
      let obj = {
        entity: entity,
      };

      requests.push(this.archivioCandidatiService.saveRequest(obj));
    });

    forkJoin(requests).subscribe((responses) => {
      let errors = false;

      responses.forEach((res) => {
        if (res.output && res.output.ERRORMESSAGE) {
          this.error.emit(res.output.ERRORMESSAGE);
          this.modalService.showError(res.output.ERRORMESSAGE);
          errors = true;
        }
      });

      if (!errors) {
        this.success.emit("OK");
        this.modalService.showSuccess("Richiesta assegnata con successo");
        this.modalService.close(this.idAssegnazioneRichiesta);
        this.crudNetTableComponentReference.tableInstance.deselectAll(
          this.columnSelection
        );
        this.close.emit();
      }
    });
  }

  disableButton(idTable: string) {
    let element = document.querySelector(
      "#" + idTable + " > div > div:nth-child(1) > div > button"
    );
    if (this.selectedDataCheckbox.length < 1) {
      element.setAttribute("disabled", "disabled");
    } else {
      element.removeAttribute("disabled");
    }
  }

  closeModal() {
    this.modalService.close(this.idAssegnazioneRichiesta);
  }
}
