import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import {
  BsDatepickerConfig,
  BsDatepickerViewMode,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import { Observable, of } from 'rxjs';
import {
  CrudNetExecRequest,
  CrudNetExecResponse,
  CrudNetViewMode,
  transformFiltersToServer,
  transformOrdersToServer,
} from 'crudnet-amgular';
import {
  faBan,
  faFilter,
  faLockOpen,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { RapportinoService } from '../rapportino/rapportino.service';
import { map } from 'rxjs/operators';
import { CrudNetFilterExpression } from 'projects/crudnet-amgular/src/public-api';
import {
  ModalService,
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterMode,
  TableServerFilterType,
  TableServerSearch,
} from 'utils';
import { RapportinoComponent } from '../rapportino/rapportino.component';
import * as moment from 'moment';
// tslint:disable-next-line:max-line-length
import { GestioneRapportinoExpandableColumnComponent } from './gestione-rapportino-expandable-column/gestione-rapportino-expandable-column.component';
import { ActionDeconsolidaButtonComponent } from '../../components/action-deconsolida-button/action-deconsolida-button.component';

const itemsAvaiable = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: -1,
    label: "ALL",
  },
];

@Component({
  selector: 'app-gestione-rapportino',
  templateUrl: './gestione-rapportino.component.html',
  styleUrls: ['./gestione-rapportino.component.css'],
})
export class GestioneRapportinoComponent implements OnInit {
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;

  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * actions allowed for crud ( INSERT EDIT DELETE )
   */
  @Input() actions?: TableServerActionIntent[];

  @ViewChild("tableCN", { static: false }) tableInstance;

  datePickerValue: Date = new Date();
  anno: number;
  mese: number;
  currentIcon = faLockOpen;
  closeIcon = faTimes;
  viewMode: CrudNetViewMode;
  tableConfig: TableServerConfig;
  tableRow: Observable<any[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];
  filtersMode = TableServerFilterMode;
  showTableFilter = false;

  itemsAvaiableI = itemsAvaiable;
  selectedItemPerPage = itemsAvaiable[0];
  itemPerPages: number = this.selectedItemPerPage.value;

  filterIcon = faFilter;
  disableIcon = faBan;

  minMode: BsDatepickerViewMode = 'month';
  colorTheme = 'theme-orange';

  bsConfig: Partial<BsDatepickerConfig> = {
    minMode: this.minMode,
    containerClass: this.colorTheme,
    dateInputFormat: 'MMMM YYYY',
  };

  currentParam: TableServerSearch;

  idConfermaDeconsolida = 'idConfermaDeconsolida';
  selectedRow: any;


  constructor(
    private localeService: BsLocaleService,
    public service: RapportinoService,
    private modalService: ModalService
  ) {
    const filter1 = new TableServerFilterItem();
    filter1.label = 'Nome';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = 'NOME';

    const filter2 = new TableServerFilterItem();
    filter2.label = 'Cognome';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = 'COGNOME';

    const filter3 = new TableServerFilterItem();
    filter3.label = 'Cliente';
    filter3.type = TableServerFilterType.FILTER_TEXT;
    filter3.data = 'CLIENTE';

    const filter4 = new TableServerFilterItem();
    filter4.label = 'Attività';
    filter4.type = TableServerFilterType.FILTER_TEXT;
    filter4.data = 'TITOLOATTIVITA';
    this.tableFilters.push(filter1);
    this.tableFilters.push(filter2);
    // this.tableFilters.push(filter3);
    // this.tableFilters.push(filter4);

    const filterConsolidato = new TableServerFilterItem();
    filterConsolidato.label = 'CONSOLIDATO';
    filterConsolidato.type = TableServerFilterType.FILTER_LIST;
    filterConsolidato.options = [
      { label: 'Consolidato', value: 'C'},
      { label: 'Non consolidato', value: 'E'},
    ];
    filterConsolidato.data = 'CODICESTATORAPPORTINO';
    this.tableFilters.push(filterConsolidato);

    localeService.use('it');
    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = this.itemPerPages;
    this.tableConfig.columns = [];

    const columnExplode = new TableServerColumn();
    columnExplode.label = 'Assegnazione';
    columnExplode.data = 'IDIMPIEGATO';
    columnExplode.type = TableServerColumnType.COLUMN_EXPANDABLE;
    columnExplode.hideMe = (row: any, column) => {
      return !(row.GIORNILAVORATI && row.GIORNILAVORATI > 0);
    };
    columnExplode.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = GestioneRapportinoExpandableColumnComponent;
      render.inputs = {
        idImpiegato: row.IDIMPIEGATO,
        anno: this.anno,
        mese: this.mese
      };
      return render;
    };
    this.tableConfig.columns.push(columnExplode);

    const column1 = new TableServerColumn();
    column1.label = 'Nome';
    column1.data = 'NOME';
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = 'Cognome';
    column2.data = 'COGNOME';
    this.tableConfig.columns.push(column2);

    const column4 = new TableServerColumn();
    column4.label = 'GG Lavorati';
    column4.data = 'GIORNILAVORATI';
    this.tableConfig.columns.push(column4);

    this.showTableFilter = false;

  }

  closeModal() {
    this.selectedRow = null;
    this.modalService.close(this.idConfermaDeconsolida);
  }

  ngOnInit() {
    this.showTableFilter = false;

    const action = new EventEmitter();
    action.subscribe((row) => {
      this.selectedRow = row;
      this.modalService.open(this.idConfermaDeconsolida);
    });

    if (this.tableConfig.columns) {
      const caction = new TableServerColumn();

      caction.type = TableServerColumnType.COLUMN_ACTION;
      caction.sortable = false;
      caction.actions = [TableServerActionIntent.VIEW];
      caction.label = '';
      caction.data = '';
      caction.render = (row: any, column, viewMode, closeModal) => {
        const render = new TableServerColumnRender();

        render.component = RapportinoComponent;
        render.inputs = {
          editable: false,
          impiegatoNominativo: row.NOME + ' ' + row.COGNOME,
          showArrow: false,
          hideActions: true,
          hideBreadcrumb: true,
          closeModal,
          idImpiegato: row.IDIMPIEGATO,
          startDate: moment(
            this.getDataFormatted(this.mese, this.anno),
            'DDMMYYYY'
          ).toDate(),
        };

        return render;
      };
      if (this.actions) {
        caction.actions = this.actions;
      }

      const columnDeconsolida = new TableServerColumn();
      columnDeconsolida.label = 'DECONSOLIDA';
      columnDeconsolida.type = TableServerColumnType.COLUMN_CUSTOM;
      columnDeconsolida.sortable = false;
      columnDeconsolida.render = (row, column) => {
        const render = new TableServerColumnRender();
        render.component = ActionDeconsolidaButtonComponent;
        render.inputs = {
          consolidato: row.CODICESTATORAPPORTINO === 'C',
          row,
        };
        render.outputs = {
          action,
        };
        return render;
      };

      this.tableConfig.columns = [
        ...this.tableConfig.columns,
        columnDeconsolida,
        caction,
      ];
    }
  }

  setItemsPerPage(page) {
    this.selectedItemPerPage = page;
    this.tableConfig.itemsPerPage = page.value;
    this.tableInstance.itemsPerPageChanged(page.value); 
  }

  
  onDateChange(ev: Date) {
    this.anno = ev.getFullYear();
    this.mese = ev.getMonth() + 1;
    this.getData(this.currentParam);
  }

  getData(param: TableServerSearch) {
    if (param) {
      this.currentParam = param;
      const orders = transformOrdersToServer(param.sort);
      const filters = transformFiltersToServer(param.filters);
      this.tableRow = this.service.getGestioneRapportino(this.anno, this.mese, orders, filters)
        .pipe(map(res => res.result.map(row => {
          row.rowClass = row.CODICESTATORAPPORTINO === 'C' ? 'table-success' : 'table-danger';
          return row;
        })));

    }
  }


  getDataFormatted(mese: number, anno: number) {
    const mesef = mese > 9 ? mese : '0' + mese;
    return '01' + mesef + anno;
  }

  deconsolidaRapportino() {
    this.service
      .deconsolidaMese(this.selectedRow.IDRAPPORTINO)
      .subscribe(() => {
        this.modalService.showSuccess('SUCCESSED_OPERATION');
        this.getData(this.currentParam);
        this.closeModal();
      });
  }
}
