import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  CrudNetFilterExpression,
  CrudNetRepo,
  CrudNetViewMode,
} from "crudnet-amgular";
import {
  faEdit,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CrudNetField } from "../../../../../projects/crudnet-amgular/src/lib/models/CrudNetFormField";
import { CrudNetUpdateRequest } from "../../../../../projects/crudnet-amgular/src/lib/models/CrudNetRequest";
import { Observable } from "rxjs";
import { BudgetModel } from "../budget.model";
import {
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterType,
} from "utils";
import { RicavoService } from "../../ricavo/ricavo.service";
import { CostoPersonaleProduzioneService } from "../../costo-personale-produzione/costo-personale-produzione.service";
import { CostoGeneralePreventivoService } from "../../costo-generale/costo-generale-preventivo/costo-generale-preventivo.service";
import * as moment from "moment";

@Component({
  selector: "app-budget-custom-crud",
  templateUrl: "./budget-custom-crud.component.html",
  styleUrls: ["./budget-custom-crud.component.css"],
})
export class BudgetCustomCrudComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: CrudNetRepo<any>;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: any;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;
  /**
   * props from attivita?
   */
  @Input() attivitaProp?: any;

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<any> = new EventEmitter<any>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  fields: CrudNetField[];

  annoCompetenza = moment().format("YYYY");
  ricaviPrevisti = 0;

  costoStruttura = 0;
  costiGenerali = 0;
  costiPersonaleProduzione = 0;
  notaCredito = 0;

  percentCostoStruttura = 0;
  percentCostiGenerali = 0;
  percentCostiPersonaleProduzione = 0;
  percentNotaCredito = 0;

  utile = 0;
  viewModes = CrudNetViewMode;
  currentIcon = faPlus;
  closeIcon = faTimes;
  show = true;
  defaultBudgetFilter: TableServerFilter;
  showAccordionRicavo = false;
  showAccordionCostiGenerali = false;
  showAccordionCostiPersonaleProduzione = false;

  constructor(
    private ricavoService: RicavoService,
    private costoGeneraleService: CostoGeneralePreventivoService,
    private costoPersonaleProduzioneService: CostoPersonaleProduzioneService
  ) {}

  ngOnInit() {
    this.currentIcon = this.getIcon();
    if (this.row) {
      this.annoCompetenza = this.row.ANNO;
      this.costoStruttura = this.row.TOTCOSTISTRUTPREV;
      this.notaCredito = this.row.NOTACREDITOPREV;
    }
    if (this.idCurrent) {
      this.defaultBudgetFilter = {
        data: "fkIdBudget",
        value: this.idCurrent,
        type: TableServerFilterType.FILTER_NUMBER,
        condition: TableServerFilterCondition.EQ,
      };
    }
    this.ricavoService.notifyImportoRicavo$.subscribe((res) => {
      if (typeof res === "string") {
        res = parseFloat(res);
      }
      this.ricaviPrevisti = res;
      this.getUtile();
    });
    this.costoGeneraleService.notifyImportoCostoGenerale$.subscribe((res) => {
      if (typeof res === "string") {
        res = parseFloat(res);
      }
      this.costiGenerali = res;
      this.getUtile();
    });
    this.costoPersonaleProduzioneService.notifyImportoCostoPersonaleProduzione$.subscribe(
      (res) => {
        if (typeof res === "string") {
          res = parseFloat(res);
        }
        this.costiPersonaleProduzione = res;
        this.getUtile();
      }
    );
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  onSubmit() {
    const p = new CrudNetUpdateRequest<BudgetModel>();
    let obj: Observable<any>;
    p.entity = {
      costoPersonaleStruttura: this.costoStruttura,
      anno: parseInt(this.annoCompetenza, 10),
      notaCredito: this.notaCredito,
    };
    if (this.viewMode === CrudNetViewMode.CREATE) {
      obj = this.service.add(p);
    } else if (this.viewMode === CrudNetViewMode.EDIT) {
      p.entity.idBudget = this.idCurrent;
      obj = this.service.update(p);
    } else {
      obj = this.service.del(p);
    }
    obj.subscribe((res) => {
      if (res.output && res.output.ERRORMESSAGE) {
        this.error.emit(res.output.ERRORMESSAGE);
      } else {
        this.success.emit(res.result);
      }
      this.close.emit(this.viewMode === CrudNetViewMode.CREATE);
    });
  }

  getUtile() {
    const totaleCosti =
      this.notaCredito +
      this.costiPersonaleProduzione +
      this.costiGenerali +
      this.costoStruttura;
    this.utile = this.ricaviPrevisti - totaleCosti;
    this.reloadPercentuali(this.ricaviPrevisti);
  }

  reloadPercentuali(base: number) {
    this.percentCostiGenerali = this.getPercentuale(base, this.costiGenerali);
    this.percentCostiPersonaleProduzione = this.getPercentuale(
      base,
      this.costiPersonaleProduzione
    );
    this.percentNotaCredito = this.getPercentuale(base, this.notaCredito);
    this.percentCostoStruttura = this.getPercentuale(base, this.costoStruttura);
  }

  getPercentuale(base, param): number {
    if (isNaN((param * 100) / base) || param === 0) {
      return 0;
    } else {
      const val = parseFloat(((param * 100) / base).toFixed(2));
      return isFinite(val) ? val : 0;
    }
  }

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }
}
