import {Injectable} from '@angular/core';
import {BaseRepo} from '../../repos/BaseRepo';
import {TipoRichiestaModel} from './tipologia-ferie-permessi.model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudNetBaseResponse, CrudNetExecRequest, CrudNetResultResponse, CrudNetSearchRequest, CrudNetUpdateRequest} from 'crudnet-amgular';
import {MISURA_FERIE} from '../../constants';

const POSSIBLE_MISURE = [
  {
    idMisura: MISURA_FERIE.ORARIO,
    descrizione: MISURA_FERIE.ORARIO
  },
  {
    idMisura: MISURA_FERIE.GIORNALIERO,
    descrizione: MISURA_FERIE.GIORNALIERO
  }];

@Injectable({
  providedIn: 'root'
})
export class TipologiaFeriePermessiService extends BaseRepo<TipoRichiestaModel> {
  getTable(): string {
    return 'TipoRichiesta';
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map(res => {
        res.result.columns = res.result.columns.filter(c => c.name !== 'idTipoRichiesta').map(c => {
          if (c.name === 'misura') {
            c.name = 'fkIdMisura';
          }
          return c;
        });
        return res;
      })
    );
  }

  find(id: any, customTable?: string): Observable<CrudNetResultResponse<TipoRichiestaModel>> {
    if (customTable === 'Misura') {
      const resMisure: CrudNetResultResponse<any> = {
        error: '',
        errorCode: '',
        message: '',
        result: POSSIBLE_MISURE.find(mis => mis.idMisura === id),
        hash: '',
        captcha: '',
      };
      return of(resMisure);
    }
    return super.find(id, customTable).pipe(
      map(res => {
        res.result = {...res.result, fkIdMisura: POSSIBLE_MISURE.find(mis => mis.idMisura === res.result.misura).idMisura};
        return res;
      })
    );
  }

  search(params: CrudNetSearchRequest<TipoRichiestaModel>, customTable?: any): Observable<CrudNetResultResponse<TipoRichiestaModel>> {
    if (!customTable) {
      params.includes = ['AttivitaTipoRichiesta_List.Attivita.DefaultContrattoAttivita_List'];
      return super.search2(params);
    }
    if (customTable === 'Misura') {
      let result = POSSIBLE_MISURE;
      if (params.filter && params.filter.expression.includes('descrizione')) {
        result = result.filter(mis => mis.descrizione.includes(params.filter.expressionValues[0].value.toUpperCase()));
      }
      const resMisure: CrudNetResultResponse<any> = {
        error: '',
        errorCode: '',
        message: '',
        result,
        hash: '',
        captcha: '',
      };
      return of(resMisure);
    }
    return super.search(params, customTable);
  }

  setDefaultContrattoAttivita(params: CrudNetExecRequest) {
    return this.exec('sp_SetDefaultContrattoAttivita', params);
  }

  add(params: CrudNetUpdateRequest<TipoRichiestaModel>, customTable?: string): Observable<CrudNetBaseResponse<TipoRichiestaModel>> {
    const execParams: CrudNetExecRequest = {
      pageNum: 0,
      pageSize: -1,
      order: [],
      par: {
        CODICETIPORICHIESTA: params.entity.codice,
        DESCTIPORICHIESTA: params.entity.descrizione,
        UNITAMISURARICHIESTA: params.entity.fkIdMisura,
        DESCATTIVITA: params.entity.descrizione
      }
    };
    return super.exec('sp_InsertTipoRichiestaAttivita', execParams);
  }

  assignImpiegatoAttivita(param: CrudNetExecRequest) {
    return this.exec('sp_AssignImpiegatoAttivita', param);
  }

  getAssignedFerieByIdImpiegato(param: CrudNetExecRequest) {
    return this.exec('fn_GetTipoRichiesteImpiegato', param);
  }

}
