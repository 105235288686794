import {Injectable} from '@angular/core';
import {CommessaService} from './commessa.service';
import {
  CrudNetBaseResponse,
  CrudNetExecRequest,
  CrudNetResultResponse,
  CrudNetSearchRequest,
  CrudNetUpdateFieldRequest, CrudNetUpdateRequest,
  transformServerFilterToTableFilter
} from 'crudnet-amgular';
import {CommessaModel} from './commessa.model';
import {Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommessaExpandedService extends CommessaService {

  updated = new Subject<any>();

  update(params: CrudNetUpdateRequest<any>, customTable?: string): Observable<CrudNetBaseResponse<CommessaModel>> {
    return super.update(params, customTable);
  }

  updateflds(params: CrudNetUpdateFieldRequest<any>): Observable<CrudNetBaseResponse<CommessaModel[] | CommessaModel>> {
    return super.updateflds(params).pipe(tap(() => this.updated.next()));
  }

  search(
    params: CrudNetSearchRequest<CommessaModel>,
    customTable?: any,
  ): Observable<CrudNetResultResponse<CommessaModel>> {
    if (customTable) {
      return super.search2(params, customTable);
    } else {
      const tableFilters = transformServerFilterToTableFilter(params.filter);
      const par = {
        FLAGATTIVA: 1,
        ANNOCOMPETENZA: moment().get('y')
      };
      tableFilters.forEach(filter => {
        par[filter.data] = filter.value;
      });
      params.filter = {expression: '', expressionValues: []};
      const execParams: CrudNetExecRequest = {
        filter: params.filter,
        order: params.order.length ? params.order.map((elm) => {
          return elm.toUpperCase();
        }) : ['IDCOMMESSA DESC'],
        pageNum: params.pageNum + 1,
        pageSize: params.pageSize,
        par,
      };
      return super.exec('fn_GetListaCommesse', execParams).pipe(
        map((res) => {
          res.result = res.result.map((r) => ({
            ...r,
            idCommessa: r.IDCOMMESSA,
            tariffa: r.TARIFFACOMMESSA,
            importo: r.IMPORTOCOMMESSA,
            numeroOrdineCliente: r.NUMEROORDINECLIENTE,
          }));
          return res;
        })
      );
    }
  }
}
