import { Injectable } from '@angular/core';
import { BaseRepo } from '../../repos/BaseRepo';
import { CommessaModel } from './commessa.model';
import {Observable, Subject} from 'rxjs';
import {
  CrudNetBaseResponse,
  CrudNetExecRequest,
  CrudNetResultResponse,
  CrudNetSearchRequest, CrudNetUpdateFieldRequest, CrudNetUpdateRequest, transformServerFilterToTableFilter,
} from 'crudnet-amgular';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import {TableServerFilter} from 'utils';

@Injectable({
  providedIn: 'root',
})
export class CommessaService extends BaseRepo<CommessaModel> {

  private notifyFilters = new Subject<TableServerFilter[]>();

  // Observable object streams
  notifyFilters$ = this.notifyFilters.asObservable();


  private notifyReload$ = new Subject<boolean>();

  // Observable object streams
  onReload = this.notifyReload$.asObservable();

  notifyReload(){
    this.notifyReload$.next(true);
  }

  getTable() {
    return 'Commessa';
  }

  search(
    params: CrudNetSearchRequest<CommessaModel>,
    customTable?: any,
  ): Observable<CrudNetResultResponse<CommessaModel>> {
    if (customTable) {
      return super.search2(params, customTable);
    } else {
      const tableFilters = transformServerFilterToTableFilter(params.filter);
      this.notifyFilters.next(tableFilters);
      const par = {
        FLAGATTIVA:1
      };
      tableFilters.forEach(filter => {
        par[filter.data] = filter.value;
      });
      params.filter = {expression: '', expressionValues: []};
      /* if (
        params.filter &&
        params.filter.expression &&
        params.filter.expression.indexOf("ANNOCOMPETENZA") >= 0
      ) {

        let ind = params.filter.expression
          .split("&&")
          .filter((el) => el.indexOf("ANNOCOMPETENZA") >= 0)[0]
          .split("@")[1]
          .split(")")[0];
        let val = params.filter.expressionValues[ind].value;
        params.filter.expressionValues[ind].value = val;
      }*/

      const execParams: CrudNetExecRequest = {
        filter: params.filter,
        order: params.order.map((elm) => elm.toUpperCase()),
        pageNum: params.pageNum + 1,
        pageSize: params.pageSize,
        par,
      };
      return super.exec('fn_GetListaCommesseGrouped', execParams).pipe(
        map((res) => {
/*          res.result = res.result.map((r) => ({
            ...r,
            idCommessa: r.IDCOMMESSA,
            tariffa: r.TARIFFACOMMESSA,
            importo: r.IMPORTOCOMMESSA,
            numeroOrdineCliente: r.NUMEROORDINECLIENTE,
          }));*/
          return res;
        })
      );
    }
  }

  del(params: CrudNetUpdateRequest<any>, customTable?: string): Observable<CrudNetBaseResponse<any>>{

    const p = new CrudNetUpdateFieldRequest<any>();
    p.entity = {...params.entity,flagAttiva: false};
    p.filter = {expression: 'idCommessa==@0', expressionValues: [{value: params.entity.idCommessa}]};
    return this.updateflds(p);
  }


  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map((res) => {
        const filteredCols = res.result.columns.filter(
          (c) => c
        );
        res.result.columns = filteredCols.map((c) => {
          if (c.name === 'tariffa') {
            c.hideMe = (parsedForm, orginalForm, externalData) => {
              if (
                !externalData ||
                !externalData.TipoOfferta ||
                !externalData.TipoOfferta.TipoFatturazione
              ) {
                return true;
              }
              return externalData.TipoOfferta.TipoFatturazione.codice !== 'GG';
            };
          } else if (c.name === 'importo') {
            c.hideMe = (parsedForm, orginalForm, externalData) => {
              if (
                !externalData ||
                !externalData.TipoOfferta ||
                !externalData.TipoOfferta.TipoFatturazione
              ) {
                return true;
              }
              return externalData.TipoOfferta.TipoFatturazione.codice !== 'CO';
            };
          } else if (c.name === 'flagAttiva') {
            c.hideMe = (parsedForm, orginalForm, externalData) => {
              return true;
            };
          } else if (c.name === 'fkIdOfferta') {
            c.hideMe = (parsedForm, orginalForm, externalData) => {
              return true;
              /*
              if (!externalData || !externalData.TipoOfferta || !externalData.TipoOfferta.TipoFatturazione) {
              }
              return externalData.TipoOfferta.TipoFatturazione.codice !== 'CO';*/
            };
          }
          return c;
        });
        return res;
      })
    );
  }


}
