import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-table-column-accept-deny',
  templateUrl: './table-column-accept-deny.component.html',
  styleUrls: ['./table-column-accept-deny.component.css']
})
export class TableColumnAcceptDenyComponent implements OnInit {
  @Input() value: any;
  @Output() acceptedValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() deniedValue: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onAccept(value) {
    this.acceptedValue.emit(value);
  }

  onDeny(value) {
    this.deniedValue.emit(value);
  }

}
