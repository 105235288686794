import { Injectable } from '@angular/core';
import {forkJoin, Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {BaseRepo} from '../../repos/BaseRepo';
import {CostoPersonaleProduzioneModel} from './costo-personale-produzione.model';
import {CrudNetExecRequest, CrudNetResultResponse, CrudNetSearchRequest} from 'crudnet-amgular';
import {sumBy, groupBy, assign} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CostoPersonaleProduzioneService extends BaseRepo<CostoPersonaleProduzioneModel> {
  private notifyImportoCostoPersonaleProduzione = new Subject<number>();

  // Observable object streams
  notifyImportoCostoPersonaleProduzione$ = this.notifyImportoCostoPersonaleProduzione.asObservable();

  getTable(): string {
    return 'CostoPersonaleProduzione';
  }
  // Service message commands
  sendImportoCostoPersonaleProduzione(ricavo: number) {
    this.notifyImportoCostoPersonaleProduzione.next(ricavo);
  }

  search(params: CrudNetSearchRequest<CostoPersonaleProduzioneModel>, customTable?: any): Observable<CrudNetResultResponse<CostoPersonaleProduzioneModel>> {
    if (!customTable) {
      params.includes = ['TipoOfferta'];
      return super.search2(params).pipe(
        tap(res => {
          this.sendImportoCostoPersonaleProduzione(sumBy(res.result, 'importo').toFixed(2));
        })
      );
    }
    return super.search(params, customTable);
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map(res => {
        res.result.columns = res.result.columns
          .filter(c => c.name !== 'fkIdBudget');
/*          .map(c => {
            if (c.name === 'fkIdTipoOfferta') {
              c.name = 'fkIdTipoCostoPersonaleProduzione';
              c.realName = 'fkIdTipoOfferta';
            }
            return c;
          });*/
        return res;
      })
    );
  }

  getDataPreventivoConsuntivo(params: CrudNetSearchRequest<any>, idBudget: number): Observable<CrudNetResultResponse<any>> {
    const parPreventivo: CrudNetExecRequest = {
      order: params.order,
      pageSize: -1,
      pageNum: 0,
      par: {
        IDBUDGET: idBudget,
        IDTIPOBUDGET: 1
      }
    };
    const parConsuntivo: CrudNetExecRequest = {
      order: params.order,
      pageSize: -1,
      pageNum: 0,
      par: {
        IDBUDGET: idBudget,
        IDTIPOBUDGET: 2
      }
    };
    const consuntivo = super.exec('fn_BudgetDettaglioCostoPersonaleProd', parConsuntivo);
    const preventivo = super.exec('fn_BudgetDettaglioCostoPersonaleProd', parPreventivo);

    return forkJoin([preventivo, consuntivo]).pipe(
      map(r => {
        const resPrev = r[0].result.map(prev => ({
          descrizione: prev.DESCRIZIONE,
          preventivo: prev.IMPORTO ? prev.IMPORTO : 0
        })).filter(e => e.descrizione);
        const resCons = r[1].result.map(cons => ({
          descrizione: cons.DESCRIZIONE,
          consuntivo: cons.IMPORTO ? cons.IMPORTO : 0
        })).filter(e => e.descrizione);

        const mappedResCons = Object.values(groupBy(resCons, 'descrizione')).map(val => (
          {
            descrizione: val[0].descrizione,
            consuntivo: sumBy(val, 'consuntivo')
          }
        ));
        const groupedArrayToUnify = groupBy([...resPrev, ...mappedResCons], 'descrizione');
        const unifiedArray = [];
        Object.keys(groupedArrayToUnify).forEach(key => {
          unifiedArray.push(assign(...groupedArrayToUnify[key]));
        });
        const res = r[0];
        res.result = unifiedArray;
        res.rowCount = unifiedArray.length;
        return res;
      })
    );
  }
}
