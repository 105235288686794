import { Injectable } from "@angular/core";
import { BaseRepo } from "../../repos/BaseRepo";
import { ClienteModel } from "./cliente.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  CrudNetResultResponse,
  CrudNetSearchRequest,
} from "crudnet-amgular/crudnet-amgular";

@Injectable({
  providedIn: "root",
})
export class ClienteService extends BaseRepo<ClienteModel> {
  getTable() {
    return "Cliente";
  }

  tabledef(customTable?: string): Observable<any> {
    return super.tabledef(customTable).pipe(
      map((x) => {
        x.result.columns.map((c) => {
          if (c.name === "fkIdComune") {
            c.type = "Autocomplete";
          }
          return c;
        });
        return x;
      })
    );
  }

  searchLookup(
    params: CrudNetSearchRequest<any>,
    customTable?: any
  ): Observable<CrudNetResultResponse<any>> {
    return super.search2(params, customTable);
  }

  getValueForSelect(
    id: any,
    customTable?: string
  ): Observable<CrudNetResultResponse<any>> {
    if (customTable) {
      return super.find(id, customTable);
    }
  }
}
