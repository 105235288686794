import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CostoGeneraleService } from './costo-generale.service';
import { CrudNetSearchRequest, CrudNetViewMode } from 'crudnet-amgular';
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterItem,
  TableServerFilterMode,
  TableServerFilterType,
} from 'utils';
import { Observable, of } from 'rxjs';
import { TIPO_BUDGET } from '../../constants/tipoBudget.it';
import { CostoGeneraleConsuntivoCustomCrudComponent } from './costo-generale-consuntivo-custom-crud/costo-generale-consuntivo-custom-crud.component';

@Component({
  selector: 'app-costo-generale',
  templateUrl: './costo-generale.component.html',
  styleUrls: ['./costo-generale.component.css'],
})
export class CostoGeneraleComponent implements OnInit {
  @ViewChild('cnCostoGenerale', { static: false }) cnInstance;

  viewMode: CrudNetViewMode;
  load = false;
  crud = CostoGeneraleConsuntivoCustomCrudComponent;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [];
  tableFilters: TableServerFilterItem[] = [];
  filterModes = TableServerFilterMode;
  disabledFields = ['fkIdTipoBudget'];
  tipoBudget = TIPO_BUDGET;
  tipoBudgetDefaultFilter: TableServerFilter;
  defaultValues;

  constructor(public costoGeneraleService: CostoGeneraleService) {
    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;

    const columnAnno = new TableServerColumn();
    columnAnno.label = 'Anno';
    columnAnno.data = 'anno';

    const columnConsuntivo = new TableServerColumn();
    columnConsuntivo.label = 'Consuntivo';
    columnConsuntivo.data = 'consuntivo';
    columnConsuntivo.type = TableServerColumnType.COLUMN_NUMBER;

    const columnDescrizione = new TableServerColumn();
    columnDescrizione.label = 'Descrizione Costo Gen.';
    columnDescrizione.data = 'TipoCostoGenerale.descrizione';

    const columnPreventivo = new TableServerColumn();
    columnPreventivo.label = 'Preventivo';
    columnPreventivo.data = 'importo';
    columnPreventivo.type = TableServerColumnType.COLUMN_NUMBER;

    this.tableConfig.columns = [
      columnAnno,
      columnDescrizione,
      columnPreventivo,
      columnConsuntivo,
    ];

    const filter1 = new TableServerFilterItem();
    filter1.label = 'Anno';
    filter1.type = TableServerFilterType.FILTER_NUMBER;
    filter1.data = 'anno';
    this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = 'Descrizione';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = 'TipoCostoGenerale.descrizione';
    this.tableFilters.push(filter2);
  }

  filterAction(row) {
    return row.importo
      ? [TableServerActionIntent.EDIT]
      : [TableServerActionIntent.EDIT, TableServerActionIntent.DELETE];
  }

  ngOnInit() {
    const param: CrudNetSearchRequest<any> = {
      pageSize: -1,
      pageNum: 0,
      filter: {
        expressionValues: [{ value: this.tipoBudget.CONSUNTIVO }],
        expression: 'codice.Contains(@0)',
      },
    };
    this.costoGeneraleService.search(param, 'TipoBudget').subscribe((res) => {
      this.defaultValues = {
        fkIdTipoBudget: res.result[0].idTipoBudget,
      };
      this.tipoBudgetDefaultFilter = {
        data: 'fkIdTipoBudget',
        value: res.result[0].idTipoBudget,
        type: TableServerFilterType.FILTER_NUMBER,
        condition: TableServerFilterCondition.EQ,
      };
    });
  }
}
