import {Component, OnInit} from '@angular/core';
import {
  TableServerActionIntent,
  TableServerColumnSortDirection,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType
} from 'utils';
import {ProfiloCrudComponent} from './profilo-crud/profilo-crud.component';
import {ProfiloService} from './profilo.service';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.css']
})
export class ProfiloComponent implements OnInit {

  tableConfig: TableServerConfig = {
    columns: [
      {
        data: 'codice',
        label: 'CODICE',
        type: TableServerColumnType.COLUMN_TEXT,
        sortable: true,
        sortDirection: TableServerColumnSortDirection.NO_SORT
      },
      {
        data: 'descrizione',
        label: 'DESCRIZIONE',
        type: TableServerColumnType.COLUMN_TEXT,
        sortable: true,
        sortDirection: TableServerColumnSortDirection.NO_SORT
      }
    ],
    itemsPerPage: 10,
    totalItems: 0
  };

  tableActions = [TableServerActionIntent.VIEW, TableServerActionIntent.EDIT];
  tableFilters: TableServerFilterItem[] = [];
  customCRUD = ProfiloCrudComponent;

  constructor(public profiloService: ProfiloService) {
    const filter1 = new TableServerFilterItem();
    filter1.data = 'codice';
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.label = 'CODICE';

    const filter2 = new TableServerFilterItem();
    filter2.data = 'descrizione';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.label = 'DESCRIZIONE';

    this.tableFilters = [filter1, filter2];

  }

  ngOnInit() {
  }

}
