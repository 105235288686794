

export enum ChatType{
    TO_PERSON,
    TO_GROUP
}
export interface ChatModel{
    id?:number,
    recipientsIds:number[],
    chatName:string;
    type:ChatType,
    opened?:boolean
}

export interface ChatMessage{
    idFrom?:number;
    idTo?:number;
    from?;
    to?;
    time?;
    messageText?:string;
}