export enum ROLES {
  // ROLE_ADMIN = 'ADM',
  // ROLE_AMMINISTRAZIONE = 'AMMI',
  // ROLE_COMMERCIALE = 'COM',
  // ROLE_DIPENDENTE= 'DIP',
  // ROLE_CAPO_PROGETTO= 'LEA',
  // ROLE_RECRUITER= 'REC',
  // ROLE_RESP_PERSONALE= 'RP',
  // ROLE_SEGRETARIA= 'SEG',
  // ROLE_ANAGARAFICA_IMP = 'ANAIMP',
  // ROLE_TRATT_ECO = 'TRATECO',
  // ROLE_ANAGRAFICA_CLIENTE = 'ANACLI',
  // ROLE_OFFERTA = 'OFF',
  // ROLE_COMSAL = 'COMSAL',
  // ROLE_COMGESTPRO = 'COMGESTPRO',
  // ROLE_SAL = 'SAL',
  // ROLE_RES = 'RES',
  // ROLE_PROF = 'PROF',
  // ROLE_RAPPORTINO = 'RAP',
  // ROLE_GESTIONERAPPORTINO = 'RAPGES',
  ROLE_READ_RAPPORTINO = 'READ_RAPPORTINO',
  ROLE_WRITE_RAPPORTINO = 'WRITE_RAPPORTINO',
  ROLE_WRITE_PERMESSI = 'WRITE_PERMESSI',
  ROLE_READ_PERMESSI = 'READ_PERMESSI',
  ROLE_READ_IMPIEGATO = 'READ_IMPIEGATO',
  ROLE_WRITE_IMPIEGATO = 'WRITE_IMPIEGATO',
  ROLE_READ_RICHIESTE = 'READ_RICHIESTE',
  ROLE_WRITE_RICHIESTE = 'WRITE_RICHIESTE',
  ROLE_READ_OFFERTA = 'READ_OFFERTA',
  ROLE_WRITE_OFFERTA = 'WRITE_OFFERTA',
  ROLE_DELETE_OFFERTA = 'DELETE_OFFERTA',
  ROLE_READ_CLIENTE = 'READ_CLIENTE',
  ROLE_WRITE_CLIENTE = 'WRITE_CLIENTE',
  ROLE_READ_COMMESSA = 'READ_COMMESSA',
  ROLE_WRITE_COMMESSA = 'WRITE_COMMESSA',
  ROLE_READ_SAL = 'READ_SAL',
  ROLE_WRITE_SAL = 'WRITE_SAL',
  ROLE_READ_ATTIVITA = 'READ_ATTIVITA',
  ROLE_WRITE_ATTIVITA = 'WRITE_ATTIVITA',
  ROLE_READ_ASSEGNAZIONE = 'READ_ASSEGNAZIONE',
  ROLE_WRITE_ASSEGNAZIONE = 'WRITE_ASSEGNAZIONE',
  ROLE_READ_TRATTAMENTO_ECONOMICO = 'READ_TRATTAMENTO_ECONOMICO',
  ROLE_WRITE_TRATTAMENTO_ECONOMICO = 'WRITE_TRATTAMENTO_ECONOMICO',
  ROLE_READ_GESTIONE_FERIE = 'READ_GESTIONE_FERIE',
  ROLE_WRITE_GESTIONE_FERIE = 'WRITE_GESTIONE_FERIE',
  ROLE_WRITE_DECONSOLIDA = 'WRITE_DECONSOLIDA',
  ROLE_WRITE_GESTIONE_RAPPORTINO = 'WRITE_GESTIONE_RAPPORTINO',
  ROLE_READ_GESTIONE_RAPPORTINO = 'READ_GESTIONE_RAPPORTINO',
  ROLE_READ_ASSEGNA_PROFILO = 'READ_ASSEGNA_PROFILO',
  ROLE_WRITE_ASSEGNA_PROFILO = 'WRITE_ASSEGNA_PROFILO',
  ROLE_READ_IMPIEGATO_REFERENTE_OFFERTA = 'READ_IMPIEGATO_REFERENTE_OFFERTA',
  ROLE_WRITE_IMPIEGATO_REFERENTE_OFFERTA = 'WRITE_IMPIEGATO_REFERENTE_OFFERTA',
  ROLE_READ_SOCIETA = 'READ_SOCIETA',
  ROLE_WRITE_SOCIETA = 'WRITE_SOCIETA',
  ROLE_READ_TIPOLOGIA_CONTRATTI = 'READ_TIPOLOGIA_CONTRATTI',
  ROLE_WRITE_TIPOLOGIA_CONTRATTI = 'WRITE_TIPOLOGIA_CONTRATTI',
  ROLE_READ_PROFILO = 'READ_PROFILO',
  ROLE_WRITE_PROFILO = 'WRITE_PROFILO',
  ROLE_READ_LOOKUPS = 'READ_LOOKUPS',
  ROLE_WRITE_LOOKUPS = 'WRITE_LOOKUPS',
  ROLE_READ_REPORT = 'READ_REPORT',
  ROLE_WRITE_REPORT = 'WRITE_REPORT',
  ROLE_READ_GESTIONE_MAGAZZINO = 'READ_GESTIONE_MAGAZZINO',
  ROLE_WRITE_GESTIONE_MAGAZZINO = 'WRITE_GESTIONE_MAGAZZINO',
  ROLE_READ_TIPOLOGIA_FERIE = 'READ_TIPOLOGIA_FERIE',
  ROLE_WRITE_TIPOLOGIA_FERIE = 'WRITE_TIPOLOGIA_FERIE',
  ROLE_READ_COSTI_EFFETTIVI = 'READ_COSTI_EFFETTIVI',
  ROLE_WRITE_COSTI_EFFETTIVI = 'WRITE_COSTI_EFFETTIVI',
  ROLE_READ_COSTI_COMMESSA = 'READ_COSTI_COMMESSA',
  ROLE_WRITE_COSTI_COMMESSA = 'WRITE_COSTI_COMMESSA',
  ROLE_READ_RIEPILOGO_COMMESSA = 'READ_RIEPILOGO_COMMESSA',
  ROLE_WRITE_RIEPILOGO_COMMESSA = 'WRITE_RIEPILOGO_COMMESSA',
  ROLE_READ_BUDGET = 'READ_BUDGET',
  ROLE_WRITE_BUDGET = 'WRITE_BUDGET',
}

export enum PROFILES {
  PROFILE_ADMIN = 'ADM',
  PROFILE_AMMINISTRAZIONE = 'AMMI',
  PROFILE_COMMERCIALE = 'COM',
  PROFILE_DIPENDENTE = 'DIP',
  PROFILE_CAPO_PROGETTO = 'LEA',
  PROFILE_RECRUITER = 'REC',
  PROFILE_RESP_PERSONALE = 'RP',
  PROFILE_SEGRETERIA = 'SEG',
}
