import { Component, OnInit } from "@angular/core";
import { CrudNetViewMode } from "crudnet-amgular";
import { Observable, of } from "rxjs";
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnSortDirection,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType,
} from "utils";
import { SkillsService } from "./skills.service";

@Component({
  selector: "app-skills",
  templateUrl: "./skills.component.html",
  styleUrls: ["./skills.component.css"],
})
export class SkillsComponent implements OnInit {
  viewMode: CrudNetViewMode;
  loadForm = false;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [
    TableServerActionIntent.VIEW,
    TableServerActionIntent.EDIT,
    TableServerActionIntent.DELETE,
  ];
  tableFilters: TableServerFilterItem[] = [];

  constructor(public skillsService: SkillsService) {
    let columns = [];

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = "Skill";
    column1.data = "descrizione";
    columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = "Ambito";
    column2.data = "AmbitoSkill.descrizione";
    columns.push(column2);

    this.tableConfig.columns = columns;

    const filter1 = new TableServerFilterItem();
    filter1.label = "Skill";
    filter1.type = TableServerFilterType.FILTER_TEXT;
    filter1.data = "descrizione";
    this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = "Ambito";
    filter2.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter2.data = "fkIdAmbitoSkill";
    filter2.options = this.skillsService.ambitoSkillDataset;
    this.tableFilters.push(filter2);
  }

  ngOnInit() {}
}
