import { Injectable } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {PROFILES, ROLES} from '../constants';
import {TOKEN_LOCAL_STORAGE_KEY} from '../constants';
import {isArray, isNullOrUndefined} from 'util';
import {intersection} from 'lodash';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  roles: ROLES[] = [];
  profiles: PROFILES[] = [];

  onLogout = new Subject();

  constructor(public jwtHelper: JwtHelperService) { }

  public isAuthenticated(): boolean {
    // Get token from localstorage
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
    // Check if token is null or empty
    if (token) {
      // Check whether the token is expired and return
      // true or false
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }

  getUserInfo() {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
    return this.jwtHelper.decodeToken(token);
  }
// Set Roles Token
/*  setRoles(token) {
    this.roles = this.jwtHelper.decodeToken(token).Profile.RIS;
  }*/

    setRoles(result: any[]) {
    this.roles = result.map(r => r.CODICE);
  }

  getRoles() {
    // return [ROLES.ROLE_ADMIN];
    return this.roles;
  }

  hasRoles(expectedRoles: ROLES[]): boolean {
   return ( isNullOrUndefined(expectedRoles) || !expectedRoles.length) ||
      (this.getRoles().length &&
        intersection(expectedRoles, this.getRoles()).length );
  }

  setProfiles(token) {
    this.profiles = this.jwtHelper.decodeToken(token).Profile//.PRO;
  }

  getProfiles() {
    // return [ROLES.ROLE_ADMIN];
    return this.profiles;
  }

  hasProfiles(expectedProfiles: PROFILES[]): boolean {
    return ( isNullOrUndefined(expectedProfiles) || !expectedProfiles.length) ||
      (this.getProfiles().length &&
        intersection(expectedProfiles, this.getProfiles()).length );
  }
}
