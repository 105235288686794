import { Injectable } from "@angular/core";
import {
  CrudNetExecRequest,
  CrudNetFilterExpression,
  CrudNetResultResponse,
  CrudNetSearchRequest,
} from "crudnet-amgular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BaseRepo } from "../../repos/BaseRepo";
import { ProfiloModel } from "./profilo.model";

@Injectable({
  providedIn: "root",
})
export class ProfiloService extends BaseRepo<ProfiloModel> {
  getTable() {
    return "Profilo";
  }

  search(
    params: CrudNetSearchRequest<ProfiloModel>,
    customTable?: any,
    filters?: CrudNetFilterExpression
  ): Observable<CrudNetResultResponse<ProfiloModel>> {
    params.includes = [
      "ProfiloRisorsaLogica_List.RisorsaLogica",
      "ProfiloLookup_List.Lookup",
    ];
    return this.search2(params, customTable);
  }

  getAllRisorseLogiche() {
    return super.search(
      {
        pageNum: 0,
        pageSize: -1,
      },
      "RisorsaLogica"
    );
  }

  getAllLookups() {
    return super
      .search(
        {
          pageNum: 0,
          pageSize: -1,
        },
        "Lookup"
      )
      .pipe(
        map((res) => {
          res.result = res.result.map((lookup) => {
            return {
              ...lookup,
              descrizione: lookup.nomeTabella,
            };
          });
          return res;
        })
      );
  }

  saveRisorseLogiche(idProfilo: number, idsRisorse: Array<number>) {
    return this.exec("sp_UpdateProfiloRisorsaLogica", {
      order: [],
      pageNum: 0,
      pageSize: -1,
      par: {
        IDPROFILO: idProfilo,
        ARRAYRISORSELOGICHE: idsRisorse.join(","),
      },
    });
  }

  saveLookups(idProfilo: number, idsLookups: Array<number>) {
    return this.exec("sp_UpdateProfiloLookup", {
      order: [],
      pageNum: 0,
      pageSize: -1,
      par: {
        IDPROFILO: idProfilo,
        ARRAYLOOKUP: idsLookups.join(","),
      },
    });
  }

  assignProfilo(params: CrudNetExecRequest) {
    return this.exec("sp_UpdateImpiegatoProfilo", params);
  }
}
