import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import html2canvas from 'html2canvas';


const REPETITION_COUNT = 5; // number of times each pixel is assigned to a canvas
const NUM_FRAMES = 50;
const DEBUG = false;

@Component({
  selector: 'app-easter-egg-thanos',
  templateUrl: './easter-egg-thanos.component.html',
  styleUrls: ['./easter-egg-thanos.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EasterEggThanosComponent implements OnInit {

  constructor() { }

  keys=["$","t","h","a","n",'o','s','enter'];
  count=0;
  inputkeys=[];
  active=false;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if(this.keys[this.count]===event.key.toLowerCase()){
      this.inputkeys.push(event.key.toLowerCase());
      this.count++;
      if(this.inputkeys.join('')===this.keys.join('')){
        if(this.active){
          //deactive
          window.location.reload();
        }else{
          
          this.enable();
          this.active=true;
        }
        this.inputkeys=[];
        this.count=0;
      }
    }else{
      this.inputkeys=[];
      this.count=0;
    }

    
    
  }

  clickMobile=0;
  activateMobile(){
    this.clickMobile++;
    if(this.clickMobile===15){
      if(this.active){
        window.location.reload();
      }else{
        this.enable();
        this.active=true;
      }
      
    }
  }

  ngOnInit() {
    setTimeout(()=>{
      document.querySelector('.copyright').addEventListener('click',this.activateMobile.bind(this));
    },1000)
  
  }


  disintegrateEl(ev){
    new Audio('/assets/sound/infinity-gauntlet.mp3').play();
      const $elm=ev.target;
      //$elm["classList"].add('ee-thanos-disintegration-container');
      if ($elm["disintegrated"]) { return; }
        $elm["disintegrated"] = true;
        this.disintegrate($elm);
  }

  enable(){
    document.body.classList.add('ee-thanos');
    document.body.onmousedown=()=>document.body.classList.add('ee-active');
    document.body.onmouseup=()=>document.body.classList.remove('ee-active');
    document.addEventListener('click',this.disintegrateEl.bind(this));
    new Audio('/assets/sound/i-am-inevitable.mp3').play();
  }

  disable(){
    document.body.classList.remove('ee-thanos');
    document.body.onmousedown=null;
    document.body.onmouseup=null;
    document.removeEventListener('click',this.disintegrateEl.bind(this));
  }

  generateFrames($canvas, count = 32) {
    const { width, height } = $canvas;
    const ctx = $canvas.getContext("2d");
    const originalData = ctx.getImageData(0, 0, width, height);
    const imageDatas = [...Array(count)].map(
      (_,i) => ctx.createImageData(width, height)
    );
    
    // assign the pixels to a canvas
    // each pixel is assigned to 2 canvas', based on its x-position
    for (let x = 0; x < width; ++x) {
      for (let y = 0; y < height; ++y) {
        for (let i = 0; i < REPETITION_COUNT; ++i) {
          const dataIndex = Math.floor(
            count * (Math.random() + 2 * x / width) / 3
          );
          const pixelIndex = (y * width + x) * 4;
          // copy the pixel over from the original image
          for (let offset = 0; offset < 4; ++offset) {
            imageDatas[dataIndex].data[pixelIndex + offset]
              = originalData.data[pixelIndex + offset];
          }
        }
      }
    }
    
    // turn image datas into canvas'
    return imageDatas.map(data => {
      const $c = $canvas.cloneNode(true);
      $c.getContext("2d").putImageData(data, 0, 0);
      return $c;
    });
  }


  replaceElementVisually($old, $new) {
    const $parent = $old.offsetParent;
    $new.style.top = `${$old.offsetTop}px`;
    $new.style.left = `${$old.offsetLeft}px`;
    $new.style.width = `${$old.offsetWidth}px`;
    $new.style.height = `${$old.offsetHeight}px`;
    $parent.appendChild($new);
    $old.style.visibility = "hidden";
  }

  disintegrate($elm) {
    html2canvas($elm).then($canvas => {    
      // create the container we'll use to replace the element with
      const $container = document.createElement("div");
      $container.classList.add("ee-thanos-disintegration-container");
      
      // setup the frames for animation
      const $frames = this.generateFrames($canvas, NUM_FRAMES);
      $frames.forEach(($frame, i) => {
        $frame.style.transitionDelay = `${1.35 * i / $frames.length}s`;
        $container.appendChild($frame);
      });
      
      // then insert them into the DOM over the element
      this.replaceElementVisually($elm, $container);
      
      // then animate them
      $container.offsetLeft; // forces reflow, so CSS we apply below does transition
      if (!DEBUG) {
        // set the values the frame should animate to
        // note that this is done after reflow so the transitions trigger
        $frames.forEach($frame => {
          const randomRadian = 2 * Math.PI * (Math.random() - 0.5);
          $frame.style.transform = 
            `rotate(${15 * (Math.random() - 0.5)}deg) translate(${60 * Math.cos(randomRadian)}px, ${30 * Math.sin(randomRadian)}px)
  rotate(${15 * (Math.random() - 0.5)}deg)`;
          $frame.style.opacity = 0;
        });
      } else {
        $frames.forEach($frame => {
          $frame.style.animation = `debug-pulse 1s ease ${$frame.style.transitionDelay} infinite alternate`;
        });
      }
    });
  }

}
