import {Component, OnInit, ViewChild} from '@angular/core';
import {CrudNetFilterExpression, CrudNetSearchRequest, CrudNetViewMode} from 'crudnet-amgular';
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnSortDirection,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterItem, TableServerFilterMode,
  TableServerFilterType,
} from 'utils';
import {Observable, of} from 'rxjs';
import {CommessaService} from './commessa.service';
import {CommessaCustomCrudComponent} from './commessa-custom-crud/commessa-custom-crud.component';
import {map} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';
import {CustomStringRendererComponent} from '../../components/custom-string-renderer/custom-string-renderer.component';
import {PROFILES} from 'src/app/constants';
import {ImpiegatoService} from '../impiegato/impiegato.service';
import {CommessaExpandableColumnComponent} from './commessa-expandable-column/commessa-expandable-column.component';
import {CommessaExpandedService} from './commessa-expanded.service';
import {orderBy} from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-commessa',
  templateUrl: './commessa.component.html',
  styleUrls: ['./commessa.component.css'],
})
export class CommessaComponent implements OnInit {
  customCRUD: any;

  viewMode: CrudNetViewMode;
  loadForm = false;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [];
  tableFilters: TableServerFilterItem[] = [];
  defaultFilters: TableServerFilter[] = [];
  filterModes = TableServerFilterMode;

  getListOptionsParams: CrudNetSearchRequest<any> = {
    pageNum: 0,
    pageSize: -1,
    order: ['ragioneSociale']
  };

  currentFilters: TableServerFilter[];

  profiloImpiegatiFilter = [PROFILES.PROFILE_COMMERCIALE];


  @ViewChild('CnCommessa', {static: false}) cnInstance;

  constructor(
    public ordineService: CommessaService,
    private auth: AuthService,
    private impiegatoService: ImpiegatoService,
    private commessaNestedService: CommessaExpandedService
  ) {
    /*    if (
          this.auth.hasProfiles([PROFILES.PROFILE_COMMERCIALE]) &&
          !this.auth.hasProfiles([PROFILES.PROFILE_ADMIN])
        ) {
          this.defaultFilters = [
            {
              data: 'IDIMPIEGATOREFOFFERTA',
              condition: TableServerFilterCondition.EQ,
              type: TableServerFilterType.FILTER_NUMBER,
              value: this.auth.getUserInfo().iduser,
            },
          ];
          // fkIdImpiegatoReferente.Equals(43)"
        }*/

    this.viewMode = CrudNetViewMode.EDIT;
    this.customCRUD = CommessaCustomCrudComponent;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const columnExplode = new TableServerColumn();
    columnExplode.label = 'Dettagli';
    columnExplode.data = 'IDCLIENTE';
    columnExplode.type = TableServerColumnType.COLUMN_EXPANDABLE;
    columnExplode.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CommessaExpandableColumnComponent;
      render.inputs = {
        idCliente: row.IDCLIENTE,
        filtersFromCommessa: this.currentFilters
      };
      return render;
    };
    this.tableConfig.columns.push(columnExplode);

    const columnCliente = new TableServerColumn();
    columnCliente.label = 'Cliente';
    columnCliente.data = 'CLIENTE';
    columnCliente.sortDirection = TableServerColumnSortDirection.ASC;

    const columnTariffaCommessa = new TableServerColumn();
    columnTariffaCommessa.label = 'Importo';
    columnTariffaCommessa.data = 'TARIFFACOMMESSA';
    columnTariffaCommessa.type = TableServerColumnType.COLUMN_CUSTOM;
    columnTariffaCommessa.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.TARIFFACOMMESSA
          ? '€ ' +
          row.TARIFFACOMMESSA.toLocaleString('it-IT', {
            maximumFractionDigits: 2,
          })
          : '',
      };
      return render;
    };

    const columnImportoCommessa = new TableServerColumn();
    columnImportoCommessa.label = 'Importo';
    columnImportoCommessa.data = 'IMPORTOCOMMESSA';
    columnImportoCommessa.type = TableServerColumnType.COLUMN_CUSTOM;
    columnImportoCommessa.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.IMPORTOCOMMESSA
          ? '€ ' +
          row.IMPORTOCOMMESSA.toLocaleString('it-IT', {
            maximumFractionDigits: 2,
          })
          : '',
      };
      return render;
    };

    const columnTotaleSal = new TableServerColumn();
    columnTotaleSal.label = 'Totale SAL';
    columnTotaleSal.data = 'TOTALESAL';
    columnTotaleSal.type = TableServerColumnType.COLUMN_CUSTOM;
    columnTotaleSal.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.TOTALESAL
          ? '€ ' +
          row.TOTALESAL.toLocaleString('it-IT', {maximumFractionDigits: 2})
          : '',
      };
      return render;
    };

    const columnTotaleSalFatturato = new TableServerColumn();
    columnTotaleSalFatturato.label = 'Totale SAL fatturato';
    columnTotaleSalFatturato.data = 'TOTALESALFATTURATO';
    columnTotaleSalFatturato.type = TableServerColumnType.COLUMN_CUSTOM;
    columnTotaleSalFatturato.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomStringRendererComponent;
      render.inputs = {
        stringToRender: row.TOTALESALFATTURATO
          ? '€ ' +
          row.TOTALESALFATTURATO.toLocaleString('it-IT', {
            maximumFractionDigits: 2,
          })
          : '',
      };
      return render;
    };


    this.tableConfig.columns = [
      columnExplode,
      columnCliente,
      /*   columnTariffaCommessa, */
      columnImportoCommessa,
      columnTotaleSal,
      columnTotaleSalFatturato,
    ];

    const filter1 = new TableServerFilterItem();
    filter1.label = 'Cliente';
    filter1.type = TableServerFilterType.FILTER_LIST;
    filter1.data = 'IDCLIENTE';
    filter1.options = [];
    this.tableFilters.push(filter1);

    // IDIMPIEGATOREFOFFERTA

    /*const filter4 = new TableServerFilterItem();
    filter4.label = 'Commerciale di rif';
    filter4.type = TableServerFilterType.FILTER_TEXT;
    filter4.data = 'COMMERCIALERIFERIMENTO';
    this.tableFilters.push(filter4);*/
    const filter4 = new TableServerFilterItem();
    filter4.label = 'Commerciale di rif';
    filter4.type = TableServerFilterType.FILTER_LIST;
    filter4.data = 'IDIMPIEGATOREFOFFERTA';
    filter4.options = [];
    if (
      this.auth.hasProfiles([PROFILES.PROFILE_ADMIN])
    ) {
      this.tableFilters.push(filter4);
    }

    const filter2 = new TableServerFilterItem();
    filter2.label = 'Titolo Offerta';
    filter2.type = TableServerFilterType.FILTER_TEXT;
    filter2.data = 'TITOLOOFFERTA';
    this.tableFilters.push(filter2);

    const filter3 = new TableServerFilterItem();
    filter3.label = 'Attesa Ordine';
    filter3.type = TableServerFilterType.FILTER_LIST;
    filter3.options = [
      {label: 'Attesa Ordine', value: 1},
      {label: 'Ordine Arrivato', value: 0},
    ];
    filter3.data = 'ATTESAORDINE';
    this.tableFilters.push(filter3);

    const filter5 = new TableServerFilterItem();
    filter5.label = 'Anno di Competenza';
    filter5.type = TableServerFilterType.FILTER_YEAR;
    filter5.data = 'ANNOCOMPETENZA';/*
    filter5.filterValue = {
      condition: TableServerFilterCondition.EQ,
      value: moment().format('YYYY').toString()
    };*/
    this.tableFilters.push(filter5);
  }

  ngOnInit() {
    this.ordineService.notifyFilters$.subscribe(res => {
      this.currentFilters = res;
    });
    this.loadLookups();

    this.commessaNestedService.updated.subscribe(() => {
      if (this.cnInstance) {
        this.cnInstance.refresh();
      }
    });
  }

  loadLookups() {
    /*this.ordineService.search(this.getListOptionsParams, 'Sede')
      .pipe(
        map(resSede => {
          return (
            resSede.result.map(sede => {
              return ({
                label: sede.descrizione,
                value: sede.idSede
              });
            })
          );
        })
      ).subscribe(res => {
        this.tableFilters[3].options = res;
      });*/

    this.ordineService
      .search(this.getListOptionsParams, 'Cliente')
      .pipe(
        map((resCliente) => {
          return orderBy(resCliente.result.map((cliente) => {
            return {
              label: cliente.ragioneSociale,
              value: cliente.idCliente,
            };
          }), 'label');
        })
      )
      .subscribe((res) => {
        this.tableFilters[0].options = res;
      });

    this.impiegatoService
      .getImpiegatiByProfili(
        {pageNum: 0, pageSize: -1, order: []},
        this.profiloImpiegatiFilter
      )
      .pipe(
        map((res) => {
          return res.result.map((imp) => ({
            value: imp.idImpiegato,
            label: imp.nome + ' ' + imp.cognome,
          }));
        })
      )
      .subscribe((res) => {
        this.tableFilters[1].options = res;
      });
  }
}
