import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  CrudNetFilterExpression,
  CrudNetRepo,
  CrudNetUpdateRequest,
  CrudNetViewMode,
  TypeaheadSearch,
} from "crudnet-amgular";
import { Observable } from "rxjs";
import * as moment from "moment";
import {
  faEdit,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "../../../auth/auth.service";
import { PROFILES, ROLES } from "../../../constants";
import { ImpiegatoService } from "../../impiegato/impiegato.service";
import { map } from "rxjs/operators";
import { log } from "util";
import { ModalService } from "utils";

@Component({
  selector: "app-offerta-custom-crud",
  templateUrl: "./offerta-custom-crud.component.html",
  styleUrls: ["./offerta-custom-crud.component.css"],
})
export class OffertaCustomCrudComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: CrudNetRepo<any>;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;
  selectedImpiegato;
  defaultCrudValues = {
    flagFuoriQuadro: false,
  };
  customSubmitParams: any[] = [];
  currentIcon = faPlus;
  closeIcon = faTimes;
  selectedAnno;

  allowedWriteReferenteRoles = [ROLES.ROLE_WRITE_IMPIEGATO_REFERENTE_OFFERTA];
  allowedReadReferenteRoles = [ROLES.ROLE_READ_IMPIEGATO_REFERENTE_OFFERTA];

  profiloImpiegatiFilter = [PROFILES.PROFILE_COMMERCIALE];

  showImpReferente = false;

  impiegatoReferenteOptionDS: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression:
          "NOME.ToUpper().Contains(@0) || COGNOME.ToUpper().Contains(@1)",
        expressionValues: [
          { value: typedValue.toUpperCase() },
          { value: typedValue.toUpperCase() },
        ],
      };
    }
    return this.impiegatoService
      .getImpiegatiByProfili(
        { pageNum: 0, pageSize: -1, order: [], filter },
        this.profiloImpiegatiFilter
      )
      .pipe(
        map((res) => {
          return res.result.map((imp) => ({
            ...imp,
            nominativo: imp.nome + " " + imp.cognome,
          }));
        })
      );
  };

  constructor(
    public auth: AuthService,
    private impiegatoService: ImpiegatoService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.impiegatoService
      .find(
        this.row
          ? this.row.fkIdImpiegatoReferente
          : this.auth.getUserInfo().iduser
      )
      .subscribe((res) => {
        this.selectedAnno = moment(
          this.row ? this.row.dataCompetenza : new Date()
        ).format("YYYY");
        this.selectedImpiegato = {
          ...res.result,
          nominativo: res.result.nome + " " + res.result.cognome,
          idImpiegato: this.row
            ? this.row.fkIdImpiegatoReferente
            : this.auth.getUserInfo().iduser,
        };
        this.showImpReferente = true;
      });
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  onSubmit = (entity, viewMode, idImpiegatoReferente) => {
    const p = new CrudNetUpdateRequest();
    let obj = new Observable<any>();
    entity.fkIdImpiegatoReferente = idImpiegatoReferente;

    if (!this.selectedAnno) {
      this.modalService.showError("ANNO_DI_COMPETENZA_REQUIRED");
      return;
    }

    let dataCompetenza = moment(this.selectedAnno + "0101", "YYYYMMDD");

    entity.dataCompetenza = dataCompetenza.format("YYYY-MM-DDTHH:mm:SS");

    p.entity = entity;
    if (viewMode === CrudNetViewMode.CREATE) {
      obj = this.service.add(p);
    } else if (viewMode === CrudNetViewMode.EDIT) {
      obj = this.service.update(p);
    } else if (viewMode === CrudNetViewMode.DELETE) {
      obj = this.service.del(p);
    }
    obj.subscribe((res) => {
      if (res.output && res.output.ERRORMESSAGE) {
        this.error.emit(res.output.ERRORMESSAGE);
      } else {
        this.success.emit("OK");
      }
      this.close.emit();
    });
  };

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }
}
