import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  faEdit,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  CrudNetFilterExpression,
  CrudNetRepo,
  CrudNetViewMode,
} from "crudnet-amgular";
import { ProfiloService } from "../profilo.service";

@Component({
  selector: "app-profilo-crud",
  templateUrl: "./profilo-crud.component.html",
  styleUrls: ["./profilo-crud.component.css"],
})
export class ProfiloCrudComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: ProfiloService;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;

  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;

  currentIcon = faPlus;
  closeIcon = faTimes;

  selectedRisorse = [];
  selectedLookups = [];

  constructor() {}

  ngOnInit() {
    this.currentIcon = this.getIcon();
    this.selectedRisorse = this.getSelectedRisorse();
    this.selectedLookups = this.getSelectedLookups();
  }

  getIcon() {
    switch (this.viewMode) {
      case CrudNetViewMode.DELETE:
        return faTrash;
      case CrudNetViewMode.EDIT:
        return faEdit;
      case CrudNetViewMode.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }

  getSelectedRisorse() {
    if (this.row) {
      return this.row.ProfiloRisorsaLogica_List.map((r) => r.RisorsaLogica);
    }
  }

  getSelectedLookups() {
    debugger;
    if (this.row && this.row.ProfiloLookup_List) {
      return this.row.ProfiloLookup_List.map((r) => r.Lookup);
    } else {
      return [];
    }
  }

  salvaRisorseLogiche() {
    this.service
      .saveRisorseLogiche(
        this.row.idProfilo,
        this.selectedRisorse.map((r) => r.idRisorsaLogica)
      )
      .subscribe((res) => {
        this.closeModal();
      });
  }

  salvaLookups() {
    this.service
      .saveLookups(
        this.row.idProfilo,
        this.selectedLookups.map((r) => r.idLookup)
      )
      .subscribe((res) => {
        this.closeModal();
      });
  }
}
