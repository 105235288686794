import { Component, OnInit } from '@angular/core';
import { AdminLayoutService } from 'src/app/layouts/admin-layout/admin-layout.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css']
})
export class RightSidebarComponent implements OnInit {

  closed:boolean=true;

  constructor(private adminLayoutService:AdminLayoutService) { }

  ngOnInit() {
    this.adminLayoutService.toogleRightSidebar.subscribe(state=>{
      this.closed=state;
    });
  }

}
