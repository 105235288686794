import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  faEdit,
  faEye,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { I18NextPipe } from "angular-i18next";
import {
  CrudNetFilterExpression,
  CrudNetSearchRequest,
  CrudNetUpdateRequest,
  CrudNetViewMode,
  TypeaheadSearch,
} from "crudnet-amgular";
import { map } from "rxjs/operators";
import { ArchivioCandidatiService } from "../archivio-candidati.service";
import { forkJoin, Observable, of } from "rxjs";
import * as moment from "moment";
import { ModalService, TaalUtilsService } from "utils";
import { ArchivioCandidatiLogAzioniViewerComponent } from "../archivio-candidati-log-azioni-viewer/archivio-candidati-log-azioni-viewer.component";
import { isArray } from "ngx-bootstrap/chronos";

@Component({
  selector: "app-archivio-candidati-custom-crud",
  templateUrl: "./archivio-candidati-custom-crud.component.html",
  styleUrls: ["./archivio-candidati-custom-crud.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ArchivioCandidatiCustomCrudComponent implements OnInit {
  /**
   * Crudnet repo for table or view
   */
  @Input() service: ArchivioCandidatiService;
  /**
   * Viewmode of form ( INSERT EDIT DELETE )
   */
  @Input() viewMode: CrudNetViewMode;
  /**
   * Object external for configuration in column definition
   */
  @Input() externalFields?: any;
  /**
   * id Value of current record null on viewMode.INSERT
   */
  @Input() idCurrent?: any;
  /**
   * filters to be set by loading list fields in the crud
   */
  @Input() lookupFilters?: Record<string, CrudNetFilterExpression>;
  /**
   * chiavi di lettura per campi di tipo lista di default 'descrizione'
   */
  @Input() refFieldMap?: Record<string, string>;
  /**
   * default values ​​for crud
   */
  @Input() defaultValues?: Object;
  /**
   * custom submit method
   */
  @Input() submit?: Function;
  /**
   * the current row of the table useful for custom components
   */
  @Input() row?: any;
  /**
   * event fire on success form submitting
   */
  @Output() success: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on error form submitting
   */
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  /**
   * event fire on closeModal
   */
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  viewModes = CrudNetViewMode;
  defaultCrudValues;
  customSubmitParams: any[] = [];
  currentIcon = faPlus;
  closeIcon = faTimes;
  hideTitle = false;

  /* SCHEDA ANAGRAFICA */
  nomeCandidato = null;
  cognomeCandidato = null;
  dataNascita = null;
  telefono = null;
  mail = null;
  provinciaResidenza = null;
  comuneResidenza = null;
  sediValutate = null;
  profilo = null;
  tipologiaProfilo = null;
  preavviso = null;
  seniority = null;
  tipoContrattoAttuale = null;
  fileCv = null;
  note = null;
  _104 = null;
  ralAttuale = null;
  skillList = null;
  skillPrimarie = null;
  ralDesiderataDa = null;
  ralDesiderataA = null;
  fileCvBase64 = null;
  fileName = null;
  fileExtension = null;
  lingueList = null;
  lingueRichieste = null;
  blackList = null;
  dispostoAlTrasferimento = null;

  seniorityList = null;

  richiestaSelected = null;

  //Scheda log
  @ViewChild("logAzioniViewerI", { static: false })
  logAzioni: ArchivioCandidatiLogAzioniViewerComponent;

  loading = true;
  loadingCV = false;

  constructor(
    public translatePipe: I18NextPipe,
    private modalService: ModalService,
    private archivioService: ArchivioCandidatiService,
    private taalUtils: TaalUtilsService
  ) {}

  ngOnInit() {
    this.setSkillsLingueAndSeniority();
  }

  getCandidatoData() {
    if (
      this.viewMode === this.viewModes.EDIT ||
      this.viewMode === this.viewModes.VIEW
    ) {
      if (!this.idCurrent && this.row) {
        this.idCurrent = this.row.IDCANDIDATO;
      }

      if (!this.row) {
        this.row = {
          szSkillsArray: [],
          szLinguaArray: [],
        };
      }

      if (!isArray(this.row.CandidatoSkill_List)) {
        this.row.CandidatoSkill_List = null;
      }

      this.row.szSkillsArray = [
        ...(this.row.CandidatoSkill_List
          ? this.row.CandidatoSkill_List.map((el) => el.Skill)
          : []),
      ];

      this.row.szLinguaArray = [
        ...(this.row.CandidatoLingua_List
          ? this.row.CandidatoLingua_List.map((el) => el.Lingua)
          : []),
      ];

      this.service.find(this.idCurrent).subscribe((res) => {
        if (res && res.result && res.result.length) {
          if (
            !this.row.CandidatoSkill_List ||
            !this.row.CandidatoLingua_List ||
            !this.row.TipoSeniority
          ) {
            this.row.szSkillsArray = this.extractSkillsFromRes(
              res.result[0].SKILLPRINCIPALI
            );
            this.row.szLinguaArray = this.extractLanguagesFromRes(
              res.result[0].LINGUECANDIDATO
            );
            this.extractTipoSeniorityFromRes(res.result[0].IDTIPOSENIORITY);
          }

          const getProvincia = this.service.getValueForSelect(
            res.result[0].IDPROVINCIA,
            "Provincia"
          );
          const getComune = this.service.getValueForSelect(
            res.result[0].IDCOMUNE,
            "Comune"
          );
          const getTipoContrattoLavoro = this.service.getValueForSelect(
            res.result[0].IDTIPOCONTRATTOLAVORO,
            "TipoContrattoLavoro"
          );
          const getProfilo = this.service.getValueForSelect(
            res.result[0].IDTIPOPROFILOLAVORATIVO,
            "TipoProfiloLavorativo"
          );

          forkJoin([
            getProvincia,
            getComune,
            getTipoContrattoLavoro,
            getProfilo,
          ]).subscribe(
            (el) => {
              this.loading = false;
              this.provinciaResidenza = el[0].result;
              this.comuneResidenza = el[1].result;
              this.tipoContrattoAttuale = el[2].result;
              this.profilo = el[3].result;
              this.lingueRichieste = this.row.szLinguaArray;
              this.nomeCandidato = res.result[0].NOME;
              this.skillPrimarie = this.row.szSkillsArray;
              this.cognomeCandidato = res.result[0].COGNOME;
              this.dataNascita = moment(res.result[0].DATANASCITA).toDate();
              this.telefono = res.result[0].CELLULARE;
              this.mail = res.result[0].EMAILPRIVATA;
              this.sediValutate = res.result[0].SEDIVALUTATE;
              this.tipologiaProfilo = res.result[0].TIPOLOGIAPROFILO;
              this.preavviso = res.result[0].GIORNIPREAVVISO;
              this.seniority = this.row.TipoSeniority;
              this.note = res.result[0].NOTE;
              this._104 = res.result[0].FLAG104;
              this.ralAttuale = res.result[0].RAL;
              this.ralDesiderataDa = res.result[0].RALDA;
              this.ralDesiderataA = res.result[0].RALA;
              this.fileName = res.result[0].NOMEFILECURRICULUM;
              this.fileExtension = res.result[0].ESTENSIONEFILECURRICULUM;
              this.dispostoAlTrasferimento =
                res.result[0].FLAGDISPONIBILETRANSFER;
              this.blackList = res.result[0].FLAGBLACKLIST;
              this.getCVData();
            },
            () => {},
            () => (this.loading = false)
          );
          this.fileCv = [
            {
              data: undefined,
              fullFileName: this.fileName + "." + this.fileExtension,
            },
          ];
        } else {
          this.loading = false;
          this.modalService.showError("element not found");
        }
      });
    } else {
      this.loading = false;
    }
    this.currentIcon = this.getIcon();
  }

  extractSkillsFromRes(skills: string) {
    let ret = [];
    let skillIDsArray = skills.split(",");
    skillIDsArray.forEach((id) => {
      let idInt = Number(id);
      this.skillList.forEach((skill) => {
        if (skill.idSkill === idInt) {
          ret.push(skill);
        }
      });
    });
    return ret;
  }

  extractLanguagesFromRes(languages: string) {
    let ret = [];
    let languageIDsList = languages.split(",");
    languageIDsList.forEach((id) => {
      let idInt = Number(id);
      this.lingueList.forEach((language) => {
        if (language.idLingua === idInt) {
          ret.push(language);
        }
      });
    });
    return ret;
  }

  extractTipoSeniorityFromRes(idSeniority: number) {
    this.seniorityList.forEach((seniority) => {
      if (seniority.idTipoSeniority === idSeniority) {
        this.row.TipoSeniority = seniority;
      }
    });
  }

  resetComune(event) {
    if (!this.provinciaResidenza) {
      this.comuneResidenza.displayValue = " ";
      this.loading = false;
    }
  }

  tipoSeniorityDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: [],
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "TipoSeniority").pipe(
      map((res) => {
        return res.result;
      })
    );
  };

  tipoProfiloDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: [],
      pageSize: -1,
      filter,
    };
    return this.service
      .searchLookup(searchRequest, "TipoProfiloLavorativo")
      .pipe(
        map((res) => {
          return res.result;
        })
      );
  };

  tipoContrattoAttualeDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      includes: [],
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "TipoContrattoLavoro").pipe(
      map((res) => {
        return res.result;
      })
    );
  };

  tipoProvinciaResidenzaDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "Provincia").pipe(
      map((res) => {
        return res.result;
      })
    );
  };

  tipoComuneResidenzaDataSet: TypeaheadSearch = (typedValue) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [
          { value: typedValue },
          { value: this.provinciaResidenza.idProvincia },
        ],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.service.searchLookup(searchRequest, "Comune").pipe(
      map((res) => {
        const sortedArray = this.taalUtils.sortCounterObj(res.result, (obj) => {
          const val = obj["descrizione"].toString().toLowerCase();
          return val == typedValue
            ? 0
            : val.indexOf(typedValue) == 0
            ? 1
            : val.indexOf(typedValue) > 0
            ? 2
            : 3;
        });
        return sortedArray;
      })
    );
  };

  richiestePassate: TypeaheadSearch = (typedValue) => {
    let filter = null;
    filter = {
      expression: "fkIdCandidato = @0 && TipoAzioneRecruiter.codice=@1",
      expressionValues: [{ value: this.idCurrent }, { value: "A" }],
    };

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      includes: ["Richiesta.Cliente", "TipoAzioneRecruiter"],
      filter,
    };
    return this.service.searchLookup(searchRequest, "LogAzioneCandidato").pipe(
      map((res) => {
        let foundIds = [];
        let filteredRes = res.result.filter((log) => {
          if (!foundIds.includes(log.fkIdRichiesta)) {
            foundIds.push(log.fkIdRichiesta);
            return true;
          }
        });
        filteredRes.map((el) => {
          let dateString = el.Richiesta.dataCreazione;
          let momentVariable = moment(dateString);
          el.formattedDate = momentVariable.format("DD/MM/YYYY");
          el.pickerLabel =
            el.Richiesta.Cliente.codiceCliente +
            " - Richiesta del " +
            el.formattedDate;
        });
        return filteredRes;
      })
    );
  };

  onRichiestePickerChange() {
    this.logAzioni.onLogAzioniPickerSelected(this.richiestaSelected);
  }

  setSkillsLingueAndSeniority() {
    const skillSearchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };
    const skills = this.service.searchLookup(skillSearchRequest, "Skill");

    const lingueSearchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };
    const lingue = this.service.searchLookup(lingueSearchRequest, "Lingua");

    const seniorityList = this.service.getSeniorityList();

    forkJoin([skills, lingue, seniorityList]).subscribe((res) => {
      this.skillList = res[0].result;
      this.lingueList = res[1].result;
      this.seniorityList = res[2].result;
      this.getCandidatoData();
    });
  }

  mapToServer() {
    this.skillPrimarie = [
      ...(this.skillPrimarie ? this.skillPrimarie.map((el) => el.idSkill) : []),
    ].join(",");

    this.lingueRichieste = [
      ...(this.lingueRichieste
        ? this.lingueRichieste.map((el) => el.idLingua)
        : []),
    ].join(",");

    if (
      this.fileCv &&
      this.fileCv.length &&
      this.fileCv[0].data &&
      this.fileCv[0].data.indexOf("base64,") >= 0
    ) {
      this.fileCvBase64 = this.fileCv[0].data.split("base64,")[1];
      let fullFileName = this.fileCv[0].name;
      if (!fullFileName) {
        fullFileName = this.fileName + "." + this.fileExtension;
      }
      let fileNameSplit = fullFileName.split(".");
      this.fileExtension = fileNameSplit[fileNameSplit.length - 1];

      //This is done since the filename can contain other "." characters
      for (let i = fullFileName.length - 1; i >= 0; i--) {
        const character = fullFileName.charAt(i);
        if (character !== ".") {
          continue;
        } else {
          this.fileName = fullFileName.substring(0, i);
          break;
        }
      }
    }

    if (this.viewMode === this.viewModes.DELETE && this.row) {
      return {
        idCandidato: this.row.IDCANDIDATO,
      };
    } else {
      let app: any = {
        idCandidato: this.idCurrent,
        nome: this.nomeCandidato,
        cognome: this.cognomeCandidato,
        curriculumVitae: this.fileCvBase64,
        NOMEFILE: this.fileName,
        ESTENSIONEFILE: this.fileExtension,
        dataNascita: this.dataNascita
          ? moment(this.dataNascita, "DD/MM/YYYY")
              .format("YYYY/MM/DDTHH:mm:ss")
              .toString()
          : null,
        cellulare: this.telefono ? this.telefono : null,
        emailPrivata: this.mail ? this.mail : null,
        idProvinciaResidenza:
          this.provinciaResidenza && this.provinciaResidenza.idProvincia
            ? this.provinciaResidenza.idProvincia
            : null,
        idComuneResidenza:
          this.comuneResidenza && this.comuneResidenza.idComune
            ? this.comuneResidenza.idComune
            : null,
        sediValutate: this.sediValutate ? this.sediValutate : null,
        ralAttuale: this.ralAttuale,
        ralDesiderataDa: this.ralDesiderataDa,
        ralDesiderataA: this.ralDesiderataA,
        idProfilo: this.profilo.idTipoProfiloLavorativo,
        tipologiaProfilo: this.tipologiaProfilo ? this.tipologiaProfilo : null,
        giorniPreavviso: this.preavviso ? this.preavviso : null,
        idTipoSeniority:
          this.seniority && this.seniority.idTipoSeniority
            ? this.seniority.idTipoSeniority
            : null,
        idTipoContrattoLavoro:
          this.tipoContrattoAttuale &&
          this.tipoContrattoAttuale.idTipoContrattoLavoro
            ? this.tipoContrattoAttuale.idTipoContrattoLavoro
            : null,
        flag104: this._104 ? 1 : 0,
        flagTransfer: this.dispostoAlTrasferimento ? 1 : 0,
        flagBlacklist: this.blackList ? 1 : 0,
        note: this.note ? this.note : null,
        szSkillsArray: this.skillPrimarie,
        szLinguaArray: this.lingueRichieste,
      };
      return app;
    }
  }

  getIcon() {
    switch (this.viewMode) {
      case this.viewModes.DELETE:
        return faTrash;
      case this.viewModes.EDIT:
        return faEdit;
      case this.viewModes.VIEW:
        return faEye;
      default:
        return faPlus;
    }
  }

  onSubmit() {
    const entity = this.mapToServer();

    const p = new CrudNetUpdateRequest();
    let obj = new Observable<any>();

    p.entity = entity;
    console.log(p.entity);
    if (this.viewMode === CrudNetViewMode.CREATE) {
      obj = this.service.add(p);
    } else if (this.viewMode === CrudNetViewMode.EDIT) {
      obj = this.service.update(p);
    } else if (this.viewMode === CrudNetViewMode.DELETE) {
      obj = this.service.del(p);
    }
    obj.subscribe((res) => {
      if (res.output && res.output.ERRORMESSAGE) {
        this.error.emit(res.output.ERRORMESSAGE);
        this.modalService.showError(res.output.ERRORMESSAGE);
      } else {
        this.success.emit("OK");
        this.close.emit();
      }
    });
  }

  getCVData() {
    this.loadingCV = true;
    if (this.viewMode !== CrudNetViewMode.CREATE) {
      this.archivioService.getCVData(this.idCurrent).subscribe((res) => {
        let data = "base64," + res.result[0].CURRICULUM;
        this.fileCv = [
          {
            data: data,
            fullFileName: this.fileName + "." + this.fileExtension,
          },
        ];
        this.loadingCV = false;
      });
    }
  }

  openFile() {
    // Create Blog View Link
    let pdfWindow = window.open("", "_blank");
    let data =
      "data:" +
      this.taalUtils.detectMimeTypeFromBase64OrFileExtension(
        this.fileCv[0].data,
        this.fileExtension
      ) +
      ";" +
      this.fileCv[0].data;
    pdfWindow.document.write(
      "<html><head><title>" +
        this.fileCv[0].fullFileName +
        "</title><head><body><iframe width='100%' height='100%' src='" +
        data +
        "'></iframe></body></html>"
    );
  }

  onCVFileValueChange(files) {
    let data = files[0].data;
    let fullFileName = files[0].name;
    this.fileCv = [
      {
        data: data,
        name: fullFileName,
      },
    ];
    this.fileExtension = fullFileName.split(".").pop();
    for (let i = fullFileName.length - 1; i >= 0; i--) {
      const character = fullFileName.charAt(i);
      if (character !== ".") {
        continue;
      } else {
        this.fileName = fullFileName.substring(0, i);
        break;
      }
    }
  }

  closeModal() {
    this.close.emit();
  }

  outputSuccess(ev) {
    this.success.emit(ev);
  }

  outputError(ev) {
    this.error.emit(ev);
  }
}
