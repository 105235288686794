import {EventEmitter, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {filter, map} from 'rxjs/operators';
import {I18NextService} from 'angular-i18next';

@Injectable({
  providedIn: 'root'
})
export class TitleBreadcrumbService {
  titleChange: EventEmitter<string> = new EventEmitter();
  titleVisibility: EventEmitter<boolean> = new EventEmitter();
  breadcrumbChange: EventEmitter<any> = new EventEmitter();

  pageTitleHidden = ['DASHBOARD', 'USER_PROFILE'];


  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title,
              private translationService: I18NextService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        let currentRouteTitle = null;
        const breadcrumbs = [];
        while (child) {
          if (child.snapshot.data && child.snapshot.data['title']) {
            currentRouteTitle = child.snapshot.data['title'];
            breadcrumbs.push(child.snapshot.data['title']);
          }
          if (child.firstChild) {
            child = child.firstChild;
          } else {
            break;
          }
        }
        return {
          title: currentRouteTitle,
          breadcrumb: breadcrumbs
        };
      })
    ).subscribe((data: any) => {
      if (data && data.title && !this.pageTitleHidden.includes(data.title)) {
        this.titleService.setTitle(this.translationService.t(data.title) + ' - PorTaal');
        this.setTitle(data.title);
        this.setTitleVisibility(false);
        this.setBreadcrumb(data.breadcrumb);
      } else {
        this.setTitleVisibility(true);
      }
    });
  }

  setTitle(value: string): void {
    this.titleChange.emit(value);
  }

  setTitleVisibility(value: boolean): void {
    this.titleVisibility.emit(value);
  }

  setBreadcrumb(value: any): void {
    this.breadcrumbChange.emit(value);
  }
}
