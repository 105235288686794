import { Component, OnInit } from '@angular/core';
import { AdminLayoutService } from 'src/app/layouts/admin-layout/admin-layout.service';
import { SpinnerService } from 'utils';
import { ChatModel, ChatType } from './chat.model';
import { ChatService } from './chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  selectedImpiegato;
  showBox=false;
  fabOpen=false;
  chatSelected:ChatModel=null;

  messageText:string;
  chatOpened:ChatModel[] = [];
  chatTypes=ChatType;

  constructor(private spinnerService:SpinnerService,private adminLayoutService:AdminLayoutService, private chatService:ChatService) { }
  
  ngOnInit() {
    this.chatService.onMessageReceived.subscribe(res=>{

    });
  }

  toogleFab(){
    this.fabOpen=!this.fabOpen;
  }

  onImpiegatoChange(selectedImpiegati){
    this.selectedImpiegato = selectedImpiegati;
  }


  impiegatoDisplayValue = (imp) => { //
    if (imp) {
      return imp.nome + ' ' + imp.cognome;
    }
  }

  onClickChat(){
    this.spinnerService.show();
    this.showBox=true;
    this.toogleFab();
    setTimeout(()=>{
      this.spinnerService.hide();
      let el: HTMLElement = document.querySelector<HTMLElement>("#chat-box input");
      el.click();
    },500);

  }

  onPickerClose(){
    this.showBox=false;
    if(this.selectedImpiegato && this.selectedImpiegato.data && this.selectedImpiegato.data.length){
      const chatName= this.selectedImpiegato.data.map(imp=>imp.nome + " "+ imp.cognome);
      let chat:ChatModel={
        type:ChatType.TO_PERSON,
        chatName: chatName.join(','),
        recipientsIds: this.selectedImpiegato.data.map(imp=>imp.idImpiegato)
      }
      if( this.selectedImpiegato.data.length>1){
        chat.type=ChatType.TO_GROUP;
      }
      this.chatOpened.push(chat);
      
      //to avoid the bug where, after creating a chat, you open the modal for creating a new chat
      //and without selecting anyone, when you close that modal, a duplicate of the first chat
      //is created
      this.selectedImpiegato = null;
    }
      
  }

  getFormattedChatName() {
    if (this.chatSelected && this.chatSelected.type === this.chatTypes.TO_GROUP) {
      let splittedNames = this.chatSelected.chatName.split(",");
      let chatName = "";
      splittedNames.forEach(fullName => {
        //get only the first name and add it to the chat name
        chatName += fullName.split(" ")[0];
        chatName += ", ";
      });
      console.log("Calculated name: "+chatName);
      return chatName;
    } else {
      //return full name if it's a chat with only one person
      return this.chatSelected.chatName;
    }
  }

  openSidebar(){
    this.adminLayoutService.toogleRightSidebar.next(false);
    this.toogleFab();
  }

  expandChat(chat){
    this.chatSelected=chat;
    chat.opened=true;
  }

  collapseChat(){
    this.chatOpened=this.chatOpened.map(chat=>({...chat,opened:false}));
    this.chatSelected=null;
  }


  getInitials(name){
    const nameS=name.split(' ');
    if(nameS.length>1){
      return nameS[0][0] + nameS[1][0];
    }
    return nameS[0][1] + nameS[0][1];
  }

  sendMessage(){
    if(this.chatSelected){
      if(this.chatSelected.type===ChatType.TO_PERSON){

        this.chatService.sendMessage(this.messageText,this.chatSelected.recipientsIds[0]);
      }else{

      }
    }
  }


}
