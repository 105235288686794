import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { CrudNetUpdateRequest, CrudNetUpdateFieldRequest, CrudNetExecRequest, CrudNetSearchRequest } from '../models/CrudNetRequest';
import FileSaver from 'file-saver';
import _ from 'lodash';
import { catchError, tap } from 'rxjs/operators';
import { CrudNetBaseResponse, CrudNetExecResponse, CrudNetResultResponse } from '../models/CrudNetResponse';
import { Observable } from 'rxjs';
import { Type, Injector } from '@angular/core';
import { SpinnerService } from 'utils';

/**
 * Abstract class that implements basic crudnet server call
 */
export abstract class CrudNetRepo<T> {

    showSpinnerDebounce = this.showSpinner.bind(this); // _.debounce(this.showSpinner.bind(this),100);
    hideSpinnerDebounce = _.debounce(this.hideSpinner.bind(this), 500);

    hideLoadingSpinner=false;

    loading = false;

    constructor(protected injector: Injector) {

    }

    /**
     * Spinner service to block user during server responding
     */
    spinnerService: SpinnerService = this.injector.get(SpinnerService);

    /**
     * @returns {HttpClient} this method allow to customize client http
     *
     * this allow to crudnet to use application client and not basic httpClient
     */
    abstract getHttpClient(): HttpClient;

    /**
     * @returns {string} this method allow to read table name from input service
     */
    abstract getTable(): string;

    /**
     * @returns {string} this method allow to read auth token from application
     */
    abstract getAuthToken(): string;

    /**
     * @returns {string} this method allow to read base url from application
     */
    abstract getBaseUrl(): string;

    /**
     * this method allow to manage error from application
     */
    abstract onError(error: HttpErrorResponse);

    /**
     * @returns {Type<any>} this method allow to manage custom spinner for crudnet
     */
    getSpinnerComponent(): Type<any> {
        return null;
    }

    /**
     *  this method allow to show spinner
     */
    protected showSpinner() {
        if (!this.loading && !this.hideLoadingSpinner) {
            this.spinnerService.show(this.getSpinnerComponent());
            this.loading = true;
        }

    }

    /**
     *  this method allow to hide spinner
     */
    protected hideSpinner() {
        this.spinnerService.hide();
        this.loading = false;
    }

    protected getUrl(url): string {
        return `${this.getBaseUrl()}${url}`;
    }

    /**
     *  this method allow to set custom headers and set auth token
     * @returns header obj
     */
    protected getHeaders() {

        const ret: any = {
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json'
        };
        const loggedToken = this.getAuthToken();
        if (loggedToken && loggedToken.length) {
            ret.Authorization = 'Bearer ' + loggedToken;
        }
        return ret;
    }

    /**
     *  this method call crudnet server insert
     */
    add(params: CrudNetUpdateRequest<T>, customTable?: string): Observable<CrudNetBaseResponse<T>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetBaseResponse<T>>(
          this.getUrl(`/Generic/add/${customTable || this.getTable()}`),
          params,
          {
              headers: this.getHeaders()
          }
        ).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
    }

    /**
     *  this method call crudnet server delete
     */
    del(params: CrudNetUpdateRequest<T>, customTable?: string): Observable<CrudNetBaseResponse<T>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetBaseResponse<T>>(
            this.getUrl(`/Generic/del/${customTable || this.getTable()}`),
            params,
            {
                headers: this.getHeaders()
            }
        ).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
    }

    /**
     *  this method call crudnet server update
     */
    update(params: CrudNetUpdateRequest<T>, customTable?: string): Observable<CrudNetBaseResponse<T>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetBaseResponse<T>>(
          this.getUrl(`/Generic/update/${customTable || this.getTable()}`),
          params,
          {
              headers: this.getHeaders()
          }
        ).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
    }

    /**
     *  this method call crudnet server updatefield
     */
    updateflds(params: CrudNetUpdateFieldRequest<T>): Observable<CrudNetBaseResponse< T | T[] >> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetBaseResponse< T |T[] >>(
            this.getUrl(`/Generic/updateflds/${this.getTable()}`)
            , params,
            {
                headers: this.getHeaders()
            }
        ).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
    }

    /**
     *  this method call crudnet server exec storeprocedure
     */
    exec(procName: string, params: CrudNetExecRequest, conf?): Observable<CrudNetExecResponse<any>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetExecResponse<any>>(
            this.getUrl(`/Generic/exec/${procName}`)
            , params, conf ||
            {
                headers: this.getHeaders()
            }
        ).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
    }

    /**
     *  this method call crudnet server send mail
     */
    sendMail(procName: string, params: CrudNetExecRequest, conf): Observable<CrudNetExecResponse<any>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetExecResponse<any>>(
            this.getUrl(`/Generic/sendmail/${procName}`)
            , params, conf ||
        {
            headers: this.getHeaders()
        }).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
    }

    /**
     *  this method call crudnet server send sms
     */
      sendSms(procName: string, params: CrudNetExecRequest, conf): Observable<CrudNetExecResponse<any>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetExecResponse<any>>(
            this.getUrl(`/Generic/sendsms/${procName}`)
            , params, conf ||
        {
            headers: this.getHeaders()
        }).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
      }

    /**
     *  this method call crudnet server get record by id
     */
      find(id,customTable?: string): Observable<CrudNetResultResponse<T>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().get<CrudNetResultResponse<T>>(
            this.getUrl(`/Generic/find/${customTable || this.getTable()}/${id}`)
            ,
        {
            headers: this.getHeaders()
        }).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
      }

      /**
     *  this method call crudnet server get table definition
     */
      tabledef(customTable?: string) {
       this.showSpinnerDebounce();
       return this.getHttpClient().get(
            this.getUrl(`/Generic/tabledef/${customTable || this.getTable()}`)
            ,
        {
            headers: this.getHeaders()
        }).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
      }

    /**
     *  this method call crudnet server get proc definition
     */
    procdef(procName: string) {
        this.showSpinnerDebounce();
        return this.getHttpClient().get(
             this.getUrl(`/Generic/procdef/${procName}`)
             ,
         {
             headers: this.getHeaders()
         }).pipe(
             tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
             catchError(this.onError.bind(this))
         );
       }

    /**
     *  this method call crudnet server pagination record from table or view
     */
      search(params: CrudNetSearchRequest<T>, customTable?): Observable<CrudNetResultResponse<T>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetResultResponse<T>>(
          this.getUrl(`/Generic/search/${customTable || this.getTable()}`),
          params,
          {
              headers: this.getHeaders()
          }
        ).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
      }

      /**
     *  this method call crudnet server pagination record from table or view with relations
     */
      search2(params: CrudNetSearchRequest<T>, customTable?): Observable<CrudNetResultResponse<T>> {
       this.showSpinnerDebounce();
       return this.getHttpClient().post<CrudNetResultResponse<T>>(
          this.getUrl(`/Generic/search2/${customTable || this.getTable()}`),
          params,
          {
              headers: this.getHeaders()
          }
        ).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
      }

      /**
     *  this method call crudnet server download file
     */
      download(url, params: any, headers, filename) {
       this.showSpinnerDebounce();
       return this.getHttpClient().get(this.getUrl(url), {
          responseType: 'blob',
          params,
          headers: _.merge(this.getHeaders(), headers), // important
        }).toPromise().then((response) => {
          FileSaver.saveAs(new Blob([response]), filename);
        }).catch(
            this.onError.bind(this)
        ).finally(() => {
            this.hideSpinnerDebounce();
        });
      }

      /**
     *  this method call crudnet server download file as blob
     */
      downloadBlob(url, params, headers, filename) {
       this.showSpinnerDebounce();
       return this.getHttpClient().get(this.getUrl(url), {
            params,
            headers: _.merge(this.getHeaders(), headers),
            responseType: 'blob'
        }).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
      }

    /**
     *  this method call crudnet server upload file
     */
      upload(url, params, headers, file, fileKey) {
       this.showSpinnerDebounce();
       const formData = new FormData();
       if (params && _.isObject(params)) {
          Object.keys(params).forEach((key) => {
            formData.append(key, params[key]);
          });
        }
       formData.append(fileKey, file);
       return this.getHttpClient().post(this.getUrl(url), formData, {
          headers: _.merge(this.getHeaders(), _.merge(headers, {
            'Content-Type': 'multipart/form-data',
          })),
        }).pipe(
            tap(this.hideSpinnerDebounce.bind(this), this.hideSpinnerDebounce.bind(this)),
            catchError(this.onError.bind(this))
        );
      }

}
