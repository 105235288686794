import {NgModule} from '@angular/core';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {CustomDateRendererComponent} from './custom-date-renderer/custom-date-renderer.component';
import {CustomBooleanRendererComponent} from './custom-boolean-renderer/custom-boolean-renderer.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {ActionSearchButtonComponent} from './action-search-button/action-search-button.component';
import {CustomStringRendererComponent} from './custom-string-renderer/custom-string-renderer.component';
import {SharedModule} from '../shared/shared.module';
import {TableColumnAcceptDenyComponent} from './table-column-accept-deny/table-column-accept-deny.component';
import {RangeSliderComponent} from './range-slider/range-slider.component';
import {ActionDeconsolidaButtonComponent} from './action-deconsolida-button/action-deconsolida-button.component';
import {CustomActionButtonComponent} from './custom-action-button/custom-action-button.component';
import {TitleBreadcrumbComponent} from './title-breadcrumb/title-breadcrumb.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { NotificationCenterComponent } from '../modules/notification-center/notification-center.component';
import { ImpiegatoPickerComponent } from '../modules/impiegato/impiegato-picker/impiegato-picker.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { ChatComponent } from './chat/chat.component';
import { SwitchComponent } from './switch/switch.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';


@NgModule({
  imports: [
    SharedModule,
    RouterModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CustomDateRendererComponent,
    CustomBooleanRendererComponent,
    ActionSearchButtonComponent,
    ProgressBarComponent,
    CustomStringRendererComponent,
    TableColumnAcceptDenyComponent,
    RangeSliderComponent,
    ActionDeconsolidaButtonComponent,
    CustomActionButtonComponent,
    TitleBreadcrumbComponent,
    RightSidebarComponent,
    NotificationCenterComponent,
    ImpiegatoPickerComponent,
    NotificationListComponent,
    ChatComponent,
    SwitchComponent,
    PdfViewerComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CustomActionButtonComponent,
    TitleBreadcrumbComponent,
    RightSidebarComponent,
    ImpiegatoPickerComponent,
    ChatComponent,
    SwitchComponent,
    PdfViewerComponent
  ],
  entryComponents: [
    CustomDateRendererComponent,
    CustomBooleanRendererComponent,
    ProgressBarComponent,
    ActionSearchButtonComponent,
    CustomStringRendererComponent,
    TableColumnAcceptDenyComponent,
    RangeSliderComponent,
    ActionDeconsolidaButtonComponent,
    CustomActionButtonComponent,
    NotificationCenterComponent,
    PdfViewerComponent
  ]
})
export class ComponentsModule {
}
