import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {isNullOrUndefined} from "util";

/**
 * Component for LongTextinput in crudnet form
 */
@Component({
  selector: 'cn-field-long-text',
  templateUrl: './crud-net-field-long-text.component.html',
  styleUrls: ['./crud-net-field-long-text.component.css']
})
export class CrudNetFieldLongTextComponent implements OnInit {


  @Input() label:string;
  @Input() id:string;
  @Input() required:boolean;
  @Input() value:string;
  @Input() readonly:boolean;
  @Input() show: boolean;
  @Input() precision:number;

  @Output() valueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    if (isNullOrUndefined(this.precision)) {
      this.precision = -1;
    }
  }

}
