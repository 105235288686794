import { Pipe, PipeTransform } from '@angular/core';
import {ROLES} from "../constants";
import {AuthService} from "../auth/auth.service";

@Pipe({
  name: 'hasRole'
})
export class HasRolePipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }

  transform(value: any[], ...args: ROLES[]): any {
    return this.authService.hasRoles(args) ? value : [];
  }

}
