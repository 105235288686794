import { Observable, of } from "rxjs";
import {
  CrudNetExecRequest,
  CrudNetSearchRequest,
  CrudNetUpdateFieldRequest,
  CrudNetUpdateRequest,
} from "crudnet-amgular";
import { BaseRepo } from "src/app/repos/BaseRepo";
import { Injectable } from "@angular/core";
import {
  CrudNetBaseResponse,
  CrudNetResultResponse,
  transformFiltersToServer,
  transformServerFilterToTableFilter,
} from "projects/crudnet-amgular/src/public-api";
import {
  TableServerFilter,
  TableServerFilterCondition,
  TableServerFilterType,
} from "utils";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ArchivioCandidatiService extends BaseRepo<any> {
  getTable(): string {
    return "Candidato";
  }

  _mapGetListaCandidati(result) {
    return result.map((candidato) => {
      return {
        Anagrafica: {
          nome: candidato.NOME,
          cognome: candidato.COGNOME,
          annoNascita: candidato.ANNONASCITA,
        },
        TipoProfiloLavorativo: {
          descrizione: candidato.DESCRIZIONETIPOPROFILOLAVORATIVO,
        },
        TipoSeniority: {
          descrizione: candidato.DESCRIZIONETIPOSENIORITY,
        },
        CandidatoSkill_List: {
          idSkills: candidato.SKILLPRINCIPALI,
        },
        LogAzioneCandidato_List: {
          tipoUltimaAzione: candidato.DESCTIPOAZIONERECRUITER,
          dataCreazione: candidato.DATALOGAZIONECANDIDATO,
        },
        tipoContrattoLavoro: candidato.DESCRIZIONETIPOCONTRATTOLAVORO,
        flagBlacklist: candidato.FLAGBLACKLIST,
        flagDisponibileTransfer: candidato.FLAGDISPONIBILETRANSFER,
        idCandidato: candidato.IDCANDIDATO,
        fkIdTipoSeniority: candidato.IDTIPOSENIORITY,
        sediValutate: candidato.SEDIVALUTATE,
        tipologiaProfilo: candidato.TIPOLOGIAPROFILO,
      };
    });
  }

  search(
    params: CrudNetSearchRequest<any>,
    customTable?: any
  ): Observable<CrudNetResultResponse<any>> {
    if (!customTable) {
      /*
      let paramFilter = transformServerFilterToTableFilter(params.filter);
      let filter = new TableServerFilter();

      filter.data = "flagAttivo";
      filter.value = false;
      filter.condition = TableServerFilterCondition.NOT_EQ;
      filter.type = TableServerFilterType.FILTER_LIST;

      paramFilter.push(filter);
      params.filter = transformFiltersToServer(paramFilter);

      params.includes = ["Anagrafica", "TipoSeniority", "TipoProfiloLavorativo", "LogAzioneCandidato_List", 
      "CandidatoLingua_List.Lingua","CandidatoSkill_List.Skill","AspettoEconomicoCandidato_List.RalDesiderataCandidato"];
      */

      let paramFilter = transformServerFilterToTableFilter(params.filter);
      let filter = new TableServerFilter();

      filter.data = "FLGATTIVO";
      filter.value = 0;
      filter.condition = TableServerFilterCondition.NOT_EQ;
      filter.type = TableServerFilterType.FILTER_LIST;

      paramFilter.push(filter);
      params.filter = transformFiltersToServer(paramFilter);

      params.filter.expressionValues = params.filter.expressionValues.map(
        (exp) => {
          if (typeof exp.value === "boolean") {
            exp.value = exp.value ? 1 : 0;
          }
          return exp;
        }
      );

      let skillListToFilter = [];
      let newParamsFilterExpression = params.filter.expression
        .split("&&")
        .map((exp) => {
          if (exp.indexOf("SKILLS") >= 0) {
            let index = parseInt(exp.split("@")[1].trim());
            skillListToFilter.push(params.filter.expressionValues[index].value);
            params.filter.expressionValues[index] = null;
          } else {
            return exp;
          }
        })
        .filter((exp) => {
          if (exp) return exp;
        });

      params.filter.expressionValues = params.filter.expressionValues.filter(
        (exp) => {
          return exp !== null;
        }
      );

      //change original index in expression array
      let paramsFilterExpressionString = newParamsFilterExpression
        .map((exp, index) => {
          let ret = exp.trim().split("@")[0] + "@" + index;
          if (exp.indexOf("Contains") >= 0) {
            ret = ret + ")";
          }
          return ret;
        })
        .join(" && ");

      params.filter.expression = paramsFilterExpressionString;

      let par = { FILTROLISTASKILL: "" };
      if (skillListToFilter.length) {
        par.FILTROLISTASKILL = skillListToFilter.join(",");
      }

      const execParams: CrudNetExecRequest = {
        order: params.order,
        par: par,
        filter: params.filter,
        pageNum: params.pageNum + 1,
        pageSize: params.pageSize,
      };
      return super.exec("fn_GetListaCandidati", execParams).pipe(
        map((res) => {
          //res.result = this._mapGetListaCandidati(res.result);
          res.result = res.result.map((candidato) => {
            if (candidato.DESCSKILLS.indexOf(",") >= 0) {
              candidato.DESCSKILLS = candidato.DESCSKILLS.split(",").join(", ");
            }
            return candidato;
          });
          return res;
        })
      );
      /*
      return super.search2(params, customTable);
      */
    }
  }

  find(id: any, customTable?: string): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDCANDIDATO: id,
      },
    };
    return this.exec("fn_GetDatiCandidato", params);
  }

  getDettaglioLogRichiesta(
    idCandidato: any,
    idRichiesta: any
  ): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: ["DATACREAZIONE"],
      par: {
        IDCANDIDATO: idCandidato,
        IDRICHIESTA: idRichiesta,
      },
    };
    return this.exec("fn_GetDettaglioLogRichiestaCandidato", params).pipe(
      map((res) => {
        res.result = res.result.map((log) => {
          if (log.VALUTAZIONI) {
            log.DESCTIPOAZIONE = log.DESCTIPOAZIONE + " - " + log.VALUTAZIONI;
          }
          return log;
        });
        return res;
      })
    );
  }

  add(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertCandidato", execParams);
  }

  update(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_UpdateCandidato", execParams);
  }

  del(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const p = new CrudNetUpdateFieldRequest<any>();
    p.entity = { ...params.entity, flagAttivo: false };
    p.filter = {
      expression: "idCandidato==@0",
      expressionValues: [{ value: params.entity.idCandidato }],
    };
    return this.updateflds(p);
  }

  searchLookup(
    params: CrudNetSearchRequest<any>,
    customTable?: any
  ): Observable<CrudNetResultResponse<any>> {
    return super.search2(params, customTable);
  }

  getValueForSelect(
    id: any,
    customTable?: string
  ): Observable<CrudNetResultResponse<any>> {
    if (customTable) {
      return super.find(id, customTable);
    }
  }

  setSkillDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };

    return super.search(searchRequest, "Skill").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.idSkill.toString(),
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  setTpoAzioneRecruiterDataset: any = (
    typedValue: string,
    fromFilter?: boolean
  ) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };

    return super.search(searchRequest, "TipoAzioneRecruiter").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.codice,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  setTpoContrattoLavoroDataset: any = (
    typedValue: string,
    fromFilter?: boolean
  ) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };

    return super.search(searchRequest, "TipoContrattoLavoro").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.codice,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  setTpoProfiloLavorativoDataset: any = (
    typedValue: string,
    fromFilter?: boolean
  ) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };

    return super.search(searchRequest, "TipoProfiloLavorativo").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.codice,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  setSeniorityDataset: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };

    return super.search(searchRequest, "TipoSeniority").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.idTipoSeniority,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  getSkillList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "Skill");
  }

  getSediList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "Sede");
  }

  sedeDataSet: any = (typedValue: string, fromFilter?: boolean) => {
    let filter = null;
    if (typedValue && typedValue.length) {
      filter = {
        expression: "descrizione.Contains(@0)",
        expressionValues: [{ value: typedValue }],
      };
    }

    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
      filter,
    };
    return this.search(searchRequest, "Sede").pipe(
      map((res) => {
        if (fromFilter) {
          return res.result.map((el) => ({
            value: el.idSede,
            descrizione: el.descrizione,
          }));
        }
        return res.result;
      })
    );
  };

  getSeniorityList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "TipoSeniority");
  }

  getTipoContrattoList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "TipoContrattoLavoro");
  }

  getTipologiaList() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };
    return super.search(searchRequest, "Tipologia");
  }

  getRichiestePassate(id: any): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDCANDIDATO: id,
      },
    };
    return this.exec("fn_GetAssegnazioniCandidato", params);
  }

  getCVData(id: any): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: {
        IDCANDIDATO: id,
      },
    };
    return this.exec("fn_GetCurriculumCandidato", params);
  }

  getCandidatiDaRicontattare(): Observable<CrudNetResultResponse<any>> {
    const params: CrudNetExecRequest = {
      pageSize: -1,
      pageNum: 0,
      order: [],
      par: null,
    };
    return this.exec("fn_GetListaCandidatiDaRicontattare", params);
  }

  saveRequest(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    function mapToServer(record) {
      return {
        IDCANDIDATO: record.idCandidato,
        IDRICHIESTA: record.idRichiesta,
        NOTE: record.note,
      };
    }

    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_AssegnaCandidatoRichiesta", execParams);
  }

  saveRequests = (data: any[]) => {
    function mapToServer(record) {
      return {
        IDCANDIDATO: record.idCandidato,
        IDRICHIESTA: record.idRichiesta,
        NOTE: record.note,
      };
    }

    this.showSpinnerDebounce();
    const params = {
      lstAction: data.map((record) => {
        return { action: "exec", entity: mapToServer(record) };
      }),
      orderOfInsertion: 1,
      tableName: "sp_AssegnaCandidatoRichiesta",
    };
    return this.getHttpClient()
      .post(this.getUrl(`/Generic/updMulti/dummy`), [params], {
        headers: this.getHeaders(),
      })
      .pipe(
        tap(
          this.hideSpinnerDebounce.bind(this),
          this.hideSpinnerDebounce.bind(this)
        ),
        catchError(this.onError.bind(this))
      );
  };

  getTipoAzioneRecruiter() {
    const searchRequest: CrudNetSearchRequest<any> = {
      pageNum: 0,
      pageSize: -1,
    };

    return super.search(searchRequest, "TipoAzioneRecruiter");
  }

  insertDaRicontattareLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertLogAzioneCandidato", execParams);
  }

  insertContattoLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertContattoLogAzione", execParams);
  }

  deleteContattoLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_DeleteContattoLogAzione", execParams);
  }

  insertValutazioneFinaleLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertValutazioneFinaleLogAzione", execParams);
  }

  deleteValutazioneFinaleLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_DeleteValutazioneFinaleLogAzione", execParams);
  }

  insertColloquioConValutazioneLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertColloquioCompleto", execParams);
  }

  insertColloquio(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertColloquio", execParams);
  }

  insertColloquioValutazione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertColloquioValutazione", execParams);
  }

  deleteColloquioLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_DeleteColloquio", execParams);
  }

  insertPropostaEconomicaLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertLogAzioneCandidato", execParams);
  }

  insertInvioCVLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertLogAzioneCandidato", execParams);
  }

  insertContrattoLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertLogAzioneCandidato", execParams);
  }

  insertAssunzioneLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertLogAzioneCandidato", execParams);
  }

  insertRimozioneLogAzione(
    params: CrudNetUpdateRequest<any>,
    customTable?: string
  ): Observable<CrudNetBaseResponse<any>> {
    const execPar = params.entity;
    const execParams: CrudNetExecRequest = {
      order: [],
      par: execPar,
      pageNum: 0,
      pageSize: -1,
    };
    return super.exec("sp_InsertLogAzioneCandidato", execParams);
  }

  rememberDontShowDashboardAlert() {
    localStorage.setItem("daRicontattareAlert", "true");
  }
}
