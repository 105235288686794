import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {CrudNetViewMode} from 'crudnet-amgular';
import {
  ModalService,
  TableServerActionIntent,
  TableServerColumn, TableServerColumnRender, TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
} from 'utils';
import {Observable, of} from 'rxjs';
import {TipologiaFeriePermessiService} from './tipologia-ferie-permessi.service';
import {faFileContract} from '@fortawesome/free-solid-svg-icons';
import {CustomActionButtonComponent} from '../../components/custom-action-button/custom-action-button.component';

@Component({
  selector: 'app-tipologia-ferie-permessi',
  templateUrl: './tipologia-ferie-permessi.component.html',
  styleUrls: ['./tipologia-ferie-permessi.component.css']
})
export class TipologiaFeriePermessiComponent implements OnInit, OnDestroy {

  viewMode: CrudNetViewMode;
  showModalTipologiaContratto = false;

  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];
  modalTipologiaContrattoId = 'tipologiaContrattoPicker';

  assegnaIcon = faFileContract;
  selectedRow: any;

  constructor(public tipologiaFeriePermessiservice: TipologiaFeriePermessiService, private modalService: ModalService) {

    this.viewMode = CrudNetViewMode.EDIT;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = 'Codice';
    column1.data = 'codice';
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = 'Descrizione';
    column2.data = 'descrizione';
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = 'Misura';
    column3.data = 'misura';
    this.tableConfig.columns.push(column3);

    const action = new EventEmitter();
    action.subscribe(row => {
      this.selectedRow = row;
      this.openModalTipologiaContratto();
    });

    const column4 = new TableServerColumn();
    column4.label = 'ASSEGNA';
    column4.data = '';
    column4.sortable = false;
    column4.type = TableServerColumnType.COLUMN_CUSTOM;
    column4.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomActionButtonComponent;
      render.inputs = {
        row,
        buttonClass: '',
        actionIcon: this.assegnaIcon
      };
      render.outputs = {
        action
      };
      return render;
    };
    this.tableConfig.columns.push(column4);
  }

  ngOnInit() {
    document.addEventListener('modalClosed', this.eventHandler);
  }

  ngOnDestroy() {
    document.removeEventListener('modalClosed', this.eventHandler);
  }

  eventHandler = (evt: CustomEvent) => {
    if (evt.detail.id === this.modalTipologiaContrattoId) {
      this.showModalTipologiaContratto = false;
    }
  }

  openModalTipologiaContratto() {
    this.modalService.open(this.modalTipologiaContrattoId);
    this.showModalTipologiaContratto = true;
  }

  closeModalTipolgiaContratto() {
    this.modalService.close(this.modalTipologiaContrattoId);
  }

}
