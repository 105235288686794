import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import * as moment from 'moment';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-counter-date',
  templateUrl: './counter-date.component.html',
  styleUrls: ['./counter-date.component.css']
})
export class CounterDateComponent implements OnInit {

  @Input() counterSubTitle: string = '';
  @Input() datePickerId: string = '';
  @Input() dateType: CounterCardDateType;
  @Input() counterIcon: string = '';

  @Output() onDateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private translatePipe: I18NextPipe) {
  }

  MESE: number = moment().get('month') + 1;
  ANNO: number = moment().get('year');

  datePickerValue: Date = new Date();

  colorTheme = 'theme-red';
  minMode: BsDatepickerViewMode = 'year';
  mode: BsDatepickerViewMode = 'month';
  bsConfig: Partial<BsDatepickerConfig>;

  ngOnInit() {
    this.counterSubTitle = this.translatePipe.transform(this.counterSubTitle)

    if (this.dateType === CounterCardDateType.YEAR) {
      this.bsConfig = {
        minMode: this.minMode,
        containerClass: this.colorTheme,
        dateInputFormat: 'YYYY',
        maxDate: new Date()
      };
    } else {
      this.bsConfig = {
        minMode: this.mode,
        containerClass: this.colorTheme,
        dateInputFormat: 'MM/YYYY',
        maxDate: new Date()
      };
    }
  }

  onDateChange(date): void {
    this.datePickerValue = date;
    this.onDateChanged.emit(date);
  }
}

export enum CounterCardDateType {
    YEAR, MONTH_YEAR
}
