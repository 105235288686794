export class DettaglioRichiesta {

    constructor(complexString: string) {
      const fieldsArray: string[] = complexString.split(',');

      this.IDDETTAGLIO = fieldsArray[0] ? parseInt(fieldsArray[0]) : 0;
      this.NOMEIMPIEGATOAUTORIZZATORE = fieldsArray[1] ? fieldsArray[1] : '';
      this.COGNOMEIMPIEGATOAUTORIZZATORE = fieldsArray[2] ? fieldsArray[2] : '';
      this.CODICESTATORICHIESTA = fieldsArray[3] ? fieldsArray[3] : '';
      this.DESCSTATORICHIESTA = fieldsArray[4] ? fieldsArray[4] : '';
      this.DATAINIZIO = fieldsArray[5] ? fieldsArray[5] : '';
      this.DATAFINE = fieldsArray[6] ? fieldsArray[6] : '';
      this.DATAVALUTAZIONE = fieldsArray[7] ? fieldsArray[7] : '';
      this.NOTE = fieldsArray[8] ? fieldsArray[8] : '';
    }

      IDDETTAGLIO: number;
      NOMEIMPIEGATOAUTORIZZATORE: string;
      COGNOMEIMPIEGATOAUTORIZZATORE: string;
      CODICESTATORICHIESTA: string;
      DESCSTATORICHIESTA: string;
      DATAINIZIO: string; // YYYYMMDDHHmm
      DATAFINE: string; // YYYYMMDDHHmm
      DATAVALUTAZIONE: string; // YYYYMMDD
      NOTE: string;
  }
