import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-custom-string-renderer',
  templateUrl: './custom-string-renderer.component.html',
  styleUrls: ['./custom-string-renderer.component.css']
})
export class CustomStringRendererComponent implements OnInit {
  @Input() stringToRender: string;

  constructor() { }

  ngOnInit() {
  }

}
