import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { Observable, of, Subscriber } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { TypeaheadSearch } from "../models/CrudNetFormField";
import _ from "lodash";
import * as moment from "moment";

@Component({
  selector: "cn-field-months",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./crud-net-field-months.component.html",
  styleUrls: ["./crud-net-field-months.component.css"],
})
export class CrudNetFieldMonthsComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() value: any;
  @Input() readonly: boolean;
  @Input() show: boolean;

  months = moment.months().map((month, index) => {
    return {
      label: month.toUpperCase(),
      value: index + 1,
    };
  });

  displayValue = "label";

  options = (query): Observable<Array<any>> => {
    return of(this.months).pipe(
      map((months) => {
        return months.filter((m) =>
          m.label.toLowerCase().includes(query.toLowerCase())
        );
      })
    );
  };

  dataSource: Observable<Array<any>>;
  showValue: string;
  typeaheadLoading = false;

  @Output() valueChange = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();

  constructor() {
    this.dataSource = new Observable((observer: Subscriber<string>) => {
      // Runs on every search
      observer.next(this.showValue || "");
    }).pipe(mergeMap((token: string) => this.options(token)));
  }

  ngOnInit() {
    if (this.value) {
      this.showValue = this.months.find((m) => m.value === this.value).label;
    }
  }

  typeaheadOnBlur() {
    // if(this.showValue && this.showValue.length){
    if (this.value) {
      this.showValue = this.months.find((m) => m.value === this.value).label;
    } else {
      this.showValue = "";
    }
    // }else{
    //  this.valueChange.emit(null);
    //  this.change.emit();
    // }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  onFocus() {
    this.showValue = "";
  }

  setFocus() {
    document.getElementById(this.id).focus();
  }

  onSelect(item) {
    this.valueChange.emit(item.value);
    this.change.emit(item.value);
  }

  clear() {
    this.showValue = "";
    this.valueChange.emit(null);
    this.change.emit();
  }

  isReadOnly(): boolean {
    const elm = document.getElementById(this.id);
    return elm
      ? elm.hasAttribute("readonly") || elm.hasAttribute("disabled")
      : false;
  }
}
