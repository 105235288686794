import { Component, OnInit } from "@angular/core";
import { CrudNetViewMode } from "crudnet-amgular";
import {
  TableServerActionIntent,
  TableServerColumn,
  TableServerColumnRender,
  TableServerColumnType,
  TableServerConfig,
  TableServerFilterItem,
  TableServerFilterType,
} from "utils";
import { Observable, of } from "rxjs";
import { RichiesteService } from "./richieste.service";
import { RichiesteCustomCrudComponent } from "./richieste-custom-crud/richieste-custom-crud.component";
import { CustomBooleanRendererComponent } from "../../components/custom-boolean-renderer/custom-boolean-renderer.component";
import { CustomDateRendererComponent } from "src/app/components/custom-date-renderer/custom-date-renderer.component";

@Component({
  selector: "app-richieste",
  templateUrl: "./richieste.component.html",
  styleUrls: ["./richieste.component.css"],
})
export class RichiesteComponent implements OnInit {
  customCRUD: any;
  viewMode: CrudNetViewMode;
  loadForm = false;
  tableConfig: TableServerConfig;
  tableRow: Observable<Object[]> = of([]);
  tableActions = [TableServerActionIntent.VIEW];
  tableFilters: TableServerFilterItem[] = [];
  skillOptions: any[];

  constructor(public richiesteService: RichiesteService) {
    this.getSkillList();
    this.getComuneList();

    this.viewMode = CrudNetViewMode.EDIT;
    this.customCRUD = RichiesteCustomCrudComponent;

    this.tableConfig = new TableServerConfig();
    this.tableConfig.itemsPerPage = 10;
    this.tableConfig.columns = [];

    const column1 = new TableServerColumn();
    column1.label = "Data Richiesta";
    column1.data = "dataCreazione";
    column1.type = TableServerColumnType.COLUMN_CUSTOM;
    column1.render = (row, column) => {
      const render = new TableServerColumnRender();
      render.component = CustomDateRendererComponent;
      render.inputs = {
        date: row.dataCreazione,
        format: "DD/MM/YYYY",
      };
      return render;
    };
    this.tableConfig.columns.push(column1);

    const column2 = new TableServerColumn();
    column2.label = "Cliente";
    column2.data = "Cliente.ragioneSociale";
    column2.type = TableServerColumnType.COLUMN_TEXT;
    this.tableConfig.columns.push(column2);

    const column3 = new TableServerColumn();
    column3.label = "Sede Competenza";
    column3.data = "Sede.descrizione";
    column3.type = TableServerColumnType.COLUMN_TEXT;
    this.tableConfig.columns.push(column3);

    const column5 = new TableServerColumn();
    column5.label = "HR Incaricata";
    column5.data = "Impiegato.Anagrafica.nomeCognome";
    column5.type = TableServerColumnType.COLUMN_TEXT;
    this.tableConfig.columns.push(column5);

    const column6 = new TableServerColumn();
    column6.label = "Profilo";
    column6.data = "TipoProfiloLavorativo.descrizione";
    column6.type = TableServerColumnType.COLUMN_TEXT;
    this.tableConfig.columns.push(column6);

    const filter1 = new TableServerFilterItem();
    filter1.label = "Data Richiesta";
    filter1.type = TableServerFilterType.FILTER_DATE;
    filter1.data = "dataCreazione";
    this.tableFilters.push(filter1);

    const filter2 = new TableServerFilterItem();
    filter2.label = "Cliente";
    filter2.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter2.data = "fkIdCliente";
    filter2.options = this.richiesteService.clienteDataSet;
    this.tableFilters.push(filter2);

    const filter3 = new TableServerFilterItem();
    filter3.label = "Sede Competenza";
    filter3.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter3.data = "fkIdSedeCompetenza";
    filter3.options = this.richiesteService.sedeDataSet;
    this.tableFilters.push(filter3);

    const filter4 = new TableServerFilterItem();
    filter4.label = "Priorità";
    filter4.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter4.data = "fkIdTipoPriorita";
    filter4.options = this.richiesteService.tipoPrioritaDataSet;
    this.tableFilters.push(filter4);

    const filter5 = new TableServerFilterItem();
    filter5.label = "Tipologia di lavoro";
    filter5.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter5.data = "fkIdTipologiaLavoro";
    filter5.options = this.richiesteService.tipologiaLavoroDataSet;
    this.tableFilters.push(filter5);

    const filter6 = new TableServerFilterItem();
    filter6.label = "Seniority";
    filter6.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter6.data = "fkIdTipoSeniority";
    filter6.options = this.richiesteService.tipoSeniorityDataSet;
    this.tableFilters.push(filter6);

    /*
    const filter7 = new TableServerFilterItem();
    filter7.label = 'Skill Primarie';
    filter7.type = TableServerFilterType.FILTER_MULTIPLE;
    filter7.data = 'RichiestaSkill_List';
    filter7.options = this.richiesteService.setSkillDataSet;
    this.tableFilters.push(filter7);
    */

    const filter8 = new TableServerFilterItem();
    filter8.label = "Stato Richiesta";
    filter8.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter8.data = "fkIdStatoRichiesta";
    filter8.options = this.richiesteService.statoRichiestaDataSet;
    this.tableFilters.push(filter8);

    const filter9 = new TableServerFilterItem();
    filter9.label = "Comune Sede Lavorativa";
    filter9.type = TableServerFilterType.FILTER_AUTOCOMPLETE;
    filter9.data = "fkIdComuneSedeLavorativa";
    filter9.options = this.richiesteService.comuneDataSet;
    filter9.minSearchLength = 3;
    this.tableFilters.push(filter9);

    const filter10 = new TableServerFilterItem();
    filter10.label = "Codice Rif. Interno";
    filter10.type = TableServerFilterType.FILTER_TEXT;
    filter10.data = "codiceRiferimento";
    filter10.options = [];
    this.tableFilters.push(filter10);
  }

  ngOnInit() {}

  getSkillList() {
    this.richiesteService.getSkillList().subscribe((res) => {
      let skillList = this.tableFilters.filter((el) => {
        return el.data === "RichiestaSkill_List";
      });
      skillList.map((skill) => {
        console.log(res.result);
        return (skill.options = res.result);
      });
    });
  }

  getComuneList() {
    this.richiesteService.getComuneList().subscribe((res) => {
      let comuniList = this.tableFilters.filter((el) => {
        return el.data === "fkIdProvincia";
      });
      comuniList.map((comune) => {
        console.log(res.result);
        return (comune.options = res.result);
      });
    });
  }
}
