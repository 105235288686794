import {Component, Input, OnInit} from '@angular/core';
import {Route, Router} from '@angular/router';
import {SocialUser} from 'angularx-social-login';
import { AdminLayoutService } from 'src/app/layouts/admin-layout/admin-layout.service';
import {LoginService} from 'src/app/pages/login/login.service';
import { environment } from 'src/environments/environment';
import {ROLES} from '../../constants';
import {AdminLayoutRoutes} from "../../layouts/admin-layout/admin-layout.routing";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {


  @Input() menuItems: Route[];
  // public menuItemsNoHeader: RouteInfo[] = [];
  public isCollapsed = true;
  user: SocialUser;
  
  chatEnabled=environment.CHAT_ENABLED;
  isMobile=false;

  constructor(private router: Router, private loginService: LoginService,private adminLayoutService:AdminLayoutService) { }

  ngOnInit() {
    this.adminLayoutService.windowsResizedMobile.subscribe(isMobile=>this.isMobile=isMobile);
    this.menuItems = AdminLayoutRoutes;
    //   MENU_ITEMS.filter(item => item.title !== '');
    //
    // // raggruppiamo tutte le rotte fuori dall'accordion
    //
    // MENU_ITEMS.filter(item => item.title === '').forEach(item => {
    //   this.menuItemsNoHeader = [...this.menuItemsNoHeader, ...item.routes];
    // });

    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.loginService.getAuthState().subscribe((user) => {
      this.user = user;
    });
  }

  logout() {
    return this.loginService.logoutTaalWithGoogle();
  }

}
